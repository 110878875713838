/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'
import InputField from './InputField'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import dale_logo from '../images/dale.png'
import LoginLayout from './LoginLayout'
import BlueButton from './layout/BlueButton'

class LoginForm extends Component {

    render() {
        const { handleSubmit, onRequestToken, error, tokenError } = this.props

        return (
            <LoginLayout msg="LOG IN" >
              { ({client}) => 
                  <form onSubmit={ handleSubmit } css={form_style}>

                    { (error || tokenError) && 
                      <div css={ error_container }>
                        { error && <p>{ error }</p>}
                        { tokenError && <p>{ tokenError }</p>}
                      </div>
                    }
                    <div className="form-group" css={ field_container }>
                      <label css={ label }>Username</label>
                      <div>
                        <Field
                          autoFocus
                          autoComplete="off"
                          type="text"
                          name="email"
                          component={ InputField }
                        />
                      </div>
                    </div>
                    <div className="form-group" css={ field_container }>
                      <label css={ label }>Password</label>
                      <div>
                        <Field
                          type="password"
                          name="password"
                          component={ InputField }
                        />
                      </div>
                    </div>
                    <div css={ footer }>
                      <div>
                        <BlueButton block type="submit" client={client}>
                          Log in
                        </BlueButton>
                      </div>
                      <div css={footer_separator}>&nbsp;</div>
                      <div>
                        <Button variant="link" onClick={ onRequestToken }>
                          Forgot your password?
                        </Button>
                      </div>
                    </div>
                    <div css={footer_separator}>&nbsp;</div>
                  </form>
              }
            </LoginLayout>
        )
    }
}
LoginForm = reduxForm({
    form: 'LOGIN_FORM'
})(LoginForm);
function mapStateToProps(state, props) {
    const { onSubmit, onRequestToken, tokenError } = props
    return {
        onSubmit,
        onRequestToken,
        tokenError
    }
}
export default withRouter(connect(mapStateToProps)(LoginForm))

const field_container = css`
width: 100%;
display:flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 50px;
flex-direction: column;
color: ${theme.colors.error};

`

const footer = css`
flex-direction: col;
text-align: center;
justify-content: space-between;
width: 100%;
height: 120px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const image = css`
width: 285px;
`

const logo_separator = css`
margin-top: 20px;
margin-bottom: 20px;
border-top: 1px solid #eee;
width: 100%;
`

const footer_separator = css`
height: 15px;
`

const form_style = css`
width: 100%;
`
