/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has } from 'lodash'
import ButtonBar from '../../components/ButtonBar'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import DefaultButton from '../../components/DefaultButton'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import TableFilter from '../../components/TableFilter'
import { Button } from 'react-bootstrap'
import { adminClientList } from '../actions/admin_client'
import Card from '../../components/layout/Card'

class AdminClients extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminClientList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminClientList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/client/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/client/')
    }

    filterClient = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminClientList.updateListFilter(filter_values))
    }

    sortOnHeader = (key) => {
        const { dispatch, client_filter } = this.props
        if (key !== 'action' && key !== 'admin_email') {
            let sort_by = key
            if (has(client_filter, 'order_by') && client_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(adminClientList.updateListFilter({'order_by': sort_by}))
        }
    }
    
    getClientCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return (
                    <Timestamp value={item.created_at} format='datetime' />
                )
            case 'action':
                return (
                    <ButtonBar>
                      <Button onClick={(evt) => this.onEdit(evt, item)}>
                        Edit
                      </Button>
                    </ButtonBar>
                )
            default:
                return undefined
        }
    }

    render() {
        const {
            client_headers,
            clients,
            is_loading,
            client_filter,
            breadcrumbs
        } = this.props
        
        return (
            <AdminMainLayout title="Client List" breadcrumbs={breadcrumbs}>
              <Card>
                <TableHeader>
                  <div css={ css`width:100%;`}>
                    <TableFilter
                        updateOnChange={ this.filterClient }
                        form="ADMIN_CLIENT_TABLE_FILTER_FORM"
                    />
                  </div>
                  <div css={ header }>
                    <DefaultButton
                        label="Add Client"
                        type="button"
                        light={ true }
                        onClickButton={ this.onAdd }
                    />
                  </div>
                </TableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message="There are no clients."
                    headers={ client_headers }
                    items={ clients }
                    getCellValue={ this.getClientCellValue }
                    sortOnHeader={ this.sortOnHeader }
                    item_list={adminClientList}
                />
                <TableFooter>
                </TableFooter>
              </Card>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const clients = adminClientList.getVisibleObjects() || []
    const breadcrumbs = [ {label: "Clients",
                           url: "/admin/clients"},
                          ]
    return {
        clients,
        client_filter: adminClientList.getFilter(),
        client_headers: {
            created_at: { name: 'Date Created', column_size: 1 },
            name: { name: 'Name', column_size: 1 },
            action: { name: 'Action', column_size: 1 }
        },
        is_loading: adminClientList.isLoading(),
        breadcrumbs
    }
}
export default withRouter(connect(mapStateToProps)(AdminClients))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
