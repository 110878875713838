/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { Formik, Form, FieldArray, Field } from 'formik'
import { userList } from '../actions/user'
import { imageCropList } from '../actions/image'
import { loggedInUserManager } from '../actions/logged_in_user'
import InputField from './InputField'
import { FormikInputField } from './form/InputField'
import DefaultButton from './DefaultButton'
import BusyMask from './BusyMask'
import { Button, Row, Col } from 'react-bootstrap'
import BlueButton from './layout/BlueButton'
import { InlineIcon } from './layout/InlineIcon'
import { FormikFileUploadField } from './form/FileUploader'
import { Separator } from './layout/Separator'
import ImageCropper from './ImageCropper'
import { default_theme as theme } from '../emotion/theme'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'email': Yup.string().required("Email is required"),
    'first_name': Yup.string().required("First name is required"),
    'last_name': Yup.string().required("Last name is required")
})

const supported_file_types = ".jpg, .png, .jpeg"
const upload_relative_url = 'file_info/'

const LEFT_WIDTH=2

class ProfileForm extends Component {

    constructor(props) {
        super(props)
        this.state = {cropping_image: null}
    }
    
    componentDidMount() {
        const { dispatch, user } = this.props
        dispatch(userList.ensureObjectLoaded(user.id))
    }


    onFileUploaded = (formik_props, file_info) => {
        this.setState({cropping_image: file_info})
    }

    hideImageCropper = () => {
        this.setState({cropping_image: null})
    }

    showImageCropper = (file_info) => {
        this.setState({cropping_image: file_info})
    }

    handleCrop = (crop, formik_props) => {
        const { dispatch, newImageFile, updateNewImageFile } = this.props
        const { cropping_image } = this.state
        const that = this
        const on_done = function(json) {
            formik_props.setFieldValue("profile_picture", json.fileinfo)
            that.hideImageCropper()
            dispatch(loggedInUserManager.invalidateUser())
            dispatch(loggedInUserManager.ensureUserLoaded())
        }
        return dispatch(imageCropList.update({image_id: cropping_image.id, crop: crop}, on_done))
    }

    renderPlaceHolder = (file) => {
        return (
            <div css={[msg_style, upload_msg_style]}>
              <div css={[upload_msg_style__icon, upload_msg_style__number_label]}>
                <InlineIcon icon_name="add" />
              </div>
              <div css={upload_file_label__text}>Click or drag here to upload a profile picture</div>
            </div>
        )
    }

    render() {
        const { onSave, onCancelProfile, onRequestToken, error, user, is_loading, initial_values } = this.props
        const { cropping_image } = this.state
        const that = this

        const otp_label = "Send OTP to: " + user.email
        
        return (

            <Formik
                  initialValues={initial_values}
                  onSubmit={onSave}
                  enableReinitialize={true}
                  validationSchema={validationSchema}
              >
              {formik_props => {
                  const { values } = formik_props
                  const profile_picture = get(values, "profile_picture")
                  return (
                      <Form>
                        { is_loading && <BusyMask /> }

                        { cropping_image && <ImageCropper
                                              image_id={ cropping_image.id }
                                              onCrop={ (crop) => this.handleCrop(crop, formik_props) }
                                              onCancel={ this.hideImageCropper }
                                            /> }

                        <Row>
                          <Col md={LEFT_WIDTH}>
                            <label css={ label }>First name</label>
                          </Col>
                          <Col>
                            <div>
                              <FormikInputField
                                type="text"
                                name="first_name"
                                component={ InputField }
                              />
                            </div>
                          </Col>
                        </Row>
                        
                        <Separator variant="h20" />
                        
                        <Row>
                          <Col md={LEFT_WIDTH}>
                            <label css={ label }>Last name</label>
                          </Col>
                          <Col>
                            <div>
                              <FormikInputField
                                type="text"
                                name="last_name"
                                component={ InputField }
                              />
                            </div>
                          </Col>
                        </Row>

                        <Separator variant="h20" />

                        <Row>
                          <Col md={LEFT_WIDTH}>
                            <label css={ label }>Password</label>
                          </Col>
                          <Col>
                            <div>
                              <FormikInputField
                                type="password"
                                name="password"
                                placeholder="(leave blank to retain existing password)"
                                component={ InputField }
                              />
                            </div>
                          </Col>
                        </Row>

                        <Separator variant="h20" />
                        
                        <Row>
                          <Col md={LEFT_WIDTH}>
                            <label css={ label }>Profile picture</label>
                          </Col>
                          <Col>
                            <div>
                              { profile_picture &&
                                <BlueButton onClick={() => this.showImageCropper(profile_picture)}>
                                  Crop
                                </BlueButton>
                              }
                              <FormikFileUploadField name="profile_picture"
                                                     upload_relative_url={upload_relative_url}
                                                     file_types={supported_file_types}
                                                     formik_props={formik_props}
                                                     renderPlaceHolder={() => that.renderPlaceHolder(initial_values.profile_picture)}
                                                     onFileUploaded={(file_info) => that.onFileUploaded(formik_props, file_info)}
                              />
                            </div>
                          </Col>
                        </Row>

                        <Separator variant="h20" />
                        
                        <div css={ footer }>
                          <div css={ error_container }>
                            { error && <p>{ error }</p>}
                          </div>
                          <BlueButton type="Submit">
                            SAVE CHANGES
                          </BlueButton>
                        </div>
                        
                      </Form>
                  )}}
            </Formik>
        )
    }
}

function mapStateToProps(state, props) {
    const { onSave, onRequestToken, onCancelProfile, user_id } = props
    const user = userList.getObject(user_id) || {}
    return {
        onSave,
        onRequestToken,
        onCancelProfile,
        initial_values: user,
        enableReinitialize: true,
        user,
        is_loading: userList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(ProfileForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 90%;
display: flex;
flex-direction: column;
`

const error_container = css`
p {
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
align-items: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
padding-bottom:20px;
`

const label = css`
color: ${theme.colors.secondary};
`

const msg_style = css`
display: flex;
text-align: center;
justify-content: space-between;
width: 100%;
border-radius: 4px;
padding-left: 13px;
padding-right: 14px;
padding-top: 8px;
height: 40px;
`

const uploaded_msg_style = css`
background-color: ${theme.colors.very_light_grey};
`

const upload_msg_style = css`
border: 1px dashed #979797;
background-color: ${theme.colors.nearly_white};
`

const upload_msg_style__icon = css`
margin-right: 10px;
color: #b8b8bd;
`

const upload_msg_style__number_label = css`
width: 15px;
text-align: right;
`

const uploaded_msg_style__icon = css`
margin-right: 9px;
color: #b8b8bd;
`

const upload_file_label__text = css`
left: 40px;
overflow: hidden;
width: 100%;
text-overflow: ellipsis;
white-space: nowrap;
`
