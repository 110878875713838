/** @jsx jsx */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../../emotion/theme'
import { connect } from 'react-redux'
import moment from 'moment'
import Form from 'react-bootstrap/Form'

export class FormLabelValue extends Component {

    render() {

        const label = this.props.children[0]
        const value = this.props.children.slice(1)
        
        return (
            <Form.Group>
              <Form.Label>{label}</Form.Label>
              {value}
            </Form.Group>
        )
        
    }

}
