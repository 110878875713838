import React from 'react'
import { round } from 'lodash'

export const FormattedNumber = ({value, num_digits}) => {

    value = round(value, num_digits)
    
    return (
        value
    )
    
}
