import React, { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'

import AdminDashboard from './AdminDashboard'
import AdminUsers from './AdminUsers'
import AdminUser from './AdminUser'
import AdminCourses from './AdminCourses'
import AdminCourse from './AdminCourse'
import AdminClients from './AdminClients'
import AdminClient from './AdminClient'
import AdminCertificateConfigs from './AdminCertificateConfigs'
import AdminCertificateConfig from './AdminCertificateConfig'
import AdminCourseSection from './AdminCourseSection'
import AdminCourseSectionQuestion from './AdminCourseSectionQuestion'
import AdminCourseSectionQuestionOption from './AdminCourseSectionQuestionOption'
import AdminUserCourseReport from './AdminUserCourseReport'
import AdminClientGroupUsers from './AdminClientGroupUsers'
import AdminEditableEmails from './AdminEditableEmails'
import AdminEditableEmail from './AdminEditableEmail'
import AdminOutboundEmails from './AdminOutboundEmails'

import AdminCoursePackages from './AdminCoursePackages'
import AdminCoursePackage from './AdminCoursePackage'
import AdminCoursePackageUsers from './AdminCoursePackageUsers'

import { isLoggedIn, loggedInUser } from '../../actions/auth'

class AuthRouter extends Component {

    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/admin/dashboard')
            } else {
                history.push('/')
            }
        }
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/login')
        }
    }
    
    render() {
        const { is_logged_in, is_superuser } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  Not logged in
                </div>
            )
        }

        return (
            <div>
              <Switch>
                <Route path="/admin/dashboard" exact={ true } component={ AdminDashboard } />
                <Route path="/admin/clients" exact={ true } component={ AdminClients } />
                <Route path="/admin/client/:client_id" exact={ true } component={ AdminClient } />
                <Route path="/admin/client" exact={ true } component={ AdminClient } />
                <Route path="/admin/courses" exact={ true } component={ AdminCourses } />
                <Route path="/admin/course/:course_id" exact={ true } component={ AdminCourse } />
                <Route path="/admin/course/" exact={ true } component={ AdminCourse } />
                <Route path="/admin/course_section/:course_section_id" exact={ true } component={ AdminCourseSection } />
                <Route path="/admin/course_section_question/:course_section_question_id" exact={ true } component={ AdminCourseSectionQuestion } />
                <Route path="/admin/course_section_question_option/:course_section_question_option_id" exact={ true } component={ AdminCourseSectionQuestionOption } />
                <Route path="/admin/reports/users/" exact={ true } component={ AdminUserCourseReport } />
                <Route path="/admin/client/:client_id/users" exact={ true } component={ AdminUsers } />
                <Route path="/admin/client/:client_id/user/:user_id" component={ AdminUser } />
                <Route path="/admin/client/:client_id/user" exact={ true } component={ AdminUser } />
                <Route path="/admin/client/:client_id/group/:group_id/users" exact={ true } component={ AdminClientGroupUsers } />
                <Route path="/admin/certificate_configs" exact={ true } component={ AdminCertificateConfigs } />
                <Route path="/admin/certificate_config" exact={ true } component={ AdminCertificateConfig } />
                <Route path="/admin/certificate_config/:certificate_config_id" exact={ true } component={ AdminCertificateConfig } />
                <Route path="/admin/outbound_emails" exact={ true } component={ AdminOutboundEmails } />
                <Route path="/admin/editable_emails" exact={ true } component={ AdminEditableEmails } />
                <Route path="/admin/editable_email/:editable_email_id" exact={ true } component={ AdminEditableEmail } />
                
                <Route path="/admin/client/:client_id/course_packages" exact={ true } component={ AdminCoursePackages } />
                <Route path="/admin/client/:client_id/course_package/:course_package_id/users" exact={ true } component={ AdminCoursePackageUsers } /> 
                <Route path="/admin/client/:client_id/course_package/:course_package_id" component={ AdminCoursePackage } />
                <Route path="/admin/client/:client_id/course_package" exact={ true } component={ AdminCoursePackage } />

                
                <Route path="/admin" component={ AdminDashboard } />
              </Switch>
            </div>
        )
    }
}
function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
    }
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser,
    }
}
export default withRouter(connect(mapStateToProps)(AuthRouter))
