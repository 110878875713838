import { ItemList } from '../../orm'

class FileImportList extends ItemList {
    getEntityKey() {
        return "file_import/process"
    }

    process(values) {
        return this.saveNewObject(values)
    }
}

class FileImportAddList extends ItemList {
    getEntityKey() {
        return "file_import/add_users_to_course"
    }

    process(values) {
        return this.saveNewObject(values)
    }
}

export const fileImportList = new FileImportList("file_import/process")
export const fileImportAddList = new FileImportAddList("file_import/add_users_to_course")
