import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'
import moment from 'moment'

class CustomerAnswerList extends ItemList {
    getEntityKey() {
        return "customer_answer"
    }

    onAnsweredQuestion(customer_course_id, question_id, option_id) {
        return this.saveNewObject({customer_course: customer_course_id, question: question_id, option: option_id})
    }
    
}

export const customerAnswerList = new CustomerAnswerList("customer_answer_list__default")
