/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { get, map, size } from 'lodash'
import { Link } from 'react-router-dom'
import Timestamp from '../../components/Timestamp'
import { adminClientList } from '../actions/admin_client'
import { adminClientGroupList } from '../actions/admin_client_group'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminClientForm from './AdminClientForm'
import AdminClientGroups from './AdminClientGroups'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {NotificationManager} from 'react-notifications'
import Card from '../../components/layout/Card'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Separator } from '../../components/layout/Separator'

class AdminClient extends Component {

    componentDidMount() {
        const { dispatch, client_id } = this.props
        if ( client_id ) {
            dispatch(adminClientList.ensureObjectLoaded(client_id))
        }
        dispatch(adminClientGroupList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, client_id } = this.props
        if ( client_id ) {
            dispatch(adminClientList.ensureObjectLoaded(client_id))
        }
        dispatch(adminClientGroupList.fetchListIfNeeded())
    }

    onSubmit = (values) => {
        const { dispatch, client_id, history } = this.props
        const that = this
        values.active = values.status ? values.status.value : true
        const on_done = function(json) {
            dispatch(adminClientList.invalidateList())
            NotificationManager.success("Saved", "Client saved")
            if ( ! client_id ) {
                history.push(`/admin/client/${json.id}`)
            }
        }
        if ( client_id ) {
            values.id = client_id
            return dispatch(adminClientList.saveObject(values, on_done))
        } else {
            return dispatch(adminClientList.saveNewObject(values, on_done))
        }
    }

    onCancel = () => {
        const { history } = this.props
        history.push('/admin/clients')
    }
    
    render() {
        const { client_id, is_busy, is_loading, client, page, breadcrumbs } = this.props

        const title = client_id ? 'Edit Client' : 'Add Client'
        return (
            <AdminMainLayout title={title} breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              { (!client_id || client.id) &&
                <Row>
                  <Col md="6">
                    <AdminClientForm
                      onSubmit={ this.onSubmit }
                      onCancel={ this.onCancel }
                      client_id={ client_id }
                    />
                  </Col>
                </Row>
              }
              { client_id &&
                <div>
                  <Separator variant="h30" />
                  <Row>
                    <Col>
                      <Row>
                        <Col>
                          <AdminClientGroups client_id={client_id} />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </div>
              }
              { client &&
                <div>
                  <div>
                    <Link to={`/admin/client/${client_id}/users`}>Manage users for this client (currently {client.num_users})</Link>
                  </div>
                  <div>
                    <Link to={`/admin/client/${client_id}/course_packages`}>Manage courses for this client (currently {client.num_course_packages})</Link>
                  </div>
                </div>
              }
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const { page } = props
    const client_id = get(props, ["match", "params", "client_id"], null)
    const client = client_id && adminClientList.getObject(client_id)
    const breadcrumbs = [ {label: "Clients",
                           url: "/admin/clients"},
                          {label: client && client.name,
                           url: client &&`/admin/client/${client_id}`}]
    return {
        client_id,
        client,
        page,
        breadcrumbs,
        is_loading: adminClientList.isLoading(),
        is_busy: adminClientList.getIsSavingObject()
    }
}
export default withRouter(connect(mapStateToProps)(AdminClient))

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`
