/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { head, get, map, size, filter } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import { jsx, css } from '@emotion/core'
import BlueButton from '../layout/BlueButton'
import { Separator } from '../layout/Separator'
import AssessmentScoreProgressBar from './AssessmentScoreProgressBar'
import SummaryAssessmentScore from './SummaryAssessmentScore'
import SummaryAssesmentSummaryMessage from './SummaryAssesmentSummaryMessage'

class AssessmentSummary extends Component {

    componentDidMount() {
        window.scroll(0, 0)        
    }
    
    onContinue = () => {
        const { onContinue } = this.props
        onContinue()
    }

    onRedo = () => {
        const { onRedo } = this.props
        onRedo()
    }

    onRedoFromMedia = () => {
        const { onRedoFromMedia } = this.props
        onRedoFromMedia()
    }
    
    render() {
        const { customer_section } = this.props
        const passed = customer_section.passed
        return (
            <div>
              { passed === true && this.renderPassedAssessmentSummary() }
              { passed !== true && this.renderFailedAssessmentSummary() }
            </div>
        )
    }

    renderFailedAssessmentSummary() {
        const { customer_section, course_section } = this.props
        const score_percentage = get(customer_section, "score_percentage", null)
        return (
            <div>
              <SummaryAssessmentScore score_percentage={score_percentage}
                                      extra_css={section_summary__failed} />

              <SummaryAssesmentSummaryMessage title="Unfortunately, you did not pass the assessment."
                                              msg="You will need to restart the assessment to proceed.">
                
                <div css={section_summary_button_container}>
                  <BlueButton css={summary_button_style} size="lg" onClick={this.onRedoFromMedia}>
                    VIEW VIDEOS AND DOCUMENTS AGAIN
                  </BlueButton>
                  <Separator variant="w20" />
                  <BlueButton css={summary_button_style} size="lg" onClick={this.onRedo}>
                    RETRY ASSESSMENT
                  </BlueButton>
                </div>

                <Separator variant="h50" />

                <AssessmentScoreProgressBar num_questions_passed={get(customer_section, "num_questions_passed")}
                                            num_questions={get(customer_section, "num_questions")}
                                            passed={false}
                                            score_percentage={get(customer_section, "score_percentage", null)}
                />
              </SummaryAssesmentSummaryMessage>

              
            </div>
        )
    }

    renderPassedAssessmentSummary() {
        const { customer_section, course_section } = this.props
        const score_percentage = get(customer_section, "score_percentage", null)
        const has_next_section = !course_section.is_last_section

        const msg = has_next_section ? "You can now proceed to the next module" :
              "This was the last module in this course, proceed to view your overall result."
        
        return (
            <div>
              <SummaryAssessmentScore score_percentage={score_percentage}
                                      extra_css={section_summary__passed} />

              <SummaryAssesmentSummaryMessage title="Congratulations, you have passed this assessment!" msg={msg}>
                
                <div css={section_summary_button_container}>
                  
                  <BlueButton css={summary_button_style} size="lg" onClick={this.onContinue}>
                    { has_next_section && 
                      <span>CONTINUE LEARNING</span>
                    }
                    { ! has_next_section && 
                      <span>COMPLETE COURSE</span>
                    }
                  </BlueButton>
                </div>

                <Separator variant="h50" />

                <AssessmentScoreProgressBar num_questions_passed={get(customer_section, "num_questions_passed")}
                                            num_questions={get(customer_section, "num_questions")}
                                            passed={true}
                                            score_percentage={get(customer_section, "score_percentage", null)}
                />
                
              </SummaryAssesmentSummaryMessage>

              <Separator variant="h80" />
              
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {}
}
    

export default connect(mapStateToProps)(AssessmentSummary)

const section_summary__passed = css`
background: #48B02C;
`

const section_summary__failed = css`
background: #ED2629;
`


const section_summary_button_container = css`
display: flex;
justify-content: center;
`

const summary_button_style = css`
font-size: 14px;
`
