/** @jsx jsx */
import { Component } from 'react'
import { default_theme as theme } from '../../emotion/theme'
import { connect } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { jsx, css } from '@emotion/core'
import BlueButton from '../layout/BlueButton'
import Pluralize from 'react-pluralize'
import { clientManager } from '../../actions/client_manager'
import { Separator } from '../layout/Separator'
import { CourseCardOverlay } from './CourseCardOverlay'
import rocket_img from '../../images/start_assessment.png'
import { faPlayCircle, faFileAlt, faClock } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import BusyMask from '../BusyMask'
import ReplacementText from '../ReplacementText'

class CourseIntro extends Component {

    componentDidMount() {
        window.scroll(0, 0)        
    }
    
    onContinue = () => {
        const { onContinue, assessment } = this.props
        onContinue(assessment)
    }
    
    render() {
        const { course, customer_course } = this.props
        const has_started = get(customer_course, "progress_percentage", 0) > 0

        if (! course.banner_image.original_download_url ) {
            return <BusyMask loading={true} />
        }
        return (
            <div css={course_banner_container}>
              <div css={ [course_banner_image_container, css`{background-image: url(${course.banner_image.original_download_url})}`] }>
                &nbsp;
              </div>
              <CourseCardOverlay>
                <div css={rocket_icon_style}>
                  <img css={rocket_icon_img_style} src={rocket_img} />
                </div>
                <div css={banner_header_style}>
                  { has_started && "CONTINUE COURSE" }
                  { !has_started && "START COURSE" }
              </div>

              <Separator variant="h30" />
              
              <div css={banner_blurb_style}>
                You are about to start this course and will need to pass the
                &nbsp;<ReplacementText
                          placeholder="assessment"
                          text={ customer_course && customer_course.course_package && customer_course.course_package.assessment_replacement_text }
                          value={ course.num_assessments }
                          showCount={ false }
                />&nbsp;
            to proceed to the next course module and complete the course.
              </div>

              <Separator variant="h30" />
              
              <div css={banner_info_style}>
                <div css={info_detail_item_style}>
                  <div css={icon_style}>
                    <FontAwesomeIcon css={info_icon} icon={faPlayCircle} />
                  </div>
                  <ReplacementText
                      placeholder="video module"
                      text={ customer_course && customer_course.course_package && customer_course.course_package.video_replacement_text }
                      value={ course.num_videos }
                  />
                </div>

                <div css={info_detail_item_style}>
                  <div css={icon_style}>
                    <FontAwesomeIcon css={info_icon} icon={faFileAlt} />
                  </div>
                  <ReplacementText
                      placeholder="assessment"
                      text={ customer_course && customer_course.course_package && customer_course.course_package.assessment_replacement_text }
                      value={ course.num_assessments }
                  />
                </div>

                <div css={info_detail_item_style}>
                  <div css={icon_style}>
                    <FontAwesomeIcon css={info_icon} icon={faClock} />
                  </div>
                  <Pluralize singular="minute" count={Math.round(course.duration_minutes)} />
                </div>

              </div>

              <Separator variant="h40" />
              
              <div>
                <BlueButton onClick={this.onContinue} css={start_learning_button} >
                  { has_started && "CONTINUE COURSE" }
                  { !has_started && "START COURSE" }
                </BlueButton>
              </div>
              </CourseCardOverlay>
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {}
}
    

export default connect(mapStateToProps)(CourseIntro)

const course_banner_container = css`
background-repeat: no-repeat;
background-size: cover;

position: relative;
display: flex;
justify-content: center;
align-items: center;
height: 100%;
min-height: 640px;

`

const course_banner_image_container = css`
filter: blur(8px);
-webkit-filter: blur(8px);
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
object-fit: cover;
object-position: 0 0;
`

const course_banner_overlay_card_style = css(theme.mq({
    position: "absolute",
    width: ["420px", "auto"],
    textAlign: "center",
    backgroundColor: "#fff",
    padding: ["30px", "15px"],
    borderRadius: "4px"
}))

const rocket_icon_style = css`
`

const rocket_icon_img_style = css`
height: 70px;
`

const banner_header_style = css`
font-size: 28px;
`

const banner_blurb_style = css`
text-align: center;
`

const banner_info_style = css`
display: flex;
justify-content: space-between;
`

const icon_style = css`
width: 15px;
margin-right: 5px;
display: flex;
align-items: center;
`

const info_icon = css`
margin-right: 5px;
`

const info_detail_item_style = css`
display: flex;
`

const start_learning_button = css`
font-size: 20px;
padding-left: 24px;
padding-right: 24px;
height: 50px;
&:hover {
}
`
