import { ItemList } from '../orm'

class ImageList extends ItemList {
    getEntityKey() {
        return "file_info"
    }
}

export const imageList = new ImageList("image_default")

class ImageCropList extends ItemList {
    getEntityKey() {
        return 'file_info/crop'
    }

    update(values, on_done) {
        return this.saveNewObject(values, on_done)
    }
}

export const imageCropList = new ImageCropList('image_crop__default')
