/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { get } from 'lodash'
import { jsx, css,  Global } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'
import { supportList } from '../actions/support'
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GrayButton } from './layout/GrayButton'
import { FormikTextarea } from './form/TextareaField'
import { faHeadset } from '@fortawesome/free-solid-svg-icons'
import { OverlayTrigger, Popover } from 'react-bootstrap'
import { Formik, Form, FieldArray, Field } from 'formik'
import WrappingBusyMask from './WrappingBusyMask'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    message: Yup.string().required("Required")
})

class SupportPopup extends Component {

    constructor(props) {
        super(props)
        this.state = {show_support_form: false,
                      support_message_sent: false}
    }

    onShowSupportForm = () => {
        this.setState({show_support_form: true,
                      support_message_sent: false})
    }

    onHideSupportForm = () => {
        this.setState({show_support_form: false,
                       support_message_sent: false})
        document.body.click()
    }

    onSend = (values) => {
        const { dispatch } = this.props
        const that = this

        const on_done = () => {
            that.setState({support_message_sent: true})
        }
        dispatch(supportList.saveNewObject(values, on_done))
    }

    renderSupportMessageSent() {
        return (
            <div css={form_style}>
              <div>
                You message has been sent, if appropriate you will receive a response via your account email.
              </div>
              <GrayButton onClick={this.onHideSupportForm} auto_disable={true}>
                Close
              </GrayButton>
            </div>
        )
    }

    renderSupportForm() {
        const { is_sending } = this.props
        return (
            <Formik
              initialValues={{}}
              onSubmit={this.onSend}
              enableReinitialize={true}
              validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        <WrappingBusyMask is_loading={is_sending}>
                          <div css={form_style}>
                            <div>
                              <div>
                                <FormikTextarea name="message"
                                                formik_props={formik_props}
                                                placeholder="Enter your message here"
                                  />
                              </div>
                            </div>

                            <GrayButton type="submit" auto_disable={false}>Send</GrayButton>
                          </div>
                        </WrappingBusyMask>
                      </Form>
                  )
              }}
            </Formik>
        )
    }
    
    render() {
        const { show_support_form, support_message_sent } = this.state
        return (
            <div css={container} >

              <OverlayTrigger
                trigger="click"
                key="top"
                placement="top"
                rootClose={support_message_sent}
                overlay={
                    <Popover id={`popover-positioned`} css={popup_style} >
                      <Popover.Title as="h3">{`Contact support`}</Popover.Title>
                      <Popover.Content>
                        { support_message_sent && this.renderSupportMessageSent() }
                        { ! support_message_sent && this.renderSupportForm() }
                      </Popover.Content>
                    </Popover>
                }
              >
                <div css={trigger}>
                  <FontAwesomeIcon css={icon_style} icon={faHeadset} />
                  Support
                </div>
                
              </OverlayTrigger>
            </div>
        )
    }

}

function mapStateToProps(state, props) {
    const { is_loading } = props
    return {
        is_loading: is_loading || (props.item_list && props.item_list.isLoading()),
        is_sending: supportList.getIsSavingObject()
    }
}

export default connect(mapStateToProps)(SupportPopup)

const container = css(theme.mq({
    position: ["fixed", "fixed"],
    bottom: ["37px", "20px"],
    right: ["37px", "8px"],
    backgroundColor: "#3586eb",
    borderRadius: "10px",
    height: "25px",
    width: "80px",
    color: "#ffffff",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "14px",
    zIndex: "9999"
}))

const trigger = css`
cursor: pointer;
`

const icon_style = css`
margin-right: 5px;
color: #000;
`

const popup_style = css(theme.mq({
    minWidth: ["300px", "250px"],
    width: ["300px", "250px"],
    minHeight: ["200px", "auto"],
    height: ["200px", "auto"],
    zIndex: "9999"
}))

const form_style = css`
display: flex;
justify-content: space-between;
flex-direction: column;
`
