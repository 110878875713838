/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../../emotion/theme'
import { head, get, map, keys, split, size } from 'lodash'
import { reset } from 'redux-form'
import { adminEditableEmailList } from '../actions/admin_editable_email'
import { Separator } from '../../components/layout/Separator'
import { Formik, Form, FieldArray, Field } from 'formik'
import Loading from '../../components/Loading'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikInputField } from '../../components/form/InputField'
import AdminMainLayout from './AdminMainLayout'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import Card from '../../components/layout/Card'
import { Col, Row, Container, Navbar, Nav } from 'react-bootstrap'
import { Form as BootstrapForm } from 'react-bootstrap'
import * as Yup from 'yup'
import {onSuccess, onError} from '../../actions/notification'
import Button from 'react-bootstrap/Button'
import CardInfoText from '../../components/layout/CardInfoText'

const validationSchema = Yup.object().shape({
    'subject': Yup.string().required("Required"),
    'html_content': Yup.string().required("Required"),
    'text_content': Yup.string().required("Required")
})

class AdminEditableEmail extends Component {

    componentDidMount() {
        const { dispatch, editable_email_id } = this.props
        dispatch(adminEditableEmailList.ensureObjectLoaded(editable_email_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, editable_email_id } = this.props
        dispatch(adminEditableEmailList.ensureObjectLoaded(editable_email_id))
    }

    onSave = (values, setSubmitting, setFieldError) => {
        const { onSubmit, dispatch, editable_email_id } = this.props

        const on_done = function(json) {
            dispatch(adminEditableEmailList.invalidateList())
            onSuccess("Saved", "Email saved")
        }

        if ( editable_email_id ) {
            values.id = editable_email_id
            return dispatch(adminEditableEmailList.saveObject(values, on_done))
        } else {
            return dispatch(adminEditableEmailList.saveNewObject(values, on_done))
        }

        onSubmit(values).then( (res) => {
            setSubmitting(false)
            if ( res.errors ) {
                map(keys(res.errors), (field_name) => setFieldError(field_name, res.errors[field_name]))
            }
        })
    }
    
    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            default:
                return undefined
        }
    }

    renderParameterTips() {
        return (
            <Card title="Parameter tips">
              <Separator variant="h10" />
              <div>Time: {"{{parameter|date:'H:i'}}"}
                <CardInfoText>10:30</CardInfoText>
              </div>
              <hr/>
              <div>Date: {"{{parameter|date:'Y-m-d'}}"}
                <CardInfoText>2020-06-10</CardInfoText>
              </div>
              <hr/>
              <div>Date (alternative): {"{{parameter|date:'d-M-Y'}}"}
                <CardInfoText>10 June 2020</CardInfoText>
              </div>
              <div>Date + Time: {"{{parameter|date:'d-M-Y H:i'}}"}
                <CardInfoText>10 June 2020 10:30</CardInfoText>
              </div>
              <hr/>
            </Card>
        )
    }

    renderAvailableParameters() {
        const { editable_email } = this.props
        const available_parameters_list = split(editable_email.available_parameters, ",")
        const static_parameter_list = split(editable_email.static_parameters, ",")

        return (
            <Card title="Available parameters">
              
              { size(available_parameters_list) === 0 &&
                <div>
                  No parameters are available for this email.
                </div>
              }
              { size(available_parameters_list) > 0 &&
                <div>
                  <CardInfoText>
                    To use a parameter, put it anywhere in the email between two curly braces, for example:
                    <hr/>
                    Dear {"{{customer.user.first_name}}"},
                    <hr/>

                  </CardInfoText>
                  
                  { map(available_parameters_list, function(parameter, index) { if ( parameter ) { return <div key={index}>{`{{${parameter}}}`}</div> } })}
                  { map(static_parameter_list, function(parameter, index) { if ( parameter ) { return <div key={index}>{`{{${parameter}}}`}</div> } })}
                </div>
              }
            </Card>
        )
    }

    renderForm(formik_props) {

        return (
            <div>
              <Row>
                <Col>
                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Subject</BootstrapForm.Label>
                    <FormikTextarea name="subject" rows={2} placeholder="subject" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Html Content</BootstrapForm.Label>
                    <FormikTextarea name="html_content" place_holder="Html Content" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Text Content</BootstrapForm.Label>
                    <FormikTextarea name="text_content" place_holder="Text Content" />
                  </BootstrapForm.Group>

                  <BootstrapForm.Group>
                    <BootstrapForm.Label>Header name</BootstrapForm.Label>
                    <CardInfoText>
                      Used in the header.
                    </CardInfoText>
                    <FormikInputField
                        name="param_header_name"
                        type="text"
                        placeholder="Header name"
                    />
                  </BootstrapForm.Group>
                  
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit">Save</Button>
                </Col>
              </Row>
            </div>
        )
    }

    render() {
        const { initial_values, is_loading, is_busy, editable_email, editable_email_id } = this.props
        const that = this
        const title = `${get(editable_email, ["description"])}`

        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           
                                           {name: 'editable_emails',
                                            label: 'Editable Emails',
                                            url: '/admin/editable_emails'},
                                           
                                           {name: 'editable_email',
                                            label: get(editable_email, ["name"], ''),
                                            url: `/admin/editable_email/${editable_email_id}`
                                           }
            ]}>
              <Container fluid>
                { is_busy && <BusyMask /> }
                { is_loading && <Loading /> }
                <h1>{title}</h1>

                <Separator variant="h20" />
                
                { editable_email && editable_email.id && 
                  
                  <Formik
                      initialValues={initial_values}
                      onSubmit={(values, { setSubmitting, setFieldError }) => {that.onSave(values, setSubmitting, setFieldError)}}
                      enableReinitialize={true}
                      validationSchema={validationSchema}
                      >
                    {formik_props => {
                         const { values } = formik_props
                         return (
                             <Form>
                               <Row>
                                 <Col>
                                   <Card>
                                     { is_loading && <Loading /> }
                                     { that.renderForm(formik_props) }
                                   </Card>
                                 </Col>
                                 <Col>
                                   { ! is_loading && that.renderAvailableParameters() }
                                   { this.renderParameterTips() }
                                 </Col>
                               </Row>
                             </Form>
                         )}
                    }
                  </Formik>
                }
              </Container>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const editable_email_id = get(props, ["match", "params", "editable_email_id"], null)
    const editable_email = adminEditableEmailList.getObject(editable_email_id)
    return {
        editable_email_id,
        editable_email,
        is_loading: adminEditableEmailList.isLoading(),
        is_busy: adminEditableEmailList.getIsSavingObject(),
        initial_values: editable_email,
    }
}

export default connect(mapStateToProps)(AdminEditableEmail)

const breadcrumb_item = css`
display: flex;
align-items: center;
`
