import { ItemList } from '../../orm'
import { has, union, map } from 'lodash'
import { http } from '../../lib'

class AdminCourseSectionQuestionOptionList extends ItemList {
    getEntityKey() {
        return 'admin/course_section_question_option'
    }

}

export const adminCourseSectionQuestionOptionList = new AdminCourseSectionQuestionOptionList('admin_course_section_question_option__default')
