/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/core'
import { reset } from 'redux-form'
import { head, get } from 'lodash'

import { sendTokenEmail, updateUser } from '../actions/auth'
import { userList } from '../actions/user'

import MainLayout from './MainLayout'
import EmailForm from './NewEmailForm'
import BusyMask from './BusyMask'

class NewEmail extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(userList.invalidateList())
        dispatch(userList.fetchListIfNeeded())
    }
    
    handleSubmit = (values) => {
        const { dispatch, history, new_email } = this.props
        values.new_email = new_email
        const on_done = function() {
            history.push('/dashboard')
        }
        return dispatch(updateUser(values, on_done))
    }

    onRequestToken = () => {
        const { dispatch, user, new_email } = this.props
        const values = Object.assign({},
                                     user,
                                     {email: new_email})
        dispatch(sendTokenEmail(values))
    }
    
    onCancelEmail = () => {
        const { dispatch, history } = this.props
        dispatch(reset('EMAIL_FORM'))
        history.push('/dashboard')
    }
    
    render() {
        const { user, new_email, is_busy } = this.props
        return (
            <MainLayout title='Update Email Address' layout="single">
              { is_busy && <BusyMask /> }
              <EmailForm
                  onSubmit={ this.handleSubmit }
                  onRequestToken ={ this.onRequestToken }
                  onCancelEmail={ this.onCancelEmail }
                  new_email={ new_email }
                  user_id={ user.id }
              />
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const new_email = get(props, ["match", "params", "new_email"], null)
    const user = head(userList.getVisibleObjects()) || {}
    return {
        user,
        new_email,
        is_busy: userList.isLoading()
    }
}
export default connect(mapStateToProps)(NewEmail)

