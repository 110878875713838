import { ItemList } from '../orm'
import { loggedInUser, announceUserUpdated } from './auth'
import { get, head } from 'lodash'

class LoggedInUserList extends ItemList {
    getEntityKey() {
        return "user/fetch_logged_in_user"
    }

    ensureUserLoaded() {
        const that = this
        return async (dispatch, getState) => {
            dispatch(this.fetchListIfNeeded())
            const user_id = get(this.getUser(), "id")
            if ( user_id ) {
                dispatch(that.ensureObjectLoaded(user_id))
            }
        }
    }

    invalidateUser() {
        const user_id = get(this.getUser(), "id")
        return this.invalidateObject(user_id)
    }

    getUser() {
        return head(this.getVisibleObjects())
    }
    
}

export const loggedInUserManager = new LoggedInUserList("logged_in_user__default")
