/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { get, map, size } from 'lodash'
import { Link } from 'react-router-dom'
import Timestamp from '../../components/Timestamp'
import { adminClientList } from '../actions/admin_client'
import CommonTable from '../../components/CommonTable'
import { adminClientGroupList } from '../actions/admin_client_group'
import { adminUserInGroupList, adminUserNotInGroupList, adminUserGroupManagerList } from '../actions/admin_user'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminClientForm from './AdminClientForm'
import AdminClientGroups from './AdminClientGroups'
import BusyMask from '../../components/BusyMask'
import { InlineIcon } from '../../components/layout/InlineIcon'
import Modal from '../../components/Modal'
import {NotificationManager} from 'react-notifications'
import Card from '../../components/layout/Card'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'

class AdminClientGroupUsers extends Component {

    componentDidMount() {
        const { dispatch, client_id, group_id } = this.props
        dispatch(adminClientList.ensureObjectLoaded(client_id))
        dispatch(adminClientGroupList.ensureObjectLoaded(group_id))
        dispatch(adminUserInGroupList.updateListFilter({in_group:group_id, client: client_id}))
        dispatch(adminUserNotInGroupList.updateListFilter({not_in_group:group_id, client: client_id}))

        dispatch(adminUserInGroupList.fetchListIfNeeded())
        dispatch(adminUserNotInGroupList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, client_id, group_id } = this.props
        dispatch(adminClientList.ensureObjectLoaded(client_id))
        dispatch(adminClientGroupList.ensureObjectLoaded(group_id))
        dispatch(adminUserInGroupList.fetchListIfNeeded())
        dispatch(adminUserNotInGroupList.fetchListIfNeeded())
    }

    onAddUser = (user) => {
        const { dispatch, client_id, group_id } = this.props

        const on_done = function(json) {
            dispatch(adminUserNotInGroupList.invalidateList())
            dispatch(adminUserInGroupList.invalidateList())
            dispatch(adminClientGroupList.invalidateObject(group_id))
            dispatch(adminClientList.invalidateObject(client_id))
        }
        dispatch(adminUserGroupManagerList.addToGroup(client_id, user.id, group_id, on_done))
    }

    onRemoveUser = (user) => {
        const { dispatch, client_id, group_id } = this.props

        const on_done = function(json) {
            dispatch(adminUserInGroupList.invalidateList())
            dispatch(adminUserNotInGroupList.invalidateList())
            dispatch(adminClientGroupList.invalidateObject(group_id))
            dispatch(adminClientList.invalidateObject(client_id))
        }
        dispatch(adminUserGroupManagerList.removeFromGroup(client_id, user.id, group_id, on_done))
    }
        
    getUserInGroupCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'phone_number':
                return item.phone_number
            case 'email':
                return item.email
            case 'action':
                return <InlineIcon icon_name="remove" onClick={() => this.onRemoveUser(item)} />
            default:
                return <span>Unknown header {header_key}</span>
        }
    }

    getUserNotInGroupCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'first_name':
                return item.first_name
            case 'last_name':
                return item.last_name
            case 'phone_number':
                return item.phone_number
            case 'email':
                return item.email
            case 'action':
                return <InlineIcon icon_name="add" onClick={() => this.onAddUser(item)} />
            default:
                return <span>Unknown header {header_key}</span>
        }
    }

    onDone = () => {
        const { history, client_id, group_id } = this.props
        history.push(`/admin/client/${client_id}/`)
    }

    render() {
        const { client_id, is_busy, is_loading, client, group,
                page, breadcrumbs, user_headers, users_in_group, users_not_in_group } = this.props

        const title = `Manage users for group ${get(group, "name")}`
        return (
            <AdminMainLayout title={title} breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              { (!client_id || client.id) &&
                <Row>
                  <Col md="6">
                    <Card title="Users in the group">
                      <CommonTable
                        is_loading={ is_loading }
                        empty_message={`No added users`}
                        headers={ user_headers }
                        items={ users_in_group }
                        getCellValue={ this.getUserInGroupCellValue }
                        item_list={adminUserInGroupList}
                      />
                    </Card>
                  </Col>
                  <Col md="6">
                    <Card title="Users not in the group">
                      <CommonTable
                        is_loading={ is_loading }
                        empty_message={`No available users`}
                        headers={ user_headers }
                        items={ users_not_in_group }
                        getCellValue={ this.getUserNotInGroupCellValue }
                        item_list={adminUserNotInGroupList}
                      />
                    </Card>
                  </Col>
                </Row>
              }
              <Button onClick={this.onDone}>
                Done
              </Button>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const { page } = props
    const client_id = get(props, ["match", "params", "client_id"], null)
    const group_id = get(props, ["match", "params", "group_id"], null)
    const client = client_id && adminClientList.getObject(client_id)
    const group = group_id && adminClientGroupList.getObject(group_id)
    const breadcrumbs = [ {label: "Clients",
                           url: "/admin/clients"},
                          {label: client && client.name,
                           url: client &&`/admin/client/${client_id}`},
                          {label: group && group.name,
                           url: group && `/admin/client/${client_id}`},
                          {label: "Users",
                           url: group && `/admin/client/${client_id}/group/${group_id}/Users`}]
    const users_in_group = adminUserInGroupList.getVisibleObjects()
    const users_not_in_group = adminUserNotInGroupList.getVisibleObjects()
    return {
        client_id,
        client,
        group_id,
        group,
        page,
        breadcrumbs,
        is_loading: adminClientList.isLoading() || adminClientGroupList.isLoading() || adminUserInGroupList.isLoading() || adminUserNotInGroupList.isLoading(),
        is_busy: adminClientList.getIsSavingObject() || adminClientGroupList.getIsSavingObject() || adminUserInGroupList.getIsSavingObject() || adminUserNotInGroupList.getIsSavingObject(),
        users_in_group,
        users_not_in_group,
        user_headers: {
            last_name: { name: 'Last name', column_size: 2},
            first_name: { name: 'First name', column_size: 2},
            email: { name: 'Email Address', column_size: 2},
            action: { name: '', column_size: 1 }
        }
    }
}
export default withRouter(connect(mapStateToProps)(AdminClientGroupUsers))

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`
