import { ItemList } from '../orm'
import { constructClientGroupTree } from './lib'
import { head } from 'lodash'

class ClientGroupList extends ItemList {
    getEntityKey() {
        return 'client_group'
    }

    ensureTreeisLoaded() {
        return this.fetchListIfNeeded()
    }
    
    getTree() {
        const root_client_group = head(this.getVisibleObjects())
        return constructClientGroupTree(root_client_group)
    }

}

export const clientGroupList = new ClientGroupList('client_group__default')
