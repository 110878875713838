import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'
import moment from 'moment'

class CustomerCourseActions extends ItemList {
    getEntityKey() {
        return "customer_course/action"
    }

    startFromCoursePackage(course_package_id, on_done) {
        const data = {action: 'start_from_package',
                      course_package: course_package_id}
        return this.saveNewObject(data, on_done)
    }

    resetSection(customer_course_id, course_section_id, on_done) {
        const data = {action: 'reset_section',
                      customer_course: customer_course_id,
                      course_section: course_section_id}
        return this.saveNewObject(data, on_done)
    }
    
    resetCourse(customer_course_id, on_done) {
        return this.saveNewObject({action: 'reset_all', customer_course: customer_course_id}, on_done)
    }

    resetCourseToFirstVideoOrDocumentInSequence(customer_course_id, on_done) {
        return this.saveNewObject({action: 'reset_to_video_or_document', customer_course: customer_course_id}, on_done)
    }
    
}

export const customerCourseActions = new CustomerCourseActions("customer_course_actions__default")
