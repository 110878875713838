/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import ReactPortal from './ReactPortal'
import close_icon from '../images/close_icon.png'
import { Button } from 'react-bootstrap'

class Modal extends Component {
    render() {
        const {
            onCloseModal,
            onActionModal,
            actionLabel,
            children,
            title,
            title_icon,
            hide_secondary_button,
            modal_size='normal',
            disable_action,
        } = this.props
        let extra_container = modal_size === 'large' ? large_container : null
        let extra_main = null
        if (extra_container === null ) {
            extra_container = modal_size === 'small' ? small_container : null
        }
        if (extra_container === null) {
            extra_container = modal_size === 'original' ? original_container : null
            extra_main = modal_size === 'original' ? flex_main : null
        }
        return (
            <ReactPortal>
              <div css={ [main, extra_main] }>
                <div css={ [container, extra_container] }>
                  <div css={ header }>
                    { title_icon &&
                      <img css={ icon } alt="" src={ title_icon } />
                    }
                    <h2 css={ title_container }>{ title }</h2>
                    
                    { onCloseModal ?
                      <img css={ close_btn } onClick={ onCloseModal } alt='close' src={ close_icon } />
                      :
                      <div css={ close_btn }></div>
                    }
                  </div>
                  <div css={ modal_content }>
                    { children}
                    <div css={ hide_secondary_button ? [modal_actions, buttons_center] : [modal_actions] }>
                      {
                          onCloseModal && !hide_secondary_button &&
                          <Button variant="secondary" onClick={ onCloseModal }>
                            Cancel
                          </Button>
                      }
                      { onActionModal &&
                        <Button onClick={ onActionModal } disabled={ disable_action }>
                          { actionLabel }
                        </Button>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </ReactPortal>
        )
    }
}
export default Modal

const main = css`
position: fixed;
z-index: 1;
top: 0;
left: 0;
height: 100%;
width: 100%;
overflow: auto;
background-color: rgba(0,0,0,0.4);
`

const container = css`
position: relative;
display: flex;
flex: 1;
align-items: center;
flex-direction: column;
justify-content: center;
margin: 5% auto;
width: 500px;
border: 1px solid #888;
border-radius: 5px;
background-color: #FFFFFF;
`

const large_container = css`
width: 800px;
`

const small_container = css`
width: 300px;
`

const original_container = css`
flex: none;
width: auto;
margin: auto;
`

const flex_main = css`
display: inline-flex;
`

const header = css`
padding: 0;
width: 100%;
margin: 0;
margin-top: 5px;
border-bottom: 1px solid #E6E6E6;
display: flex;
justify-content: space-between;
align-items: center;
`

const title_container = css`
font-weight: 500;
font-size: 18px;
padding: 0;
margin: 0;
padding-left: 10px;
`

const icon = css`
max-width: 30px;
max-height: 30px;
margin-left: 10px;
`

const close_btn = css`
margin-right: 10px;
cursor: pointer;
width: 30px;
height: 30px;
`

const modal_content = css`
width: 100%;
`

const modal_actions = css`
display: flex;
border-top: 1px solid #E6E6E6;
justify-content: space-between;
padding: 10px;
`

const buttons_center = css`
justify-content: center;
`
