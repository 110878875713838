/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'

class DashboardItem extends Component {

    render() {
        const { background_colour, icon1, icon2, stat1, stat2, text1, text2, alert_icon, alert } = this.props
        return (
            <div css={ [container, css`background:${background_colour};`] }>
              <div css={ [item_container, css`margin-bottom:20px;`] }>
                <div css={ icon_container }>
                  <img src={ icon1 } alt="" css={ icon_css } />
                </div>
                <div css={ children_container }>
                  <span css={ stat_container }>
                    { stat1 || 0 }
                  </span>
                  <span css={ text_container }>
                    { text1 }
                  </span>
                </div>
              </div>
              <div css={ [item_container, alert ? css`background:red;margin-top:20px;` : css`margin-top:20px;`] }>
                <div css={ icon_container }>
                  <img src={ alert ? alert_icon : icon2 } alt="" css={ icon_css } />
                </div>
                <div css={ children_container }>
                  <span css={ stat_container }>
                    { stat2 || 0 }
                  </span>
                  <span css={ text_container }>
                    { text2 }
                  </span>
                </div>
              </div>
            </div>            
        )
    }
}
function mapStateToProps(state, props) {
const { background_colour, icon1, icon2, stat1, stat2, text1, text2, alert_icon, alert } = props
    return {
        background_colour,
        icon1,
        icon2,
        stat1,
        stat2,
        text1,
        text2,
        alert_icon,
        alert,
    }
}
export default connect(mapStateToProps)(DashboardItem)

const container = css`
display:flex;
flex-direction: column;
align-items: center;
width: 300px;
border-radius: 10px;
min-height: 144px;
align-items: center;
justify-content: center;
margin: 20px;
`

const item_container = css`
display:flex;
flex-direction: row;
align-items: center;
width: 100%;
min-height: 144px;
justify-content: center;
border-radius: 10px;
padding: 10px;
`

const icon_container = css`
display:flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
width: 30%;
`

const children_container = css`
display:flex;
flex-direction: column;
align-items: flex-start;
justify-content: center;
padding: 10px;
width: 70%;
`

const icon_css = css`
max-height: 100%;
max-width: 100%;
`

const text_container = css`
font-size: 16px;
`

const stat_container = css`
vertical-align: middle;
font-size: 24px;
letter-spacing: 1.5;
font-weight: 600;
`
