/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has } from 'lodash'
import ButtonBar from '../../components/ButtonBar'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import Timestamp from '../../components/Timestamp'
import DefaultButton from '../../components/DefaultButton'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import TableFilter from '../../components/TableFilter'
import { Button } from 'react-bootstrap'
import { adminCourseList } from '../actions/admin_course'
import Card from '../../components/layout/Card'

class AdminCourses extends Component {
 
    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminCourseList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(adminCourseList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history } = this.props
        history.push('/admin/course/' + item.id)
    }

    onAdd = () => {
        const { history } = this.props
        history.push('/admin/course/')
    }

    filterCourse = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminCourseList.updateListFilter(filter_values))
    }

    sortOnHeader = (key) => {
        const { dispatch, course_filter } = this.props
        if (key !== 'action' && key !== 'admin_email') {
            let sort_by = key
            if (has(course_filter, 'order_by') && course_filter['order_by'] === key) {
                sort_by = '-' + key
            }
            dispatch(adminCourseList.updateListFilter({'order_by': sort_by}))
        }
    }
    
    getCourseCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'created_at':
                return (
                    <Timestamp value={item.created_at} format='datetime' />
                )
            case 'action':
                return (
                    <ButtonBar>
                      <Button onClick={(evt) => this.onEdit(evt, item)}>
                        Edit
                      </Button>
                    </ButtonBar>
                )
            default:
                return undefined
        }
    }

    render() {
        const {
            course_headers,
            courses,
            is_loading,
            course_filter,
        } = this.props
        
        return (
            <AdminMainLayout title="Course Template List">
              <Card>
                <TableHeader>
                  <div css={ css`width:100%;`}>
                    <TableFilter
                        updateOnChange={ this.filterCourse }
                        form="ADMIN_COURSE_TABLE_FILTER_FORM"
                    />
                  </div>
                  <div css={ header }>
                    <DefaultButton
                        label="Add Course Template"
                        type="button"
                        light={ true }
                        onClickButton={ this.onAdd }
                    />
                  </div>
                </TableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message="There are no course templates."
                    headers={ course_headers }
                    items={ courses }
                    getCellValue={ this.getCourseCellValue }
                    sortOnHeader={ this.sortOnHeader }
                    item_list={adminCourseList}
                />
                <TableFooter>
                </TableFooter>
              </Card>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const courses = adminCourseList.getVisibleObjects() || []
    return {
        courses,
        course_filter: adminCourseList.getFilter(),
        course_headers: {
            created_at: { name: 'Date Created', column_size: 1 },
            name: { name: 'Name', column_size: 1 },
            description: { name: 'Description', column_size: 1 },
            action: { name: 'Action', column_size: 1 }
        },
        is_loading: adminCourseList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(AdminCourses))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
