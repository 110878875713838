import { ItemList } from '../../orm'
import { has, union, map } from 'lodash'
import { http } from '../../lib'

class AdminCertificateConfigList extends ItemList {
    getEntityKey() {
        return 'admin/certificate_config'
    }

}

export const adminCertificateConfigList = new AdminCertificateConfigList('admin_certificate_config__default')
