/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, map, size } from 'lodash'
import Timestamp from '../../components/Timestamp'
import { adminCourseList } from '../actions/admin_course'
import { adminCourseSectionList } from '../actions/admin_course_section'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminCourseForm from './AdminCourseForm'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {NotificationManager} from 'react-notifications'
import Card from '../../components/layout/Card'
import { Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Separator } from '../../components/layout/Separator'

class AdminCourse extends Component {

    componentDidMount() {
        const { dispatch, course_id } = this.props
        dispatch(adminCourseList.ensureObjectLoaded(course_id))
        dispatch(adminCourseSectionList.updateListFilter({course:course_id}))
        dispatch(adminCourseSectionList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch, course_id } = this.props
        if ( course_id != prev_props.course_id ) {
            dispatch(adminCourseSectionList.updateListFilter({course:course_id}))
        }
        dispatch(adminCourseList.ensureObjectLoaded(course_id))
        dispatch(adminCourseSectionList.fetchListIfNeeded())
    }

    onSubmit = (values) => {
        const { dispatch, course_id, history } = this.props
        const that = this
        values.active = values.status ? values.status.value : true
        const on_done = function(json) {
            dispatch(adminCourseList.invalidateList())
            NotificationManager.success("Saved", "Course saved")
            if ( ! course_id ) {
                history.push(`/admin/course/${json.id}`)
            }
        }
        if ( course_id ) {
            values.id = course_id
            return dispatch(adminCourseList.saveObject(values, on_done))
        } else {
            return dispatch(adminCourseList.saveNewObject(values, on_done))
        }
    }

    onCancel = () => {
        const { history } = this.props
        history.push('/admin/courses')
    }

    onEditSection = (section) => {
        const { history } = this.props
        history.push(`/admin/course_section/${section.id}`)
    }

    onDeleteSection = (section) => {
        const { history, dispatch, course_id } = this.props
        if ( ! window.confirm("Are you sure?") ) {
            return
        }
        return dispatch(adminCourseSectionList.deleteObject(section.id)).then( () => {
            NotificationManager.success("Deleted", "Course section deleted")
            dispatch(adminCourseSectionList.invalidateList())
            dispatch(adminCourseList.invalidateObject(course_id))
        })
    }

    onAddSection = () => {
        const { dispatch, course_id, course } = this.props
        dispatch(adminCourseSectionList.saveNewObject({course: course_id,
                                                       section_type: 'assessment',
                                                       name: `New Course Section ${size(course.section_infos)+1}`})).then( () => {
            dispatch(adminCourseSectionList.invalidateList())
            dispatch(adminCourseList.invalidateObject(course_id))
        })
    }
    
    renderSections = () => {
        const { course } = this.props
        const that = this
        return (
            <Card title="Sections">
              {map(course.section_infos, function(section, index) {
                  return (
                      <Row key={index}>
                        <Col md="3">
                          {section.name}
                        </Col>
                        <Col md="1">
                          <Button onClick={() => that.onEditSection(section)} variant="outline-secondary">
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                        </Col>
                        <Col md="1">
                          <Button onClick={() => that.onDeleteSection(section)} variant="outline-secondary">
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        </Col>
                      </Row>
                  )
              }
                  )}
              <Button onClick={that.onAddSection} variant="outline-secondary">
                <div css={button_style}>
                  <FontAwesomeIcon icon={faPlus} />
                  <Separator variant="w10" />
                  Add section
                </div>
              </Button>
            </Card>
        )
    }

    render() {
        const { course_id, is_busy, is_loading, course, page, breadcrumbs } = this.props
        const title = course_id ? 'Edit Course Template' : 'Add Course Template'
        return (
            <AdminMainLayout title={title} breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              { (!course_id || course.id) && 
                <AdminCourseForm
                    onSubmit={ this.onSubmit }
                    onCancel={ this.onCancel }
                    course_id={ course_id }
                />
              }
              <Separator variant="h10" />
              { course && course.id && this.renderSections() }
              { course && 
                <div>Last modified at <Timestamp value={course.modified_at} /></div>
              }
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const { page } = props
    const course_id = get(props, ["match", "params", "course_id"], null)
    const course = course_id && adminCourseList.getObject(course_id)
    const course_sections = adminCourseSectionList.getVisibleObjects()
    const breadcrumbs = [ {label: course && course.name,
                           url: course &&`/admin/course/${course_id}`}]
    return {
        course_id,
        course,
        course_sections,
        page,
        breadcrumbs,
        is_loading: adminCourseList.isLoading(),
        is_busy: adminCourseList.getIsSavingObject()
    }
}
export default connect(mapStateToProps)(AdminCourse)

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const button_style = css`
display: flex;
`
