import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'

class CoursePackageList extends ItemList {
    getEntityKey() {
        return "course_package"
    }

}

export const coursePackageList = new CoursePackageList("course_package_available_incomplete_courses__default")
export const coursePackageAvailableIncompleteCoursesList = new CoursePackageList("course_package_available_incomplete_courses__default")
export const coursePackageAvailableCompleteCoursesList = new CoursePackageList("course_package_available_complete_courses__default")
export const coursePackageUnavailableCoursesList = new CoursePackageList("course_package_unavailabl__default")
