/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get, map, size } from 'lodash'
import { imageList } from '../../actions/image'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../../emotion/theme'
import { Container, Col, Row } from 'react-bootstrap'
import MainLayout from '../MainLayout'
import Loading from '../Loading'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle, faFileAlt, faClock, faEye, faCheck } from '@fortawesome/free-solid-svg-icons'
import Pluralize from 'react-pluralize'
import { Separator } from '../layout/Separator'
import { clientManager } from '../../actions/client_manager'
import { customerCourseForPackageList } from '../../actions/customer_course'
import { CourseCompletionProgressBar } from './CourseCompletionProgressBar'
import { coursePackageList } from '../../actions/course_package'
import clock_course_img from '../../images/clock_course.png'
import video_course_img from '../../images/video_course.png'
import document_course_img from '../../images/case-study_course-outline.png'
import assessment_course_img from '../../images/assessment_course-outline.png'
import ReplacementText from "../ReplacementText"

class CourseLayout extends Component {

    componentDidMount() {
        const { dispatch, course_package_id } = this.props
        if ( course_package_id ) {
            dispatch(coursePackageList.ensureObjectLoaded(course_package_id))
            dispatch(customerCourseForPackageList.updateListFilter({course_package: course_package_id}))
            dispatch(customerCourseForPackageList.fetchListIfNeeded())
        }
    }

    componentDidUpdate(prev_props) {
        const { dispatch, course_package_id, customer_course_for_package_list_filter } = this.props
        if ( course_package_id && customer_course_for_package_list_filter.course_package != course_package_id ) {
            dispatch(customerCourseForPackageList.updateListFilter({course_package: course_package_id}))
            dispatch(customerCourseForPackageList.fetchListIfNeeded())
        }
    }

    onGotoCourseSection(customer_section, course_section) {
        const { gotoCourseSection } = this.props
        const is_complete = get(customer_section, "is_complete", false)
        if ( is_complete ) {
            gotoCourseSection(course_section)
        }
    }
    
    renderTitle() {
        const { course, customer_course, course_package } = this.props

        const info_icon_style = css`color: ${clientManager.getPrimaryColour()}};`

        return (
            <div css={title_container}>
              <div css={header}>
                { course_package.name }
              </div>
              <div css={progress_bar_style}>
                <CourseCompletionProgressBar customer_course={customer_course} />
              </div>

              <Separator variant="h20" />
              
              <div css={info_text}>
                
                <div css={icon_container_style}>
                  <img css={icon_style} src={video_course_img} />
                </div>
                <ReplacementText
                    placeholder="video module"
                    text={ course_package && course_package.video_replacement_text }
                    value={ course.num_videos }
                />

                <div css={vertical_separator}>
            &nbsp;
                </div>
                
                <div css={icon_container_style}>
                  <img css={icon_style} src={assessment_course_img} />
                </div>
                <ReplacementText
                    placeholder="assessment"
                    text={ course_package && course_package.assessment_replacement_text }
                    value={ course.num_assessments }
                />
                <div css={vertical_separator}>
            &nbsp;
                </div>
                
                <div css={icon_container_style}>
                  <img css={icon_style} src={clock_course_img} />
                </div>
                <Pluralize singular="minute" count={course.duration_minutes} />
              </div>
            </div>
        )
    }

    renderOutline(active_course_section) {
        const { course, customer_course } = this.props
        const that = this
        const active_section_id = get(course, ["customer_course", "next_incomplete_section"]) || get(head(course.sections), "id")
        const client_video_active_style = css`color: ${clientManager.getPrimaryColour()};`
        const client_section_header_underline = css`border-top: 2px solid ${clientManager.getPrimaryColour()};`
        return (
            <div css={outline_container}>
              <div css={section_header}>
                Course Outline
                <div css={[section_header_underline, client_section_header_underline]}>&nbsp;</div>

                { map(course.sections, function(section, index) {

                    const video = section.section_type === 'video' && section.video
                    const is_active = section.id === get(active_course_section, "id")
                    const customer_section = customerCourseForPackageList.getCustomerSection(customer_course, section)
                    const is_complete = get(customer_section, "is_complete", false)

                    let icon_src = assessment_course_img
                    switch ( section.section_type ) {
                        case "assessment": icon_src = assessment_course_img; break;
                        case "video": icon_src = video_course_img; break;
                        case "document": icon_src = document_course_img; break;
                    }
                    
                    return (

                        <div key={index} css={[video_row, is_complete ? complete_section_style : null]} onClick={() => that.onGotoCourseSection(customer_section, section)}>

                          <div css={video_row_title}>
                            <div css={[video_sequence_nr, is_active ? client_video_active_style : null]}>

                              <div css={active_video_info_icon}>
                                <img src={icon_src} css={[active_video_info_icon_img_style]} />
                              </div>
                              { get(customer_section, "passed", false) &&
                                <div>
                                  <FontAwesomeIcon css={[active_video_info_icon]} icon={faCheck} />
                                </div>
                              }
                            </div>

                            <div css={is_active ? client_video_active_style : null}>
                              {section.name}
                            </div>
                          </div>
                          
                          { video && <span>({video.duration_string})</span> }
                        </div>
                    )
                  })
                }
                
              </div>
            </div>
        )
    }    

    renderAboutThisCourse() {
        const { course } = this.props
        const client_section_header_underline = css`border-top: 2px solid ${clientManager.getPrimaryColour()};`
        return (
            <div css={about_container}>
              <div css={section_header}>
                About this course
                <div css={[section_header_underline, client_section_header_underline]}>&nbsp;</div>
              </div>
              { course.about_text}
            </div>
        )
    }
    
    render() {
        const { course, is_loading, children, enable_border, active_course_section, space_top, show_title } = this.props
        return (
            <MainLayout>
              <Container  css={[container, (space_top === true && space_top_style) || null]}>
                { is_loading && <Loading /> }

                { course &&
                  <div>

                      <div css={[center_container_style, (space_top === true && center_container_space_top_style) || null]}>
                        <div css={[child_container, (enable_border && child_container_border) || child_container_nonborder]}>
                          { children }
                        </div>
                        { this.renderTitle() }
                      </div>

                    <Row>
                      <Col xs="12" sm="6" md="6">
                        { this.renderOutline(active_course_section) }
                      </Col>
                      <Col>
                        { this.renderAboutThisCourse() }
                      </Col>
                    </Row>
                  </div>
                }
                
              </Container>
              
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const { course, is_loading, active_course_section, course_package_id } = props
    const customer_course = head(customerCourseForPackageList.getVisibleObjects())
    const course_package = coursePackageList.getObject(course_package_id)
    
    return {
        course,
        is_loading,
        active_course_section,
        course_package_id: course_package_id,
        course_package,
        customer_course,
        customer_course_for_package_list_filter: customerCourseForPackageList.getFilter()
    }
}

export default connect(mapStateToProps)(CourseLayout)

const container = css`
margin-bottom: 50px;
`

const title_container = css`
color: #000;
padding-left: 16px;
/*border-bottom: 1px solid #8080803d;*/

-webkit-box-shadow: 2px 3px 3px 0px #EDEDED;
-moz-box-shadow: 2px 3px 4px 0px #EDEDED;
box-shadow: 2px 3px 4px 0px #EDEDED;
margin-right: 3px;

padding-bottom: 20px;
padding-top: 20px;
margin-bottom: 15px;
`

const course_banner_container = css`
max-width: 1140px;
/*height: 640px;*/
/*width: 100%;*/
background-repeat: no-repeat;
background-size: cover;

display: flex;
justify-content: center;
align-items: center;
`

const info_icon = css`
margin-right: 5px;
`

const light_info_icon = css`
color: #fff;
margin-right: 5px;
`

const header = css`
font-size: 30px;
color: rgb(80,80,80);
font-family: Lato;
margin-bottom: 10px;
font-weight: 700;
line-height: 1.1;
`

const info_text = css`
font-size: 14px;
color: rgb(51,51,51);
display: flex;
`

const outline_container = css`
margin-top: 40px;
`

const about_container = css`
color: rgb(184,184,184);
font-size: 16px;
padding-bottom: 20px;
margin-top: 40px;
`

const section_header = css`
margin-top: 15px;
font-size: 30px;
font-weight: 600;
color: rgb(80,80,80);
`

const section_header_underline = css`
margin-top: 0px;
margin-bottom: 12px;
height: 0px;
width: 100px;
`

const video_row = css`
font-weight: 600;
font-size: 14px;
padding-bottom: 20px;
padding-left: 8px;
padding-right: 8px;
display: flex;
justify-content: space-between;
line-height: 20px;
color: rgb(157,157,157);
`

const video_row_title = css`
display: flex;
`

const video_sequence_nr = css`
margin-right: 5px;
display: flex;
`

const active_video_info_icon = css`
margin-right: 3px;
min-width: 15px;
margin-left: 3px;
`

const active_video_info_icon_img_style = css`
width: 15px;
`

const child_container = css`
width: 100%;
`

const child_container_border = css`
border: 1px solid #808080;
`

const child_container_nonborder = css`
/*height: 640px;*/
max-width: 1140px;
`

const center_container_style = css`
/*border-left: 1px solid #8080803d;*/
/*border-right: 1px solid #8080803d;*/
`

const space_top_style = css`
margin-top: 40px;
`

const center_container_space_top_style = css`
/*border-top: 1px solid #8080803d;*/
`

const video_sub_row = css`
  margin-left: 15px;
`

const complete_section_style = css`
cursor: pointer;
`

const progress_bar_style = css`
width: 165px;
color: #505050;
`

const icon_container_style = css`
margin-right: 5px;
`


const icon_style = css`
height: 15px;
`

const vertical_separator = css`
width: 2px;
background-color: #E3E3E3;
margin-left: 12px;
margin-right: 12px;
height: 15px;
margin-top: 7px;
`
