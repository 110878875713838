/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has, map } from 'lodash'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import DefaultButton from '../../components/DefaultButton'
import TableFilter from '../../components/TableFilter'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import FileUploader from '../../components/form/FileUploader'
import Modal from '../../components/Modal'
import { Button, Row, Col } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { adminUserCourseReportList } from '../actions/admin_user_course_report'
import { faCheckCircle, faTimes } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { default_theme as theme } from '../../emotion/theme'

class AdminUserCourseReport extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminUserCourseReportList.fetchListIfNeeded())
    }

    componentDidUpdate(old_props) {
        const { dispatch } = this.props
        dispatch(adminUserCourseReportList.fetchListIfNeeded())
    }

    onViewUser = (evt, item) => {
        const { history } = this.props
        history.push({
            pathname: '/admin/user/'+item.user
        })
    }

    onUpdateFilter = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminUserCourseReportList.updateListFilter(filter_values))
    }

    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'user_created_at':
                return <Timestamp value={item.user_created_at} format='date' />
            case 'course_info':
                return this.renderCourseInfo(item)
            case 'action':
                return (
                <Button onClick={(evt) => this.onViewUser(evt, item)}>
                  Edit
                </Button>
                )
                
            default:
                return undefined
        }
    }

    renderCourseInfoSnippet(label, value) {
        return (
            <Row>
              <Col md="6">
                {label}
              </Col>
              <Col>
                {value}
              </Col>
            </Row>
        )
    }

    renderCourseInfo = (user) => {
        const that = this
        return (
            <div>
              { map(user.courses, function(course, index) {
                    return (
                        <Row key={index}>
                          <Col>
                            <Row>
                              <Col css={course_info_heading}>
                                {course.course_name}
                              </Col>
                            </Row>
                            <Row>
                              <Col md="1"> </Col>
                              <Col md="6">
                                { course.passed && 
                                  <span>Passed <FontAwesomeIcon css={awesome_icon} icon={faCheckCircle} /> </span>
                                }
                                { !course.passed && 
                                  <span>Not complete<FontAwesomeIcon css={awesome_icon} icon={faTimes} /> </span>
                                }
                              </Col>
                            </Row>
                            <Row>
                              <Col md="1"> </Col>
                              <Col md="6">
                                { ! course.passed && <span>Last attempt at</span> }
                                { course.passed && <span>Passed at</span> }
                              </Col>
                              <Col>
                                { course.last_attempt_at && <Timestamp value={course.last_attempt_at} format="datetime" /> }
                                { !course.last_attempt_at && <span>Not started yet</span> }
                              </Col>
                            </Row>
                            { !course.passed && course.most_recent_section_name &&
                              <Row>
                                <Col md="1"> </Col>
                                <Col md="6">
                                  Last section attempted
                                </Col>
                                <Col>
                                  { course.most_recent_section_name }
                                </Col>
                              </Row>
                            }
                          </Col>
                        </Row>
                    )}
              )}
            </div>
        )
    }

    render() {
        const { is_loading, upload_relative_url, user_course_headers, user_courses, list_filter } = this.props
        
        return (
            <AdminMainLayout title="User Course Report">
              <Card>
                <TableHeader>
                  <TableFilter
                      updateOnChange={ this.onUpdateFilter }
                      form="ADMIN_USER_COURSE_FILTER_FORM"
                  />
                </TableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message="There is no user course information"
                    headers={ user_course_headers }
                    items={ user_courses }
                    getCellValue={ this.getCellValue }
                    item_list={adminUserCourseReportList}
                />
                <TableFooter>
                </TableFooter>
              </Card>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const user_courses = adminUserCourseReportList.getVisibleObjects()
    return {
        list_filter: adminUserCourseReportList.getFilter(),
        user_courses,
        user_course_headers: {
            user_display_name: { name: 'User', column_size: 2},
            course_info: { name: 'Courses', column_size: 5},
            user_created_at: { name: 'User Created', column_size: 2},
            action: { name: 'Actions', column_size: 1 }
        },
        is_loading: adminUserCourseReportList.isLoading(),
    }
}
export default withRouter(connect(mapStateToProps)(AdminUserCourseReport))

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
`

const add_container = css`
display: flex;
align-items: center;
`

const modal_container = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
padding: 10px;
text-align: center;
`

const course_info_heading = css(theme.mq({
fontSize: ["14px","12px"],
fontWeight: 600
}))

const awesome_icon = css`
margin-left: 5px;
margin-right: 5px;

`
