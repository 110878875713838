/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { jsx, css } from '@emotion/core'
import { map, get, size, filter, keys } from 'lodash'
import { adminClientList } from '../actions/admin_client'
import { adminClientGroupList } from '../actions/admin_client_group'
import InputField from '../../components/form/InputField'
import DefaultButton from '../../components/DefaultButton'
import { FormikDropdownField } from '../../components/Dropdown'
import Timestamp from '../../components/Timestamp'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import AdminCreationFooter from './AdminCreationFooter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikTextarea } from '../../components/form/TextareaField'
import { default_theme as theme } from '../../emotion/theme'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Col, Row, Container, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { Separator } from '../../components/layout/Separator'
import {NotificationManager} from 'react-notifications'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormFieldWithAction } from '../../components/form/FormFieldWithAction'
import { FormikFileUploadField } from '../../components/form/FileUploader'
import { FSRoot } from '../../components/tree'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'name': Yup.string().required("Name is required")
})

class AdminClientGroups extends Component {

    constructor(props) {
        super(props)
        this.state = { selected_node: null,
                       selected_group: null }
    }
    
    componentDidMount() {
        const { dispatch, client_id } = this.props
        dispatch(adminClientGroupList.fetchListIfNeeded())
        dispatch(adminClientList.fetchListIfNeeded())
        dispatch(adminClientList.ensureObjectLoaded(client_id))
    }

    componentDidUpdate() {
        const { dispatch, client_id } = this.props
        dispatch(adminClientGroupList.fetchListIfNeeded())
        dispatch(adminClientList.fetchListIfNeeded())
        dispatch(adminClientList.ensureObjectLoaded(client_id))
    }

    onSaveGroup = (values, setSubmitting, setFieldError) => {
        const { dispatch, client_id, history } = this.props
        const that = this
        const on_done = function(json) {
            setSubmitting(false)
            if ( json.errors ) {
                map(keys(json.errors), (field_name) => setFieldError(field_name, json.errors[field_name]))
            }
            dispatch(adminClientList.invalidateObject(client_id))
            that.setState( {selected_group: null })
            NotificationManager.success("Saved", "Group saved")
        }
        values.client = client_id
        if ( values.id ) {
            return dispatch(adminClientGroupList.saveObject(values, on_done))
        } else {
            return dispatch(adminClientGroupList.saveNewObject(values, on_done))
        }
    }
    
    onSelectedGroup = (node) => {
        const { onSelect } = this.props
        const group = node.props.node
        const { selected_node } = this.state
        if ( selected_node ) {
            selected_node.deselect()
        }
        this.setState( {selected_node: node,
                        selected_group: group })
        if ( onSelect ) {
            onSelect(group)
        }
    }

    onAddChildGroup = (group) => {
        const { dispatch, client_id } = this.props
        const that = this
        const values = {parent: group.id,
                        client: client_id,
                        name: "New child group"}
        const on_done = function(json) {
            dispatch(adminClientList.invalidateObject(client_id))
            that.setState({selected_group: json})
        }
        return dispatch(adminClientGroupList.saveNewObject(values, on_done))
    }

    onDeleteChildGroup = (group) => {
        if (! window.confirm(`Are you sure you want to delete this group? ${group.name}`) ) {
            return
        }
        const { dispatch, client_id } = this.props
        const that = this
        const on_done = function(json) {
            dispatch(adminClientList.invalidateObject(client_id))
            that.setState({selected_group: null})
        }
        dispatch(adminClientGroupList.deleteObject(group.id, on_done))
    }

    renderTree() {
        const { client, tree } = this.props
        return (
            <FSRoot childNodes={tree}
                    onSelect={this.onSelectedGroup}
            />
        )
    }

    renderGroupForm() {
        const { client_id, can_edit } = this.props
        const { selected_group } = this.state
        if ( ! selected_group ) {
            return null
        }
        return (
            <div>
              <Formik
                initialValues={selected_group}
                onSubmit={(values, { setSubmitting, setFieldError }) => {this.onSaveGroup(values, setSubmitting, setFieldError)}}
                enableReinitialize={true}
                validationSchema={validationSchema}
              >
                {formik_props => {
                    const { values } = formik_props
                    return (
                        <Form>
                          <Row>
                            <Col>
                              <h2>Group details</h2>
                              <Row>
                                <Col>
                                  <FormLabelValue>
                                    Name
                                    <FormikInputField
                                      name="name"
                                      type="text"
                                      placeholder="Group name"
                                    />
                                  </FormLabelValue>
                                  
                                </Col>
                              </Row>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <FormLabelValue>
                                Members can complete courses
                                <FormikCheckboxField
                                  name="can_complete_courses"
                                  label=""
                                />
                              </FormLabelValue>
                            </Col>
                          </Row>

                          <Row>
                            <Col>
                              <FormLabelValue>
                                Members can view aggregated reports
                                <FormikCheckboxField
                                  name="can_view_aggregated_reports"
                                  label=""
                                />
                              </FormLabelValue>
                            </Col>
                          </Row>
                          
                          <Row>
                            <Col>
                              <Button type="submit">
                                Save
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                    )}
                }
              </Formik>
              { can_edit && 
                <Row>
                  <Col>
                    <Separator variant="h15" />
                    <Button variant="secondary" onClick={() => this.onAddChildGroup(selected_group)}>
                      Add child group
                    </Button>
                    { selected_group.parent !== null && 
                      <Button variant="warning" onClick={() => this.onDeleteChildGroup(selected_group)}>
                        Delete
                      </Button>
                    }
                    <div>
                      <Link to={`/admin/client/${client_id}/group/${selected_group.id}/users`}>
                        Manage users for this group (currently {selected_group.num_users})
                      </Link>
                    </div>
                  </Col>
                </Row>
              }
            </div>
        )
    }

    render() {
        const { title, can_edit } = this.props
        return (
            <Card title={title}>
              <Container fluid>
                <Row>
                  <Col md="6">
                    { this.renderTree() }
                  </Col>
                  { can_edit && 
                    <Col>
                      { this.renderGroupForm() }
                    </Col>
                  }
                </Row>
              </Container>
            </Card>
        )
    }
}

function mapStateToProps(state, props) {
    const { client_id, can_edit, onSelect, title } = props
    const client = adminClientList.getObject(client_id) || {}
    const tree = adminClientList.getGroupsAsRecursiveTree(client)
    return {
        client,
        tree,
        can_edit: can_edit !== false,
        onSelect,
        title: title || "Groups"
    }
}
export default withRouter(connect(mapStateToProps)(AdminClientGroups))

