/** @jsx jsx */
import { Component } from 'react'
import { Separator } from '../layout/Separator'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../../emotion/theme'

export default class SummaryAssesmentSummaryMessage extends Component {
    
    render() {
        const { title, msg, children } = this.props
        return (
            <div css={section_summary__info_container}>
              <div css={section_summary__info}>
                <h2>
                  {title}
                </h2>
                <br/>
                {msg}
              </div>

              <Separator variant="h30" />

              {children}

              <Separator variant="h80" />
              
            </div>
        )
    }
}

const section_summary__info_container = css(theme.mq({
    paddingLeft: ["100px", "0px"],
    paddingRight: ["100px", "0px"]
}))

const section_summary__info = css(theme.mq({
    fontSize: ["22px", "18px"],
    lineHeight: "1.5",
    textAlign: "center"
}))
