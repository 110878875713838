/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, map, size } from 'lodash'
import { Link } from 'react-router-dom'
import Timestamp from '../../components/Timestamp'
import { adminCoursePackageList } from '../actions/admin_course_package'
import { adminClientList } from '../actions/admin_client'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminCoursePackageForm from './AdminCoursePackageForm'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {NotificationManager} from 'react-notifications'
import Card from '../../components/layout/Card'
import { Container, Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'

class AdminCoursePackage extends Component {

    componentDidMount() {
        const { dispatch, course_package_id, client_id } = this.props
        dispatch(adminCoursePackageList.ensureObjectLoaded(course_package_id))
        dispatch(adminClientList.ensureObjectLoaded(client_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, client_id, course_package_id } = this.props
        dispatch(adminCoursePackageList.ensureObjectLoaded(course_package_id))
        dispatch(adminClientList.ensureObjectLoaded(client_id))
    }

    onSubmit = (values) => {
        const { dispatch, course_package_id, client_id, history } = this.props
        const that = this
        values.active = values.status ? values.status.value : true
        const on_done = function(json) {
            dispatch(adminCoursePackageList.invalidateList())
            dispatch(adminClientList.invalidateObject(client_id))
            NotificationManager.success("Saved", "Course saved")
            if ( ! course_package_id ) {
                history.push(`/admin/client/${client_id}/course_package/${json.id}`)
            }
        }
        values.client = client_id
        if ( course_package_id ) {
            values.id = course_package_id
            return dispatch(adminCoursePackageList.saveObject(values, on_done))
        } else {
            return dispatch(adminCoursePackageList.saveNewObject(values, on_done))
        }
    }

    onCancel = () => {
        const { history } = this.props
        history.push('/admin/client/${client_id}/course_packages')
    }
    
    render() {
        const { course_package_id, client_id, is_busy, is_loading, course_package, page, breadcrumbs } = this.props
        const title = course_package_id ? 'Edit Course' : 'Add Course'
        return (
            <AdminMainLayout title={title} breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              { (!course_package_id || course_package.id) &&
                <Row>
                  <Col>
                    <AdminCoursePackageForm
                      onSubmit={ this.onSubmit }
                      onCancel={ this.onCancel }
                      client_id={client_id}
                      course_package_id={course_package_id}
                    />
                  </Col>
                </Row>
              }
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const { page } = props
    const client_id = get(props, ["match", "params", "client_id"], null)
    const client = adminClientList.getObject(client_id)
    const course_package_id = get(props, ["match", "params", "course_package_id"], null)
    const course_package = course_package_id && adminCoursePackageList.getObject(course_package_id)
    const breadcrumbs = [ {label: "Clients",
                           url: "/admin/clients"},
                          {label: client && client.name,
                           url: `/admin/client/${client_id}`},
                          {label: "Course Packages",
                           url: `/admin/client/${client_id}/course_packages`},
                          {label: course_package && course_package.name,
                           url: `/admin/course_package/${course_package_id}`}]
    return {
        client_id,
        client,
        course_package_id,
        course_package,
        page,
        breadcrumbs,
        is_loading: adminCoursePackageList.isLoading(),
        is_busy: adminCoursePackageList.getIsSavingObject()
    }
}
export default connect(mapStateToProps)(AdminCoursePackage)

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`
