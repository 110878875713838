/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { has, get } from 'lodash'
import ButtonBar from '../../components/ButtonBar'
import AdminMainLayout from './AdminMainLayout'
import CommonTable from '../../components/CommonTable'
import Pagination from '../../components/Pagination'
import Timestamp from '../../components/Timestamp'
import DefaultButton from '../../components/DefaultButton'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import TableFilter from '../../components/TableFilter'
import { Button } from 'react-bootstrap'
import { adminCoursePackageList } from '../actions/admin_course_package'
import { adminClientList } from '../actions/admin_client'
import Card from '../../components/layout/Card'

class AdminCoursePackages extends Component {
 
    componentDidMount() {
        const { dispatch, client_id } = this.props
        dispatch(adminCoursePackageList.updateListFilter({client:client_id}))
        dispatch(adminCoursePackageList.fetchListIfNeeded())
        dispatch(adminClientList.ensureObjectLoaded(client_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, client_id } = this.props
        if ( prev_props.client_id != client_id ) {
            dispatch(adminCoursePackageList.updateListFilter({client:client_id}))
        }
        dispatch(adminCoursePackageList.fetchListIfNeeded())
    }

    onEdit = (evt, item) => {
        const { history, client_id } = this.props
        history.push(`/admin/client/${client_id}/course_package/` + item.id)
    }

    onAdd = () => {
        const { history, client_id } = this.props
        history.push(`/admin/client/${client_id}/course_package`)
    }

    filterCoursePackage = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminCoursePackageList.updateListFilter(filter_values))
    }

  getCoursePackageCellValue = (header_key, item, index) => {
    switch( header_key ) {
      case 'created_at':
        return (
          <Timestamp value={item.created_at} format='datetime' />
        )
      case 'enabled':
        return (
          <input
            type="checkbox"
            checked={ item.enabled }
            className="checkbox"
            css={ css`height:1.4rem;` }            
            readonly={ true }
          />
        )
      case 'action':
        return (
          <ButtonBar>
            <Button onClick={(evt) => this.onEdit(evt, item)}>
              Edit
            </Button>
          </ButtonBar>
        )
      default:
        return undefined
    }
  }

    render() {
        const {
            course_package_headers,
            course_packages,
            is_loading,
            course_package_filter,
            breadcrumbs
        } = this.props
        
        return (
            <AdminMainLayout title="Course List" breadcrumbs={breadcrumbs}>
              <Card>
                <TableHeader>
                  <div css={ css`width:100%;`}>
                    <TableFilter
                        updateOnChange={ this.filterCoursePackage }
                        form="ADMIN_COURSE_PACKAGE_TABLE_FILTER_FORM"
                    />
                  </div>
                  <div css={ header }>
                    <DefaultButton
                        label="Add Course"
                        type="button"
                        light={ true }
                        onClickButton={ this.onAdd }
                    />
                  </div>
                </TableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message="There are no courses."
                    headers={ course_package_headers }
                    items={ course_packages }
                    getCellValue={ this.getCoursePackageCellValue }
                    item_list={adminCoursePackageList}
                />
                <TableFooter>
                </TableFooter>
              </Card>
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const client_id = get(props, ["match", "params", "client_id"], null)
    const client = adminClientList.getObject(client_id)
    const course_packages = adminCoursePackageList.getVisibleObjects() || []
    const breadcrumbs = [ {label: "Clients",
                           url: "/admin/clients"},
                          {label: client && client.name,
                           url: client &&`/admin/client/${client_id}`},
                          {label: "Courses",
                           url: `/admin/client/${client_id}/course_packages`},
                          ]
    return {
        client_id,
        client,
        course_packages,
        course_package_filter: adminCoursePackageList.getFilter(),
        course_package_headers: {
            created_at: { name: 'Date Created', column_size: 1 },
            name: { name: 'Name', column_size: 1 },
            enabled: { name: 'Enabled', column_size: 1 },
            action: { name: 'Action', column_size: 1 },
        },
        is_loading: adminCoursePackageList.isLoading(),
        breadcrumbs
    }
}
export default withRouter(connect(mapStateToProps)(AdminCoursePackages))

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`
