import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'

class CustomerCourseList extends ItemList {
    getEntityKey() {
        return "customer_course"
    }

    getNextUnpassedCourseSection(customer_course, course) {
        if ( ! customer_course.id || ! course ) {
            return null
        }
        const next_unpassed_section = customer_course.next_unpassed_section
        if ( ! next_unpassed_section ) {
            if ( ! customer_course.passed ) {
                return head(course.sections)
            } else {
                return null
            }
        }
        return head(filter(course.sections, (section) => section.id === next_unpassed_section))
    }

    getCustomerSection(customer_course, course_section) {
        if ( ! course_section ) {
            return null
        }
        return head(filter(get(customer_course, "customer_sections", []), (customer_section) => customer_section.course_section === course_section.id))
    }

    getCustomerVideo(customer_course, course_video) {
        if ( ! course_video ) {
            return null
        }
        return head(filter(get(customer_course, "customer_videos", []), (customer_video) => customer_video.video === course_video.id))
    }

    getCustomerDocument(customer_course, course_document) {
        if ( ! course_document ) {
            return null
        }
        return head(filter(get(customer_course, "customer_documents", []), (customer_document) => customer_document.document === course_document.id))
    }

    getCustomerCourse({course_id, customer_id}) {
        const customer_courses = this.getAllObjects()
        return head(filter(customer_courses, (x) => x.course.id === course_id && x.customer === customer_id))
    }
    
    
}

export const customerCourseList = new CustomerCourseList("customer_course__default")
export const customerCourseForPackageList = new CustomerCourseList("customer_course_for_package__default")
