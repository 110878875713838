/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get } from 'lodash'
import { imageList } from '../../actions/image'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../../emotion/theme'
import { Container } from 'react-bootstrap'
import MainLayout from '../MainLayout'
import FeaturedCourse from './FeaturedCourse'
import CourseList from './CourseList'
import { courseList } from '../../actions/course'

class Courses extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(courseList.fetchListIfNeeded())
    }
    
    render() {
        const { title, children, max_width, title_buttons, selected_course_id } = this.props
        return (
            <MainLayout>

              <FeaturedCourse selected_course_id={selected_course_id} />
              <CourseList />
              
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const { title, layout, children } = props
    const courses = courseList.getVisibleObjects() || []
    const selected_course_id = get(props, ["match", "params", "course_id"])
    
    return {
        selected_course_id,
        title,
        children
    }
}

export default connect(mapStateToProps)(Courses)


const main = css`
/*background-color: ${theme.colors.page_background};*/

`

const header = css`
display: flex;
flex-direction: row;
width: 100%;
height: 100px;
justify-content: space-between;
align-items: center;
color: ${theme.colors.secondary};
padding: 10px;
background-color: #efefef;
`

const header_title = css`
margin-top:5px;
text-align: center;
width: 100%;
font-size:16px;
font-weight: bold;
`
