/** @jsx jsx */
import { Component } from 'react';
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reduxForm, Field } from 'redux-form'
import InputField from './InputField'
import { map, get } from 'lodash'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import dale_logo from '../images/dale.png'
import background_image from '../images/main_background.jpg'
import { clientManager } from '../actions/client_manager'
import { Helmet } from 'react-helmet'
import BusyMask from './BusyMask'

class LoginLayout extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(clientManager.ensureClientIsLoaded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(clientManager.ensureClientIsLoaded())
    }
    
    render() {
        const { client, msg, sub_msg } = this.props

        if ( ! client ) {
            return null
        }

        if ( ! get(client, ["login_image", "original_download_url"] ) ) {
            return <BusyMask loading={true} />
        }
        
        const background_style = css`background-image: url(${get(client, ["login_image", "original_download_url"], background_image)})`
        const client_login_header_style = css`color: ${clientManager.getPrimaryColour()};`
        
        return (
            <div css={[main, background_style]} >
              <Helmet>
                <title>{ get(client, "name", "Jigsaw Compliance") }</title>
              </Helmet>
              <div css={ layout }>

                <div>
                  <img src={ get(client, ["logo", "original_download_url"], dale_logo) } alt="" css={ image } />
                </div>
                
                <div css={logo_separator}>&nbsp;</div>
                
                <div css={[login_header, client_login_header_style]}>
                  {msg}
                </div>

                { sub_msg && 
                  <div css={login_subheader}>
                    {sub_msg}
                  </div>
                }
                
                {this.props.children({client})}

                <div css={footer_separator}>&nbsp;</div>
              </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { onSubmit, onRequestToken, tokenError } = props
    const client = clientManager.getClient()
    return {
        client,
        onSubmit,
        onRequestToken,
        tokenError
    }
}

export default withRouter(connect(mapStateToProps)(LoginLayout))

const main = css`
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
background-color: ${theme.colors.gray2};
min-height: 100vh;
padding: 0 16px;
background: url(${background_image});
background-position: center;
background-size: cover;
`

const layout = css(theme.mq({
    width: ['399px', '100%'],
    marginLeft: ['50px', '0px'],
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    padding: '20px'
}))

const image = css`
width: 285px;
`

const login_header = css`
font-size: 18px;
font-weight: bold;
padding-bottom: 20px;
text-align: center;
`

const login_subheader = css`
font-weight: normal;
color: #000000;
text-align: center;
`

const logo_separator = css`
margin-top: 20px;
margin-bottom: 0px;
border-top: 1px solid #eee;
width: 100%;
`

const footer_separator = css`
margin-top: 0px;
margin-bottom: 20px;
border-top: 1px solid #eee;
width: 100%;
`
