/** @jsx jsx */
import { Component } from 'react'
import { jsx } from '@emotion/core'
import Select from 'react-select'
import { map } from 'lodash'

class MultiDropdown extends Component {
    
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            tabIndex,
            meta,
            ...extraProps
        } = this.props

        let options = map(input.value, (item) => {
            return { value: item, label: item }
        })
        
        return (
            <div>
              <Select
                  {...extraProps}
                  isMulti
                  isDisabled
                  value={options}
              />
            </div>
        )
    }
}
export default MultiDropdown
