/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, map, size } from 'lodash'
import Timestamp from '../../components/Timestamp'
import { adminCourseList } from '../actions/admin_course'
import { adminCourseSectionList } from '../actions/admin_course_section'
import { adminCourseSectionQuestionList } from '../actions/admin_course_section_question'
import { adminCourseSectionQuestionOptionList } from '../actions/admin_course_section_question_option'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminCourseSectionQuestionForm from './AdminCourseSectionQuestionForm'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {NotificationManager} from 'react-notifications'
import Card from '../../components/layout/Card'
import { Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt, faPlus, faCheckCircle } from '@fortawesome/free-solid-svg-icons'

class AdminCourseSectionQuestion extends Component {

    componentDidMount() {
        const { dispatch, course_section_question_id, course_section_question, course_section } = this.props
        dispatch(adminCourseSectionQuestionList.ensureObjectLoaded(course_section_question_id))
        dispatch(adminCourseSectionQuestionOptionList.updateListFilter({course_section_question:course_section_question_id}))
        dispatch(adminCourseSectionQuestionOptionList.fetchListIfNeeded())

        if ( course_section_question && course_section_question.course_section ) {
            dispatch(adminCourseSectionList.ensureObjectLoaded(course_section_question.course_section))
        }
        if ( course_section && course_section.course ) {
            dispatch(adminCourseList.ensureObjectLoaded(course_section.course))
        }
    }

    componentDidUpdate(prev_props) {
        const { dispatch, course_section_id, course_section, course_section_question_id, course_section_question } = this.props
        if ( course_section_question_id != prev_props.course_section_question_id ) {
            dispatch(adminCourseSectionQuestionOptionList.updateListFilter({course_section_question:course_section_question_id}))
        }
        if ( course_section_id != prev_props.course_section_id ) {
            dispatch(adminCourseSectionList.ensureObjectLoaded(course_section_id))
        }
        if ( course_section && course_section.course ) {
            dispatch(adminCourseList.ensureObjectLoaded(course_section.course))
        }
        dispatch(adminCourseSectionQuestionList.ensureObjectLoaded(course_section_question_id))
        dispatch(adminCourseSectionQuestionOptionList.fetchListIfNeeded())
    }

    onAddQuestionOption = () => {
        const { dispatch, course_section_question_id, course_section_question } = this.props
        dispatch(adminCourseSectionQuestionOptionList.saveNewObject({course_section_question: course_section_question_id, text: `New Answer ${size(course_section_question.options)+1}`})).then( () => {
            dispatch(adminCourseSectionQuestionOptionList.invalidateList())
            dispatch(adminCourseSectionQuestionList.invalidateObject(course_section_question_id))
            dispatch(adminCourseSectionQuestionList.ensureObjectLoaded(course_section_question_id))
        })
    }

    onDeleteQuestionOption = (question_option) => {
        const { history, dispatch, course_section_question_id } = this.props
        if ( ! window.confirm("Are you sure?") ) {
            return
        }
        return dispatch(adminCourseSectionQuestionOptionList.deleteObject(question_option.id)).then( () => {
            NotificationManager.success("Deleted", "Option deleted")
            dispatch(adminCourseSectionQuestionOptionList.invalidateList())
            dispatch(adminCourseSectionQuestionList.invalidateObject(course_section_question_id))
        })
    }

    onEditQuestionOption = (question_option) => {
        const { history } = this.props
        history.push(`/admin/course_section_question_option/${question_option.id}`)
    }

    onSubmit = (values) => {
        const { dispatch, course_section_question_id, history } = this.props
        const that = this
        values.active = values.status ? values.status.value : true
        const on_done = function(json) {
            dispatch(adminCourseSectionQuestionOptionList.invalidateList())
            NotificationManager.success("Saved", "Question saved")
            if ( ! course_section_question_id ) {
                history.push(`/admin/course_section_question/${json.id}`)
            }
        }
        if ( course_section_question_id ) {
            values.id = course_section_question_id
            return dispatch(adminCourseSectionQuestionList.saveObject(values, on_done))
        } else {
            return dispatch(adminCourseSectionQuestionList.saveNewObject(values, on_done))
        }
    }

    renderQuestionOptions = () => {
        const { course_section_question } = this.props
        const that = this
        return (
            <Card title="Options">
              {map(course_section_question.options, function(question_option, index) {
                  return (
                      <Row key={index}>
                        <Col md="6">
                          { question_option.is_right && <FontAwesomeIcon icon={faCheckCircle} /> }
                          {question_option.text}
                        </Col>
                        <Col md="1">
                          <Button onClick={() => that.onEditQuestionOption(question_option)} variant="outline-secondary">
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                        </Col>
                        <Col md="1">
                          <Button onClick={() => that.onDeleteQuestionOption(question_option)} variant="outline-secondary">
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        </Col>
                      </Row>
                  )
               }
              )}
              <Button onClick={that.onAddQuestionOption} variant="outline-secondary">
                <FontAwesomeIcon icon={faPlus} />
                Add option
              </Button>
            </Card>
        )
    }

    render() {
        const { course_section_question_id, course_section_question,
                course_section_id, course_section,
                is_busy, is_loading, page, breadcrumbs } = this.props
        const title = course_section_question_id ? 'Edit Question' : 'Add Question'

        return (
            <AdminMainLayout title={title} breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              <Row>
                <Col>
                  <AdminCourseSectionQuestionForm
                      onSubmit={ this.onSubmit }
                      course_section_question_id={ course_section_question_id }
                  />
                </Col>
                <Col>
                  { course_section_question && course_section_question.id && this.renderQuestionOptions() }
                </Col>
              </Row>
              { course_section_question && 
                <div>Last modified at <Timestamp value={course_section_question.modified_at} /></div>
              }
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const course_section_question_id = get(props, ["match", "params", "course_section_question_id"], null)
    const course_section_question = adminCourseSectionQuestionList.getObject(course_section_question_id)
    const course_section = course_section_question && adminCourseSectionList.getObject(course_section_question.course_section)
    const course = course_section && adminCourseList.getObject(course_section.course)
    const breadcrumbs = [ {label: course && course.name,
                           url: course &&`/admin/course/${course_section.course}`},
                          {label: course_section && course_section.name,
                           url: `/admin/course_section/${course_section && course_section.id}`},
                          {label: course_section_question && course_section_question.text,
                           url: `/admin/course_section_question/${course_section_question_id}`}]
    return {
        course_section_question_id,
        course_section_question,
        course_section_id: course_section_question && course_section_question.course_section,
        course_section,
        breadcrumbs,
        is_loading: adminCourseSectionList.isLoading(),
        is_busy: adminCourseSectionList.getIsSavingObject()
    }
}
export default connect(mapStateToProps)(AdminCourseSectionQuestion)

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`
