/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, map, size } from 'lodash'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../../emotion/theme'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CourseCard from './CourseCard'
import { Row, Col, Container } from 'react-bootstrap'
import { coursePackageUnavailableCoursesList, coursePackageAvailableCompleteCoursesList, coursePackageAvailableIncompleteCoursesList } from '../../actions/course_package'
import { customerCourseList } from '../../actions/customer_course'
import Loading from '../Loading'
import { withRouter } from 'react-router'
import { Separator } from '../layout/Separator'
import { clientManager } from '../../actions/client_manager'

class CourseList extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        
        dispatch(coursePackageAvailableIncompleteCoursesList.updateListFilter({available_for_completion:true,
                                                                               is_complete: false}))
        dispatch(coursePackageAvailableIncompleteCoursesList.fetchListIfNeeded())

        dispatch(coursePackageAvailableCompleteCoursesList.updateListFilter({available_for_completion:true,
                                                                             is_complete: true}))
        dispatch(coursePackageAvailableCompleteCoursesList.fetchListIfNeeded())
        
        dispatch(coursePackageUnavailableCoursesList.updateListFilter({available_for_completion:false}))
        dispatch(coursePackageUnavailableCoursesList.fetchListIfNeeded())
        dispatch(customerCourseList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(coursePackageAvailableIncompleteCoursesList.fetchListIfNeeded())
        dispatch(coursePackageAvailableCompleteCoursesList.fetchListIfNeeded())
        dispatch(coursePackageUnavailableCoursesList.fetchListIfNeeded())
        dispatch(customerCourseList.fetchListIfNeeded())
    }

    onSelectedCourse = (course_package_id) => {
        const { history } = this.props
        history.push(`/course/${course_package_id}`)
    }

    renderCourseCard(course_package, lg_width) {
        return (
            <Col xs="12" sm="6" md="3" lg={lg_width} css={course_col_style}>
              <CourseCard course_id={course_package.course.id}
                          course_package={course_package}
                          course_package_id={course_package.id}
                          onSelectedCourse={this.onSelectedCourse} />
            </Col>
        )
    }
    
    render() {

        const { is_loading, available_incomplete_packages, available_complete_packages, unavailable_packages } = this.props
        const that = this

        const num_course_packages = size(available_incomplete_packages) + size(available_complete_packages) || size(unavailable_packages)
        let lg_width = "4"
        if ( num_course_packages == 2 ) {
            lg_width = "6"
        } else if ( num_course_packages == 1 ) {
            lg_width = "12"
        }

        const client_header_separator_style = css`border-top: 2px solid ${clientManager.getPrimaryColour()};`
        
        return (
            <Container>
              { is_loading && <Loading /> }
              <div css={header}>
                My Courses
                <div css={[header_separator, client_header_separator_style]}>&nbsp;</div>
              </div>

              <Row xs="12" sm="12" md="12" lg="12">
                { num_course_packages == 0 &&
                  <div>
                    You have no courses.
                  </div>
                }
                { map(available_incomplete_packages, (course_package) => that.renderCourseCard(course_package, lg_width)) }
                { map(available_complete_packages, (course_package) => that.renderCourseCard(course_package, lg_width)) }
                { map(unavailable_packages, (course_package) => that.renderCourseCard(course_package, lg_width)) }
              </Row>
              <Separator variant="h30" />
            </Container>
        )
    }
}

function mapStateToProps(state, props) {

    const available_incomplete_packages = coursePackageAvailableIncompleteCoursesList.getVisibleObjects()
    const available_complete_packages = coursePackageAvailableCompleteCoursesList.getVisibleObjects()
    const unavailable_packages = coursePackageUnavailableCoursesList.getVisibleObjects()
    
    return {
        available_incomplete_packages,
        available_complete_packages,
        unavailable_packages,
        is_loading: coursePackageAvailableIncompleteCoursesList.isLoading() ||
            coursePackageAvailableCompleteCoursesList.isLoading() ||
            coursePackageUnavailableCoursesList.isLoading()
    }
}

export default withRouter(connect(mapStateToProps)(CourseList))

const header = css`
text-align: center;
font-weight: 600;
color: rgb(80,80,80);
font-size: 38px;
margin-top: 30px;
`

const header_separator = css`
width: 115px;
margin-top: 10px;
margin-bottom: 20px;
margin: auto;
`

const course_col_style = css(theme.mq({
    marginBottom: ['0px', '0px'],
    paddingLeft: ["15px", "0px"],
    paddingRight: ["15px", "0px"]
}))
