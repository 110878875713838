/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import XLSX from 'xlsx'
import moment from 'moment'
import { map, get, size, head, round } from 'lodash'
import { FormattedNumber } from '../FormattedNumber'
import { Link } from 'react-router-dom'
import MainLayout from '../MainLayout'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import { Container, Col, Row, Popover, OverlayTrigger } from 'react-bootstrap'
import { Separator } from '../layout/Separator'
import { PieChart, Pie, Legend, LineChart, Line, BarChart, Bar,
         XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts'
import { clientManager } from '../../actions/client_manager'
import CommonTable from '../CommonTable'
import TableFilter from '../TableFilter'
import TableHeader from '../TableHeader'
import { InlineIcon } from '../layout/InlineIcon'
import { GrayButton } from '../layout/GrayButton'
import WrappingBusyMask from '../WrappingBusyMask'
import { aggregatedReportList } from '../../actions/aggregated_report'
import { aggregatedReportCustomerCourseList } from '../../actions/aggregated_report_customer_course'
import { aggregatedReportCustomerCourseForExportList } from '../../actions/aggregated_report_customer_course_for_export'
import { clientGroupList } from '../../actions/client_group'
import { FSRoot } from '../tree'
import BlueButton from '../layout/BlueButton'
import Timestamp from '../Timestamp'
import { DatePicker } from '../form/DatetimePicker'


class AggregatedReport extends Component {

    constructor(props) {
        super(props)
        this.state = { client_group: null,
                       selected_client_group_node: null }
    }
    
    componentDidMount() {
        const { dispatch } = this.props
        this.autoSelectGroup()
        dispatch(aggregatedReportList.loadAggregatedReportIfNeeded())
        dispatch(aggregatedReportCustomerCourseList.fetchListIfNeeded())
        dispatch(aggregatedReportCustomerCourseForExportList.fetchListIfNeeded())
        dispatch(clientGroupList.ensureTreeisLoaded())
    }

    componentDidUpdate() {
      const { dispatch } = this.props
      this.autoSelectGroup()
      dispatch(aggregatedReportList.loadAggregatedReportIfNeeded())
      dispatch(aggregatedReportCustomerCourseList.fetchListIfNeeded())
      dispatch(aggregatedReportCustomerCourseForExportList.fetchListIfNeeded())      
      dispatch(clientGroupList.ensureTreeisLoaded())
    }

    autoSelectGroup() {
        const { group_tree, dispatch } = this.props
        const { client_group } = this.state
        if ( ! client_group && size(group_tree) > 0 && group_tree[0].name ) {
          const group = group_tree[0]
          dispatch(aggregatedReportList.setClientGroup(group.id))
          dispatch(aggregatedReportCustomerCourseList.setClientGroup(group.id))
          dispatch(aggregatedReportCustomerCourseForExportList.setClientGroup(group.id))
          this.setState({client_group: group,
                         selected_client_group_node: null})
        }
    }

    onSelectedGroup = (node) => {
        const { dispatch } = this.props
        const { selected_client_group_node } = this.state
        const client_group = node.props.node
        if ( selected_client_group_node ) {
            selected_client_group_node.deselect()
        }
      dispatch(aggregatedReportList.setClientGroup(client_group.id))
      dispatch(aggregatedReportCustomerCourseList.setClientGroup(client_group.id))
      dispatch(aggregatedReportCustomerCourseForExportList.setClientGroup(client_group.id))
      this.setState({client_group: client_group,
                     selected_client_group_node: node})
    }

    getCourseCellValue = (header_key, item, index, row_index) => {
        switch( header_key ) {
          case 'display_name':
            return item.customer_name
          case 'client_group':
            return item.business_unit_name
          case 'course_name':
            return item.name            
          case 'progress':
            return <div css={item.percentage_complete === 100 ? result_green_style : result_red_style} >{item.percentage_complete}%</div>            
          case 'passed_at':
            if ( item.most_recent_course_passed_at ) {
              return <Timestamp value={item.most_recent_course_passed_at} format="date" />
            } else {
                    return ""
                }
            default:
                return undefined
        }
    }

    buildDataArrayForCSVExport = () => {
        const { headers, customer_courses_for_export } = this.props
      let exportData = []
        exportData.push(map(headers, (header) => { return header && header.name }))
        map(customer_courses_for_export, (course) => {
            exportData.push(map(headers, (value, key) => {
                switch( key ) {
                    case 'display_name':
                        return course.customer_name
                    case 'client_group':
                        return course.business_unit_name
                    case 'course_name':
                        return course.name
                    case 'progress':
                        return `${course.percentage_complete}%`
                    case 'passed_at':
                        if ( course.most_recent_course_passed_at ) {
                            return moment(course.most_recent_course_passed_at).format('DD MMM YYYY HH:mm:ss')
                        } else {
                            return ""
                        }
                    default:
                        return undefined
                }
            }))
          map(course.additional_items, (item) => {
            exportData.push(map(headers, (value, key) => {
              switch( key ) {
                    case 'display_name':
                        return item.customer_name
                    case 'client_group':
                        return item.business_unit_name
                    case 'course_name':
                        return item.course_name
                    case 'progress':
                        return `${item.percentage_complete}%`
                    case 'passed_at':
                        if ( item.most_recent_course_passed_at ) {
                            return moment(item.most_recent_course_passed_at).format('DD MMM YYYY HH:mm:ss')
                        } else {
                            return ""
                        }
                default:
                  return undefined
              }
            }))
          })
        })
        return exportData
    }

    onFilterChanged = (new_values) => {
      const { dispatch } = this.props
      dispatch(aggregatedReportCustomerCourseList.updateListFilter(new_values))
      dispatch(aggregatedReportCustomerCourseForExportList.updateListFilter(new_values))
    }

  onDateFromChanged = (value) => {
    const { dispatch } = this.props
    dispatch(aggregatedReportList.updateListFilter({'completed_at_from': value.format()}))
    dispatch(aggregatedReportCustomerCourseList.updateListFilter({'completed_at_from': value.format()}))
    dispatch(aggregatedReportCustomerCourseForExportList.updateListFilter({'completed_at_from': value.format()}))
  }

  onDateToChanged = (value) => {
    const { dispatch } = this.props
    dispatch(aggregatedReportList.updateListFilter({'completed_at_to': value.format()}))
    dispatch(aggregatedReportCustomerCourseList.updateListFilter({'completed_at_to': value.format()}))
    dispatch(aggregatedReportCustomerCourseForExportList.updateListFilter({'completed_at_to': value.format()}))
  }

  
  renderFilter() {
        return (
            <div css={filter_style}>
              <DatePicker extra_css={filter_date_picker_style} onChange={this.onDateFromChanged} placeholder="From" />
              <DatePicker extra_css={filter_date_picker_style} onChange={this.onDateToChanged} placeholder="To" />
            </div>
        )
    }
    
    renderClientGroupPopover() {
        const { client_group } = this.state
        const { group_tree } = this.props

        const popover = (
            <Popover id="client_group">
              <Popover.Title>
                Groups
              </Popover.Title>
              <Popover.Content>
                <FSRoot childNodes={group_tree}
                        onSelect={this.onSelectedGroup}
                />
                <Separator variant="h20" />
                <div css={group_buttons_container_style}>
                  <GrayButton onClick={() => document.body.click()}>
                    Close
                  </GrayButton>
                </div>
              </Popover.Content>
            </Popover>
        )
        
        return (
            <OverlayTrigger trigger="click"
                            placement="bottom"
                            rootClose={true}
                            overlay={popover}>
              <div css={client_group_name_container_style}>
                <Separator variant="w10" />
                -&nbsp;
                <div css={client_group_name_style}>
                  {get(client_group, "name", "No group selected") }
                  <Separator variant="w10" />
                  <GrayButton>Change group</GrayButton>
                </div>
              </div>
            </OverlayTrigger>
        )
    }
    
    renderCompanyCompliance() {
        const { report } = this.props
        return (
            <div css={[card_style, short_card_style]}>
              <div css={card_header_style}>
                Compliance Score
              </div>
              <div css={[result_style, result_green_style]}>
                {report && <FormattedNumber value={report.full_compliance_percentage} num_digits={2} />}%
              </div>
            </div>
        )
    }

    renderTotalRegisteredUsers() {
        const { report } = this.props
        return (
            <div css={[card_style, short_card_style]}>
              <div css={card_header_style}>
                Registered Users
              </div>
              <div css={[result_style, result_blue_style]}>
                {report && report.num_users}
              </div>
            </div>
        )
    }

    renderUsersTrainedChart() {
        const { report } = this.props
        const data = [
            {name: 'Users Fully Trained', value: report ? round(report.percentage_users_fully_trained, 2) : 0, fill: '#3aab47'},
            {name: 'Users still to complete training', value: report ? round(100 - report.percentage_users_fully_trained, 2) : 0, fill: '#c85958'}
        ]
        return (
            <div css={users_trained_chart_style}>
              <ResponsiveContainer width="100%" height="100%">
                <PieChart>
                  <Pie
                      data={data}
                      dataKey="value"
                      nameKey="name"
                      innerRadius="60%"
                      outerRadius="80%"
                      label={ true }
                      isAnimationActive={ false }
                  />
                </PieChart>
              </ResponsiveContainer>
            </div>
        )
    }
    
    renderUsersTrained() {
        const { report } = this.props
        return (
            <div css={[card_style, short_card_style]}>
              <Row>
                <Col md="6">
                  <Row>
                    <Col>
                      <div css={card_header_style}>
                        Users Fully Trained
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div css={[result_style, result_short_style, result_green_style]}>
                        {report && report.num_users_fully_trained}
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <hr/>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div css={card_header_style}>
                        Users Still to Complete Training
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div css={[result_style, result_short_style, result_red_style]}>
                        {report && report.num_users_not_fully_trained}
                      </div>
                    </Col>
                  </Row>
                </Col>
                <Col md="6">
                  { this.renderUsersTrainedChart()}
                </Col>
              </Row>
            </div>
        )
    }

    renderTrainingPerBusinessUnitTooltipValue = (value, name, props) => {
        return [`${round(value, 2)}%`, "Compliance Percentage"]
    }

    renderTrainingPerBusinessUnit() {
        const { report } = this.props
        const { client_group } = this.state
        const that = this
        let data = report ? map(report.children, function(child) { return {name: child.name, compliance_percentage: child.info.full_compliance_percentage} }) : []

        if (report) {
            data.unshift({
                name: client_group ? client_group.name : 'Total',
                compliance_percentage: report.full_compliance_percentage,
            })
        }
        
        if ( ! size(data) ) {
            return null
        }
        
        return (
            <div css={[card_style, short_card_style]}>
              <div css={[card_header_style, wide_card_header_style]}>
                Training per business group
              </div>
              <div css={business_unit_chart_style}>
                <ResponsiveContainer width="100%" height="80%">

                  <BarChart
                    width={1000}
                    height={1000}
                    data={data}
                    margin={{
                        top: 5, right: 30, left: 20, bottom: 3
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis domain={[0,100]} />
                    <Tooltip formatter={that.renderTrainingPerBusinessUnitTooltipValue}/>
                    <Bar type="monotone" dataKey="compliance_percentage" fill={clientManager.getPrimaryColour()} stroke="#63aed1" />
                  </BarChart>
                </ResponsiveContainer>
              </div>
            </div>
        )
    }

    ExportToExcel = () => {
        const data = this.buildDataArrayForCSVExport()
        var worksheet = XLSX.utils.aoa_to_sheet(data)
        var new_workbook = XLSX.utils.book_new()
        const filename = `User-Training-Progress-Report-${moment().format("DD-MMM-YYYY-HH-mm-ss")}.xlsx`
        XLSX.utils.book_append_sheet(new_workbook, worksheet, 'Learners')
        XLSX.writeFile(new_workbook, filename)
    }

    renderUsersTrainedList() {
      const { is_loading, headers, customer_courses, customer_course_filter, is_customer_course_for_export_list_loading } = this.props
      
      return (
        <div css={card_style}>
          <TableHeader>
            <div css={[card_header_style, wide_card_header_style]}>
              User Training Progress
            </div>
            <TableFilter
              label=""
              placeholder="Search"
              values={customer_course_filter}
              updateOnChange={ this.onFilterChanged }
            />
            <div css={ padding_left }>
              <BlueButton
                onClick={ () => { this.ExportToExcel() } }
                disabled={ is_customer_course_for_export_list_loading }
              >
                { is_customer_course_for_export_list_loading ? "Loading" : "Export Excel" }
              </BlueButton>
            </div>
          </TableHeader>
          <CommonTable
            is_loading={ is_loading }
            empty_message="There are no users."
            headers={ headers }
            items={ customer_courses }
            getCellValue={ this.getCourseCellValue }
            item_list={aggregatedReportCustomerCourseList}
          />
        </div>
      )
    }
    
    render() {
        const { is_loading, is_customer_course_list_loading } = this.props
        return (
            <MainLayout>

              <Container fluid css={container_style}> 
                <div css={header_style}>
                  Compliance Report
                  { this.renderClientGroupPopover() }
                  {/* { this.renderFilter() } */}
                </div>

                <WrappingBusyMask is_loading={is_loading}>
                  <div>
                    <Row>
                      <Col md="4">
                        { this.renderCompanyCompliance() }
                      </Col>
                      <Col md="3">
                        { this.renderTotalRegisteredUsers() } 
                      </Col>
                      <Col md="5">
                        { this.renderUsersTrained() }
                      </Col>
                    </Row>

                    <Separator variant="h20" />
                    
                    <Row>
                      <Col md="12">
                        { this.renderTrainingPerBusinessUnit() }
                      </Col>
                    </Row>

                    <Separator variant="h20" />
                  </div>
                </WrappingBusyMask>
                
                <Row>
                  <Col md="12">
                    <WrappingBusyMask is_loading={is_customer_course_list_loading}>
                      { this.renderUsersTrainedList() }
                    </WrappingBusyMask>
                  </Col>
                </Row>

                <Separator variant="h50" />
                
              </Container>
              
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
  const { } = props
  const report = aggregatedReportList.getAggregatedReport()
  const customer_courses = aggregatedReportCustomerCourseList.getVisibleObjects()
  const customer_courses_for_export = aggregatedReportCustomerCourseForExportList.getVisibleObjects()
  const group_tree = clientGroupList.getTree()
  return {
        report,
        is_loading: !report || aggregatedReportList.isLoading(),
    is_customer_course_list_loading: aggregatedReportCustomerCourseList.isLoading(),
    is_customer_course_for_export_list_loading: aggregatedReportCustomerCourseForExportList.isLoading(),
        customer_course_filter: aggregatedReportCustomerCourseList.getFilter(),
        headers: {
            'display_name': {name: 'Name'},
            'client_group': {name: 'Business unit'},
            'course_name': {name: 'Course'},    
            'progress': {name: 'Progress'},
            'passed_at': {name: 'Pass date'}
        },
    customer_courses,
    customer_courses_for_export,
        group_tree
    }
}
export default connect(mapStateToProps)(AggregatedReport)

const container_style = css`
background-color: #e6e8e3;
padding-left: 50px;
padding-right: 50px;
`

const header_style = css`
background-color: #e6e8e3;
font-size: 30px;
text-align: center;
color: #575853;
padding-top: 40px;
padding-bottom: 30px;
display: flex;
justify-content: center;
`

const card_style = css`
background: white;
border-radius: 8px;
text-align: center;
padding-bottom: 20px;
padding-top: 20px;
padding-left: 20px;
padding-right: 20px;
`

const short_card_style = css`
height: 350px;
`

const card_header_style = css`
font-size: 24px;
text-align: center;
padding-bottom: 20px;
flex-grow: 1;
`

const wide_card_header_style = css`
text-align: left;
`

const result_style = css`
font-weight: bold;
font-size: 80px;
text-align: center;
flex-grow: 1;
display: flex;
justify-content: center;
height: 70%;
vertical-align: middle;
align-items: center;
`

const result_short_style = css`
font-size: 40px;
`

const result_green_style = css`
color: #3aab47;
`

const result_blue_style = css`
color: #63b3d7;
`

const result_red_style = css`
color: #c24e4b;
`

const legend_style = {
  top: 0,
  left: 350,
  lineHeight: '24px'
}

const business_unit_chart_style = css`
    width: 100%;
    height: 100%;
`

const users_trained_chart_style = css`
    width: 100%;
    height: 100%;
`

const client_group_name_style = css`
display: flex;
`

const client_group_name_container_style = css`
display: flex;
cursor: pointer;
`

const filter_date_picker_style = css`
margin-right: 2px;
background-color: #fff;
`

const filter_style = css`
display: flex;
font-size: 16px;
align-items: center;
margin-left: 30px;
`

const group_buttons_container_style = css`
display: flex;
justify-content: flex-end;
`

const padding_left = css`
padding-left: 1rem;
`
