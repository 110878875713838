/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { default_theme as theme } from '../../emotion/theme'
import { jsx, css } from '@emotion/core'
import BlueButton from '../layout/BlueButton'
import VideoPlayer from '../VideoPlayer'

class VideoMain extends Component {

    onContinue = () => {
        const { onContinue, video } = this.props
        onContinue(video)
    }
    
    render() {
        const { video } = this.props
        return (
            <div css={video_container}>
              <VideoPlayer ref={video.id}
                           video_url={video.video_url}
                           onVideoFinished={this.onContinue}
              />
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {}
}
    

export default connect(mapStateToProps)(VideoMain)

const video_container = css(theme.mq({
    minHeight: ["640px", "100px"]
}))
