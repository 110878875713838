import { ItemList } from '../../orm'
import { map, size } from 'lodash'
import { http } from '../../lib'
import { constructClientGroupTree } from '../../actions/lib'

class AdminClientList extends ItemList {
    getEntityKey() {
        return 'admin/client'
    }

    getGroupsAsRecursiveTree(client) {
        return constructClientGroupTree(client.group_tree)
    }

}

export const adminClientList = new AdminClientList('admin_client__default')
