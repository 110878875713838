/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { useField } from 'formik'
import FormError from './FormError'
import FormWarning from './FormWarning'

export const FormikCheckbox = ({ label, field, type, checked, ...props }) => {
    return (
        <div css={container}>
          { label && 
            <label htmlFor={props.id || props.name}>{label}</label>
          }
          <input {...field} type={type} checked={checked} />
        </div>
    )
}

export const FormikInputField = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
  const [field, meta] = useField(props)
    return (
        <div css={container}>
          { label && 
            <label htmlFor={props.id || props.name}>{label}</label>
          }
          <input className="form-control" {...field} {...props} />
          <FormError meta={meta} />
        </div>
    )
}



class InputField extends Component {
    
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            type,
            tabIndex,
            maxLength,
            meta,
            ...extraProps
        } = this.props
        
        return (
            <div css={container}>
              
              <input {...input}
                     {...extraProps}
                     placeholder={placeholder}
                     css={[extra_class]}
                     type={type}
                     maxLength={maxLength}
                     className="form-control"
              />
              { meta.error && meta.touched ?
                <FormError meta={ meta } />
                :
                <FormWarning meta={ meta } />
              }
            </div>
        )
    }
}

export default InputField

const container = css`
width: 100%;
`

const textarea_style = css`
width: 100%;
`
