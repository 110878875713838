import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'

class FeaturedCoursePackageList extends ItemList {
    getEntityKey() {
        return "course_package/featured_course_packages"
    }
    
}

export const featuredCoursePackageList = new FeaturedCoursePackageList("featured_course_package__default")
