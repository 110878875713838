/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'

import { imageList } from '../actions/image'

import ReactCrop from 'react-image-crop'
import Modal from './Modal'

import 'react-image-crop/dist/ReactCrop.css'

class ImageCropper extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            crop: {}
        }
    }

    componentDidMount() {
        const { dispatch, image_id } = this.props
        dispatch(imageList.ensureObjectLoaded(image_id))
    }

    onChange = (crop) => {
        this.setState({
            crop: crop
        })
    }

    handleCrop = () => {
        const { onCrop } = this.props
        const { crop } = this.state
        if (onCrop) {
            onCrop(crop)
        }
    }

    
    render() {
        const { image, onCancel, background } = this.props
        const { crop } = this.state
        return (
            <Modal
                title="Crop Image"
                onActionModal={ this.handleCrop }
                actionLabel="Crop"
                onCloseModal={ onCancel }
                modal_size="original"
            >
              <div css={ crop_container } >
                <ReactCrop src={ image.original_download_url }
                           crop={ crop }
                           onChange={this.onChange}
                           style={{background: background}}
                />
              </div>
            </Modal>
        )
    }
}
function mapStateToProps(state, props) {
    const { image_id, onCrop, onCancel, background } = props
    const image = imageList.getObject(image_id)
    return {
        image_id,
        onCrop,
        onCancel,
        image,
        background
    }
}
export default withRouter(connect(mapStateToProps)(ImageCropper))

const crop_container = css`
display: flex;
flex-direction: column;
align-items: center;
max-height: 80vh;
max-width: 80vw;
`
