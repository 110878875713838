/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get, map, size, filter } from 'lodash'
import { imageList } from '../../actions/image'
import { Button } from 'react-bootstrap'
import BlueButton from '../layout/BlueButton'
import { default_theme as theme } from '../../emotion/theme'
import { Container, Col, Row } from 'react-bootstrap'
import Loading from '../Loading'
import WrappingBusyMask from '../WrappingBusyMask'
import { Separator } from '../layout/Separator'
import { customerVideoList } from '../../actions/customer_video'
import { customerDocumentList } from '../../actions/customer_document'
import { customerAnswerList } from '../../actions/customer_answer'
import { coursePackageActions } from '../../actions/course_package_action'
import { customerCourseActions } from '../../actions/customer_course_action'
import { customerCourseForPackageList } from '../../actions/customer_course'
import { coursePackageList } from '../../actions/course_package'
import { courseList } from '../../actions/course'
import { customerCourseList } from '../../actions/customer_course'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import CourseLayout from './CourseLayout'
import Question from './Question'

import VideoIntro from './VideoIntro'
import DocumentIntro from './DocumentIntro'
import AssessmentIntro from './AssessmentIntro'

import VideoMain from './VideoMain'
import DocumentMain from './DocumentMain'
import AssessmentMain from './AssessmentMain'

import VideoSummary from './VideoSummary'
import DocumentSummary from './DocumentSummary'
import AssessmentSummary from './AssessmentSummary'

import CourseSummary from './CourseSummary'


class RunCourse extends Component {

    constructor(props) {
        super(props)
        this.state = { active_question_index: null,
                       has_watched_override_video: false,
                       section_display_modes: {} }
    }
    
    componentDidMount() {
        const { dispatch, course_package_id } = this.props
        this.refresh()

        const on_done = (customer_course) => {
            dispatch(customerCourseForPackageList.ensureObjectLoaded(customer_course.id))
            dispatch(customerCourseForPackageList.updateListFilter({course_package: course_package_id}))
            dispatch(customerCourseForPackageList.fetchListIfNeeded())
        }
        
        if ( course_package_id ) {
            dispatch(customerCourseActions.startFromCoursePackage(course_package_id, on_done))
        }
    }

    componentDidUpdate(prev_props) {
        const { dispatch, course_package_id, course_package, customer_course_for_package_list_filter, customer_course } = this.props
        if ( course_package_id && customer_course_for_package_list_filter.course_package != course_package_id ) {
            dispatch(customerCourseForPackageList.updateListFilter({course_package: course_package_id}))
            dispatch(customerCourseForPackageList.fetchListIfNeeded())
        }
        this.refresh()
    }

    refresh() {
        const { dispatch, course_package_id } = this.props
        dispatch(coursePackageList.ensureObjectLoaded(course_package_id))
    }

    getCurrentSectionDisplayMode() {
        const { next_course_section } = this.props
        if ( ! next_course_section ) {
            return null
        }
        return this.getSectionDisplayMode(next_course_section.id)
    }
    
    getSectionDisplayMode(section_id) {
        const { section_display_modes } = this.state
        const res = get(section_display_modes, section_id, "intro")
        return res
    }

    resetSectionDisplayModeView() {
        this.setState( {section_display_modes: {}} )
    }

    setSectionDisplayModeViewIntro(section_id) {
        this._setSectionDisplayMode(section_id, "intro")
    }
    
    setSectionDisplayModeViewMain(section_id) {
        this._setSectionDisplayMode(section_id, "main")
    }

    setSectionDisplayModeViewSummary(section_id) {
        this._setSectionDisplayMode(section_id, "summary")
    }

    setSectionDisplayModeViewDone(section_id) {
        this._setSectionDisplayMode(section_id, "done")
    }

    _setSectionDisplayMode(section_id, mode) {
        const section_display_modes = {}
        section_display_modes[section_id] = mode
        this.setState({section_display_modes: section_display_modes})
    }

    onGotoCourses = () => {
        const { history } = this.props
        history.push("/courses")
    }

    redoFromMedia = () => {
        const { dispatch, course, course_package_id, customer_course } = this.props
        const that = this

        const on_done = function() {
            dispatch(coursePackageList.invalidateObject(course_package_id))
            dispatch(coursePackageList.ensureObjectLoaded(course_package_id))
            dispatch(customerCourseForPackageList.invalidateList())
            dispatch(customerCourseForPackageList.invalidateObject(customer_course.id))
            dispatch(customerCourseForPackageList.ensureObjectLoaded(customer_course.id)).then( () => {
                that.onResetCourseSectionOverride()
                that.resetSectionDisplayModeView()
            })
        }
        dispatch(customerCourseActions.resetCourseToFirstVideoOrDocumentInSequence(customer_course.id, on_done))
    }

    forceReanswerQuestions = () => {
        const { dispatch, course, course_package_id, customer_course, next_course_section } = this.props
        const course_section = next_course_section
        const that = this
        const on_done = function() {
            dispatch(coursePackageList.invalidateObject(course_package_id))
            dispatch(coursePackageList.ensureObjectLoaded(course_package_id))
            dispatch(customerCourseForPackageList.invalidateList())
            dispatch(customerCourseForPackageList.invalidateObject(customer_course.id))
            dispatch(customerCourseForPackageList.ensureObjectLoaded(customer_course.id)).then( () => {
                that.resetSectionDisplayModeView()
                that.onResetCourseSectionOverride()
            })
        }
        dispatch(customerCourseActions.resetSection(customer_course.id, course_section.id, on_done))
    }

    onGotoCourseSection = (course_section) => {
        const { history, course_package_id } = this.props
        this.setState({active_question_index: null})
        history.push(`/course/run/${course_package_id}/${course_section.id}`)
    }

    onResetCourseSectionOverride = () => {
        const { history, course_package_id } = this.props
        this.setState({has_watched_override_video: null,
                       has_watched_override_document: null,
                       active_question_index: null})
        history.push(`/course/run/${course_package_id}`)
    }

    onChangeActiveQuestionIndex = (new_index) => {
        this.setState({active_question_index: new_index})
    }
    
    refreshCustomerCourse = () => {
        const { dispatch, customer_course, course_package_id } = this.props
        dispatch(customerCourseForPackageList.invalidateObject(customer_course.id))
        dispatch(customerCourseForPackageList.ensureObjectLoaded(customer_course.id))
    }
    
    loadNextSection = () => {
        const { dispatch, customer_course, course_package_id, next_course_section } = this.props
        const that = this
        this.setSectionDisplayModeViewDone(next_course_section.id)
        dispatch(coursePackageList.invalidateObject(course_package_id))
        dispatch(coursePackageList.ensureObjectLoaded(course_package_id))
        dispatch(customerCourseForPackageList.invalidateObject(customer_course.id))
        dispatch(customerCourseForPackageList.ensureObjectLoaded(customer_course.id)).then( () => {
            that.resetSectionDisplayModeView()
            that.onResetCourseSectionOverride()
        })
    }

    redoCurrentSection = () => {
        const { next_course_section } = this.props
        console.log("Redo", next_course_section)
        this.setSectionDisplayModeViewIntro(next_course_section.id)
    }
    
    renderVideoIntro(course_section) {
        const { course } = this.props
        return <VideoIntro video={course_section.video}
                           course={course}
                           onContinue={() => this.setSectionDisplayModeViewMain(course_section.id)}
               />
    }

    renderDocumentIntro(course_section) {
        const { course } = this.props
        return <DocumentIntro document={course_section.document}
                              course={course}
                              onContinue={() => this.setSectionDisplayModeViewMain(course_section.id)}
            />
    }

    renderAssessmentIntro(course_section) {
        const { course } = this.props
        return <AssessmentIntro course_section={course_section}
                                course={course}
                                onContinue={() => this.setSectionDisplayModeViewMain(course_section.id)}
               />
    }

    renderVideoMain(course_section) {
        const { dispatch, customer_course } = this.props
        const that = this
        
        const onContinue = () => {
            dispatch(customerVideoList.onWatchedVideo(customer_course.id, course_section.video.id))
            this.refreshCustomerCourse()
            that.setSectionDisplayModeViewSummary(course_section.id)
        }
        
        return <VideoMain video={course_section.video}
                          onContinue={onContinue}
               />
    }

    renderDocumentMain(course_section) {
        const { dispatch, customer_course } = this.props
        const that = this
        
        const onContinue = () => {
            dispatch(customerDocumentList.onWatchedDocument(customer_course.id, course_section.document.id))
            this.refreshCustomerCourse()
            that.setSectionDisplayModeViewSummary(course_section.id)
        }
        
        return <DocumentMain document={course_section.document}
                             onContinue={onContinue}
               />
    }

    renderAssessmentMain(course_section) {
        const { customer_course } = this.props
        const { active_question_index } = this.state
        const that = this
        
        const onContinue = () => {
            this.refreshCustomerCourse()
            that.setSectionDisplayModeViewSummary(course_section.id)
        }
        
        return <AssessmentMain course_section={course_section}
                               customer_course={customer_course}
                               active_question_index={active_question_index}
                               onChangeActiveQuestionIndex={this.onChangeActiveQuestionIndex}
                               onContinue={onContinue}
               />
    }

    renderVideoSummary(course_section) {
        const { course } = this.props
        return <VideoSummary video={course_section.video}
                             course={course}
                             onContinue={this.loadNextSection}
                             onRedo={this.redoCurrentSection}
               />
    }

    renderDocumentSummary(course_section) {
        const { course } = this.props
        return <DocumentSummary document={course_section.document}
                                course={course}
                                onContinue={this.loadNextSection}
                                onRedo={this.redoCurrentSection}
            />
    }

    renderAssessmentSummary(course_section) {
        const { customer_course, is_loading, is_saving } = this.props
        const customer_section = course_section && customerCourseForPackageList.getCustomerSection(customer_course, course_section)

        const assessment_summary_is_ready = customer_section && customer_section.id && !is_loading && !is_saving

        const redo = () => {
            this.forceReanswerQuestions()
        }

        return (
            <>
              <WrappingBusyMask is_loading={!assessment_summary_is_ready}>
                <AssessmentSummary course_section={course_section}
                                   customer_section={customer_section}
                                   onContinue={this.loadNextSection}
                                   onRedo={redo}
                                   onRedoFromMedia={this.redoFromMedia}
                />
              </WrappingBusyMask>
            </>
        )
    }
    
    renderActiveCourseSection() {
        const { history, customer_course, course, next_course_section, override_course_section_id } = this.props
        const course_section = next_course_section

        if ( override_course_section_id !== course_section.id ) {
            this.onGotoCourseSection(course_section)
        }
        
        const section_display_mode = this.getCurrentSectionDisplayMode()
        
        switch( course_section.section_type ) {
            case "video":
                switch ( section_display_mode ) {
                    case "intro": return this.renderVideoIntro(course_section)
                    case "main": return this.renderVideoMain(course_section)
                    case "summary": return this.renderVideoSummary(course_section)
                }
            case "document":
                switch ( section_display_mode ) {
                    case "intro": return this.renderDocumentIntro(course_section)
                    case "main": return this.renderDocumentMain(course_section)
                    case "summary": return this.renderDocumentSummary(course_section)
                }
            case "assessment":
                switch ( section_display_mode ) {
                    case "intro": return this.renderAssessmentIntro(course_section)
                    case "main": return this.renderAssessmentMain(course_section)
                    case "summary": return this.renderAssessmentSummary(course_section)
                }
            default:
                return null
        }
        
    }

    render() {
        const { is_loading, course, course_package_id, next_course_section, customer_course } = this.props
        const course_section = next_course_section        
        let course_status;
        
        return (
            <CourseLayout course={course}
                          course_package_id={course_package_id}
                          is_loading={is_loading}
                          active_course_section={course_section}
                          space_top={true}
                          gotoCourseSection={this.onGotoCourseSection}
                          show_title={false}
                          enable_border={false}>

              <WrappingBusyMask is_loading={is_loading}>
                { !is_loading && course_section &&
                  <div className="active_course_section">
                    { this.renderActiveCourseSection() }
                  </div>
                }
                { !is_loading && !course_section && customer_course &&
                  <CourseSummary customer_course={customer_course} onContinue={this.onGotoCourses} />
                }
              </WrappingBusyMask>
            </CourseLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const course_package_id = get(props, ["match", "params", "course_package_id"], null)
    const override_course_section_id = get(props, ["match", "params", "override_course_section_id"], null)
    const course_package = coursePackageList.getObject(course_package_id)
    const customer_course = head(customerCourseForPackageList.getVisibleObjects())
    const override_course_section = override_course_section_id && courseList.getCourseSection(course_package.course, override_course_section_id)
    const next_course_section = override_course_section || (customer_course && course_package && customerCourseList.getNextUnpassedCourseSection(customer_course, course_package.course))

    console.log("next_course_section", next_course_section)

    return {
        course_package_id,
        course_package,
        course: course_package && course_package.course,
        override_course_section_id,
        customer_course,
        next_course_section,
        is_loading: coursePackageList.isLoading() || !course_package || !course_package.course || customerCourseForPackageList.isLoading(),
        is_saving: coursePackageList.isSaving() || customerAnswerList.isSaving(),
        customer_course_for_package_list_filter: customerCourseForPackageList.getFilter()
    }
}

export default connect(mapStateToProps)(RunCourse)

const summary_container = css`
background-color: #fff;
margin-top: 60px;
`

const section_summary__content = css`
margin-top: 20px;
text-align: center;
`

const section_summary__score = css`
color: #fff;
width: 100%;
font-size: 90px;
line-height: 90px;
height: 130px;
text-align: center;
font-weight: 700;
display: flex;
justify-content: center;
align-items: center;
`

const section_summary__passed = css`
background: #48B02C;
`

const section_summary__failed = css`
background: #ED2629;
`

const section_summary__info = css`
font-size: 22px;
text-align: center;
`

const section_summary__info_container = css(theme.mq({
    paddingLeft: ["100px", "0px"],
    paddingRight: ["100px", "0px"]
}))

const section_summary_button_container = css`
display: flex;
justify-content: center;
`

const section_summary__button = css`
border-radius: 0px;
color: #fff;
border: 0px;
font-size: 18px;
padding-top: 10px;
padding-left: 24px;
padding-right: 24px;
padding-bottom: 10px;
margin: 5px;
&:hover {
  background-color: #fff;
  border-color: #fff;
}
&:focus{
  background-color: #fff;
  border-color: #fff;
}


`

const section_summary__success_button = css`
color: #0aa00a;
&:hover {
  color: #0aa00a;
}
&:focus{
  color: #0aa00a;
}
`

const summary_button_style = css`
font-size: 14px;
`


const section_summary_results_title = css`
font-size: 18px;
font-weight: bold;
`
