/** @jsx jsx */
import React, { Component, useState } from 'react'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../../emotion/theme'
import { Button } from 'react-bootstrap'

export const BaseButton = ({ onClick, variant, extra_css, children, auto_disable, ...props }) => {

    const [clicked_against_http_timer, setClickedAgainstHttpTimer ] = useState(0)

    extra_css = extra_css || null
    variant = variant || "default"

    const localOnClick = (evt) => {
        if ( onClick ) {
            evt.preventDefault()
            onClick()
        } else {
            return true
        }
    }

    return (
        <Button variant={variant} css={extra_css} onClick={localOnClick} {...props}>
          {children}
        </Button>
    )

}

const style = css`
background-color: ${theme.colors.primary_blue};
border: 1px solid ${theme.colors.primary_blue};
font-weight: 500;


&:hover {
background-color: ${theme.colors.primary_blue};
}
`

const disabled_style = css`
  cursor: default;
  background-color: ${theme.colors.primary_dark};

`
