/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { includes, split, last } from 'lodash'
import Vimeo from '@u-wave/react-vimeo'

const SHOW_CONTROLS=true

class VideoPlayer extends Component {

    onEnd = () => {
        const { onVideoFinished } = this.props
        if ( onVideoFinished ) {
            onVideoFinished()
        }
    }

    renderVimeoPlayer() {
        const {video_url, width, height, start_immediately} = this.props
        if ( ! video_url ) {
            return (<div>No video specified</div>)
        }
        return (
            <Vimeo video={video_url}
                   width={width || "1110px"}
                   height={height || "624px"}
                   autoplay
                   responsive={true}
                   paused={false}
                   controls={SHOW_CONTROLS}
                   onEnd={this.onEnd}
            />
        )
    }
    
    render() {
        const {video_url, width, height, start_immediately} = this.props
        const is_vimeo = includes(video_url, "vimeo.com")
        return (
            <div css={container}>
              { is_vimeo && this.renderVimeoPlayer() }
              { ! is_vimeo &&
                <i>Unknown video format : {video_url}</i>
              }
            </div>
        )
    }
}
export default VideoPlayer;

const container = css`
overflow: hidden;
`

const image = css`
max-width: 100%;
max-height: 100%;
`
