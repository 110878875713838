/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { jsx, css, Global } from '@emotion/core'
import { Link } from 'react-router-dom'
import { clearAuthentication, loggedInUser } from '../actions/auth'
import { loggedInUserManager } from '../actions/logged_in_user'
import { clientManager } from '../actions/client_manager'
import { default_theme as theme } from '../emotion/theme'
import logo from '../images/dale_small.png'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
import { Container } from 'react-bootstrap'
import default_profile_image from '../images/profile_dark.png'

class MenuTop extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(loggedInUserManager.ensureUserLoaded())
        dispatch(clientManager.ensureClientIsLoaded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(loggedInUserManager.ensureUserLoaded())
        dispatch(clientManager.ensureClientIsLoaded())
    }
    
    onLogout = () => {
        const { dispatch } = this.props
        dispatch(clearAuthentication())
    }

    render() {
        const { user, email, display_name, can_view_aggregated_reports, client } = this.props
        const profile_picture_url = get(user, ["profile_picture", "thumbnail_download_url"], default_profile_image)
        const title = (<span css={title_style}>
                         <img css={profile_picture_style} src={profile_picture_url} />
                         &nbsp;&nbsp;
                         {display_name}
                       </span>
                      )

        if ( ! client ) {
            return null
        }
        
        return (
            <div css={outer_container}>
              <Container css={container} className="menutop_container">
                <Global styles={global_styles} />
                <Navbar expand="lg" >

                  <Navbar.Brand>
                    <Link to= "/">
                      <img css={logo_style} src={ get(client, ["logo", "original_download_url"], logo) } alt="" />
                    </Link>
                  </Navbar.Brand>
                  
                  <Navbar.Toggle />
                  <Navbar.Collapse>
                    <Nav className="mr-auto">
                    </Nav>

                    <Nav className="ml-auto" css={nav_style}>

                      <Nav.Item>
                        <Nav.Link css={nav_link} href="/courses">
                          MY COURSES
                        </Nav.Link>
                      </Nav.Item>

                      <NavDropdown title="REPORTING">
                        <Nav.Item>
                          <NavDropdown.Item>
                            <Link to="/report" css={nav_link}>MY REPORT</Link>
                          </NavDropdown.Item>
                          { can_view_aggregated_reports &&
                            <NavDropdown.Item>
                              <Link to="/aggregated_report" css={nav_link}>AGGREGATED REPORT</Link>
                            </NavDropdown.Item>
                          }
                        </Nav.Item>
                      </NavDropdown>

                      <Nav.Item>
                        <Nav.Link css={nav_link} href="/certificates">
                          CERTIFICATES
                        </Nav.Link>
                      </Nav.Item>

                      <Nav.Item css={support_menu_style}>
                        <Nav.Link css={nav_link} href="/courses">
                          SUPPORT
                        </Nav.Link>
                      </Nav.Item>
                      
                      
                      <NavDropdown title={title}>
                        <Nav.Item>
                          <NavDropdown.Item>
                            <Link to="/profile" css={nav_link} >PROFILE</Link>
                          </NavDropdown.Item>
                          <NavDropdown.Item onClick={ this.onLogout } tag={Link}>
                            LOGOUT
                          </NavDropdown.Item>
                        </Nav.Item>
                      </NavDropdown>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </Container>
            </div>
        )
    }

}

function mapStateToProps(state, props) {
    const user = loggedInUserManager.getUser()

    const display_name = (get(user, "first_name", null) || get(user, "email", "")).toUpperCase()
    
    return {
        email: get(user, "email"),
        display_name,
        user,
        can_view_aggregated_reports: get(user, 'can_view_aggregated_reports'),
        client: clientManager.getClient()
    }
}
export default connect(mapStateToProps)(MenuTop)

const global_styles = css`

.menutop_container .navbar {
  padding-left: 0px;
  padding-right: 0px;
}

.menutop_container .navbar-light .navbar-nav a {
    color: rgba(0, 0, 0, 1);
}

.menutop_container .nav-item {
    margin-left: 23px;
}
`

const container = css(theme.mq({
    paddingLeft: ["0px", "15px"],
    paddingRight: ["0px", "15px"],
    backgroundColor: theme.colors.menu_background
}))

const outer_container = css`
background-color:  ${theme.colors.menu_background};
width: 100%;
`

const nav_link = css`
  text-decoration: none;
  color: rgba(0,0,0);

  &:hover {
    text-decoration: none;
    color: rgba(0,0,0,0.9);
  }

`

const logo_style = css`
height: 70px;
`

const profile_picture_style = css`
width: 40px;
height: 40px;
border-radius: 20px;
`

const title_style = css`
`

const nav_style = css(theme.mq({
    alignItems: ["center", "flex-end"]
}))

const support_menu_style = css(theme.mq({
    display: ["none", "none"]
}))
