import { ItemList } from '../orm'
import { map, get, head } from 'lodash'

class AggregatedReportList extends ItemList {
    getEntityKey() {
        return "aggregated_report/report_data"
    }

    setClientGroup(client_group_id) {
        return this.updateListFilter({client_group: client_group_id})
    }

    setCoursePackage(course_package_id) {
        return this.updateListFilter({course_package: course_package_id})
    }
    
    loadAggregatedReportIfNeeded() {
        const that = this
        return async (dispatch, getState) => {
            const filter = this.getFilter()
            dispatch(that.fetchListIfNeeded())
        }
    }

    getAggregatedReport() {
        return head(this.getVisibleObjects())
    }
    
}

export const aggregatedReportList = new AggregatedReportList("aggregated_report__default")
