import { ItemList } from '../../orm'
import { has, union, map } from 'lodash'
import { http } from '../../lib'

class AdminCourseSectionQuestionList extends ItemList {
    getEntityKey() {
        return 'admin/course_section_question'
    }

}

export const adminCourseSectionQuestionList = new AdminCourseSectionQuestionList('admin_course_section_question__default')
