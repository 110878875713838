/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../../emotion/theme'

export class CourseCardOverlay extends Component {
    render() {
        return (
            <div css={course_banner_overlay_card_style}>
              {this.props.children}
            </div>
        )
        
    }

}

const course_banner_overlay_card_style = css(theme.mq({
    position: "absolute",
    width: ["420px", "auto"],
    textAlign: "center",
    backgroundColor: "#fff",
    padding: ["30px", "15px"],
    borderRadius: "4px"
}))

