/** @jsx jsx */
import { Component } from 'react'
import React from 'react'
import ReactDom from 'react-dom'
import ReactDatetime from 'react-datetime'
import moment from 'moment'
import { isEmpty } from 'lodash'
import { jsx, css } from '@emotion/core'
import cross_icon from '../../images/cross.png'
import 'react-datetime/css/react-datetime.css'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'
import { default_theme as theme } from '../../emotion/theme'
import { useField } from 'formik'
import FormError from './FormError'
import FormWarning from './FormWarning'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

export const FormikTimePicker = ({ label, ...props }) => {
    const [field, meta] = useField(props)
    const { formik_props } = props

    const on_change = function(value) {
        const { setFieldValue } = formik_props
        const { name } = field
        formik_props.setFieldValue(name, value)
    }

    const old_v = field.value
    field.value = moment(field.value)
    
    return (
        <div css={container}>
          { label && 
            <label htmlFor={props.id || props.name}>{label}</label>
          }
          <TimePicker
              showSecond={false}
              defaultValue={field.value}
              onChange={on_change}
              format={'h:mm a'}
              use12Hours
              inputReadOnly
          />
          <FormError meta={meta} />
        </div>
    )
}


export const FormikDatePicker = ({ label, placeholder, ...props }) => {
    const [field, meta] = useField(props)
    const { formik_props } = props

    const on_change = function(value) {
        const { setFieldValue } = formik_props
        const { name } = field
        formik_props.setFieldValue(name, value)
    }

    const old_v = field.value
    field.value = moment(field.value)
    if ( ! field.value.isValid() ) {
         field.value = moment()
    }

    const renderInput = (props, openCalendar, closeCalendar) => {
        if (props.value) {
            return (
                <div onClick={ openCalendar } css={ form_input }>{ props.value }</div>
            )
        } else {
            return (
                <div onClick={ openCalendar } css={ [form_input, disabled_input] }>{ placeholder }</div>
            )
        }
    }
    const fixed_value = (field.value && moment(field.value).toDate()) || null

    return (
        <div css={container}>
          { label && 
            <label htmlFor={props.id || props.name}>{label}</label>
          }
          <ReactDatetime
                    inputProps={{ placeholder: placeholder }}
                    closeOnSelect={true}
                    onChange={on_change}
                    value={fixed_value}
                    timeFormat={false}
                    dateFormat={"DD MMMM YYYY"}
                    styling={ form_input }
                    renderInput={ renderInput }
                />
          <FormError meta={meta} />
        </div>
    )
}

export const DatePicker = ({ value, placeholder, extra_css, onChange, ...props }) => {

    const renderInput = (props, openCalendar, closeCalendar) => {
        const css_styles = props.value ? [form_input, extra_css] : [form_input, disabled_input, extra_css]
        return (
            <div onClick={ openCalendar } css={css_styles}>
              <FontAwesomeIcon css={calendar_icon_style} icon={faCalendarAlt} />
              { props.value || placeholder }
            </div>
        )
    }
    const fixed_value = (value && moment(value).toDate()) || null

    return (
        <ReactDatetime inputProps={{ placeholder: placeholder }}
                       closeOnSelect={true}
                       onChange={onChange}
                       value={fixed_value}
                       timeFormat={false}
                       dateFormat={"DD MMMM YYYY"}
                       styling={ form_input }
                       renderInput={ renderInput }
        />
    )
}


const container = css`
display: flex;
`

const form_input = css`
display: flex;
align-items: center;
padding: 0 8px 0 8px;
min-height: 25px;
min-width: 200px;
border: none;
border: 1px solid ${theme.colors.gray1};
border-radius: 3px;
margin-right: 20px;

font-size: 16px;
outline: none;
background-color: transparent;

&:hover {
    cursor: pointer;
}

&:focus {
    border: 1px solid ${theme.colors.primary};
    background-color: ${theme.colors.white};
    outline: none;
}
`

const disabled_input = css`
color: #848484;
`


const calendar_icon_style = css`
margin-right: 12px;
color: #8e8e93;
padding-bottom: 1px;
`
