/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, map, size } from 'lodash'
import Timestamp from '../../components/Timestamp'
import { adminCertificateConfigList } from '../actions/admin_certificate_config'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminCertificateConfigForm from './AdminCertificateConfigForm' 
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {NotificationManager} from 'react-notifications'
import Card from '../../components/layout/Card'
import { Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'
import { Separator } from '../../components/layout/Separator'

class AdminCertificateConfig extends Component {

    componentDidMount() {
        const { dispatch, certificate_config_id } = this.props
        dispatch(adminCertificateConfigList.ensureObjectLoaded(certificate_config_id))
    }

    componentDidUpdate(prev_props) {
        const { dispatch, certificate_config_id } = this.props
        dispatch(adminCertificateConfigList.ensureObjectLoaded(certificate_config_id))
    }

    onSubmit = (values) => {
        const { dispatch, certificate_config_id, history } = this.props
        const that = this
        values.active = values.status ? values.status.value : true
        const on_done = function(json) {
            dispatch(adminCertificateConfigList.invalidateList())
            NotificationManager.success("Saved", "Certificate config saved")
            if ( ! certificate_config_id ) {
                history.push(`/admin/certificate_config/${json.id}`)
            }
        }
        if ( certificate_config_id ) {
            values.id = certificate_config_id
            return dispatch(adminCertificateConfigList.saveObject(values, on_done))
        } else {
            return dispatch(adminCertificateConfigList.saveNewObject(values, on_done))
        }
    }

    onCancel = () => {
        const { history } = this.props
        history.push('/admin/certificate_configs')
    }

    render() {
        const { certificate_config_id, is_busy, is_loading, certificate_config, page, breadcrumbs } = this.props
        const title = certificate_config_id ? 'Edit Certificate Config' : 'Add Certificate Config'
        return (
            <AdminMainLayout title={title} breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              { (!certificate_config_id || certificate_config.id) &&

                <>
                  <div>All courses in this list must be passed before issuing the certificate</div>
                  <AdminCertificateConfigForm
                    onSubmit={ this.onSubmit }
                    onCancel={ this.onCancel }
                    certificate_config_id={ certificate_config_id }
                  />
                </>
              }
              <Separator variant="h10" />
              { certificate_config && 
                <div>Last modified at <Timestamp value={certificate_config.modified_at} /></div>
              }
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const { page } = props
    const certificate_config_id = get(props, ["match", "params", "certificate_config_id"], null)
    const certificate_config = certificate_config_id && adminCertificateConfigList.getObject(certificate_config_id)
    const breadcrumbs = [ {label: get(certificate_config, "certificate_name"),
                           url: certificate_config &&`/admin/certificate_config/${certificate_config_id}`}]
    return {
        certificate_config_id,
        certificate_config,
        page,
        breadcrumbs,
        is_loading: adminCertificateConfigList.isLoading(),
        is_busy: adminCertificateConfigList.getIsSavingObject()
    }
}
export default connect(mapStateToProps)(AdminCertificateConfig)

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const button_style = css`
display: flex;
`
