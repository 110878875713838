/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { map, keys } from 'lodash'
import { Link } from 'react-router-dom'
import Container from 'react-bootstrap/Container'
import { LinkContainer } from 'react-router-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { Breadcrumb } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'

const DEFAULT_CRUMBS = [{'name': 'admin_home', 'label': 'Home', 'url':'/admin'},
                        {'name': 'home', 'label': 'Home', 'url':'/'},
]

class Breadcrumbs extends Component {

    render() {

        let { crumbs } = this.props

        map(crumbs, (crumb) => {
            map(DEFAULT_CRUMBS, function(default_crumb) {
                if ( default_crumb.name === crumb.name ) {
                    crumb.url = default_crumb.url
                    crumb.label = default_crumb.label
                    return
                }
            })
        })
        
        return (
            <Breadcrumb css={main}>
              { map(crumbs, function(crumb, index) {
                  if (! crumb.url ) {
                      return null
                  }
                  return (
                      <LinkContainer key={index} to={crumb.url} exact>
                        <Breadcrumb.Item>
                          {crumb.label}
                        </Breadcrumb.Item>
                      </LinkContainer>
                  )}
                   )}
            </Breadcrumb>
        )
        
    }

}

export default Breadcrumbs

const main = css`
background-color: ${theme.colors.page_background};
`

