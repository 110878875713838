import {NotificationManager} from 'react-notifications'

export function onSuccess(msg, sub_msg) {
    NotificationManager.success(sub_msg, msg)
}

export function onError(msg, sub_msg, ex) {
    // NotificationManager.error(msg, sub_msg)
    NotificationManager.error(sub_msg, msg)
}
