/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import SupportPopup from './SupportPopup'

import { default_theme as theme } from '../emotion/theme'

class Footer extends Component {

    render() {
        return (
            <div css={container}>
              <SupportPopup />
              <div css={inner_container}>
                <div css={[copyright]}>
                  COPYRIGHT ©2024. All rights reserved.
                </div>
              </div>
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    return {
    }
}
export default withRouter(connect(mapStateToProps)(Footer))

const container = css(theme.mq({
    position: "relative",
    textAlign: "center",
    backgroundColor: "#311f25",
    fontSize: ["18px", "14px"],
    height: ["160px", "50px"],

    paddingBottom: "28px",
    paddingTop: "28px",
    margin: "0",
    color: "#4e4d4d",
    display: "flex",
    justifyContent: "center"

}))

const inner_container = css`
width: 1140px;
margin: auto;
position: relative;
height: 100%;
color: #fff;
`

const copyright = css(theme.mq({
    position: "absolute",
    fontSize:["18px","9px"],
    bottom: ["15px", "-6px"],
    right: ["0px", "auto"],
    textAlign: ["auto", "center"],
    width: ["auto", "100%"]
}))
