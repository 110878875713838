/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import { jsx, css } from '@emotion/core'
import BlueButton from '../layout/BlueButton'
import { Separator } from '../layout/Separator'
import AssessmentScoreProgressBar from './AssessmentScoreProgressBar'
import SummaryAssessmentScore from './SummaryAssessmentScore'
import SummaryAssesmentSummaryMessage from './SummaryAssesmentSummaryMessage'

class CourseSummary extends Component {

    onContinue = () => {
        const { onContinue, document } = this.props
        onContinue(document)
    }
    
    render() {
        const { customer_course } = this.props
        const score_percentage = get(customer_course, "score_percentage", null)
                
        return (
            <div css={summary_container}>
              <div css={section_summary__content}>
                <SummaryAssessmentScore score_percentage={score_percentage}
                                        extra_css={section_summary__passed} />
                <SummaryAssesmentSummaryMessage title="Congratulations, you have passed this course!"
                                                msg="This course is finished, you can now return to your course list by clicking below."
                >
                  <div css={section_summary_button_container}>
                    <BlueButton css={summary_button_style} size="lg" onClick={this.onContinue}>
                      COURSE LIST
                    </BlueButton>
                  </div>

                  <Separator variant="h50" />

                  <AssessmentScoreProgressBar num_questions_passed={get(customer_course, "num_questions_passed")}
                                              num_questions={get(customer_course, "num_questions")}
                                              score_percentage={score_percentage}
                                              score_label="Course score"
                                              passed={true} />
                </SummaryAssesmentSummaryMessage>

                <Separator variant="h80" />
                
              </div>
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {}
}
    

export default connect(mapStateToProps)(CourseSummary)

const summary_container = css`
background-color: #fff;
margin-top: 60px;
`

const section_summary__content = css`
margin-top: 20px;
text-align: center;
`

const section_summary_button_container = css`
display: flex;
justify-content: center;
`

const summary_button_style = css`
font-size: 14px;
`

const section_summary__passed = css`
background: #48B02C;
`
