/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { SubmissionError } from 'redux-form'
import { get } from 'lodash'
import NewPasswordForm from './NewPasswordForm'
import LoginLayout from './LoginLayout'
import { setPassword, loggedInUser } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import dale_logo from '../images/dale.png'

class Login extends Component {

    componentDidMount() {
        const { user, history } = this.props
        if (user.has_usable_password) {
            history.push('/dashboard')
        }
    }
    
    handleSubmit = (values) => {
        const { dispatch, history } = this.props
        const on_done = function() {
            history.push('/dashboard')
        }
        if (values.password !== values.confirm_password) {
            throw new SubmissionError({confirm_password: "Passwords do not match"})
        } else {
            return dispatch(setPassword(values, on_done))
        }
    }

    render() {
        return (
            <LoginLayout msg="This is your first time logging in, please choose a password.">
              { ({client}) => 
                  <NewPasswordForm
                    client={client}
                    onSubmit={ this.handleSubmit }
                  />
              }
            </LoginLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const user = loggedInUser()
    return {
        user
    }
}
export default withRouter(connect(mapStateToProps)(Login))

