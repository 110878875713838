/** @jsx jsx */
import { Component } from 'react'
import { Link } from 'react-router-dom'
import { jsx } from '@emotion/core'
import { Button } from 'react-bootstrap'

class DefaultButton extends Component {
    renderButton = () => {
        const {
            extra_class,
            label,
            onClickButton,
            disabled,
            type,
            light,
            custom_styles
        } = this.props;
        
        return (
            <button
                css={ [ extra_class ] }
                style={custom_styles}
                className={light ? "btn btn-light" : "btn"}
                disabled={disabled}
                onClick={!disabled ? onClickButton : function() {}} 
                type={type}>
              { label && label }
              {this.props.children}
            </button>
        )
    }

    renderLink = () => {
        const {
            extra_class,
            label,
            onClickButton,
            disabled,
            to,
            target,
            custom_styles
        } = this.props;
        
        return (
            <Button href={to} onClick={onClickButton} {...this.props}>
            {label && label}
            {this.props.children}
            </Button>
        )
    }
    
    render() {
        const { to } = this.props;
        
        if(to) {
            return this.renderLink()
        } else {
            return this.renderButton()
        }
    }
}
export default DefaultButton

