/** @jsx jsx */
import React, { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Route, Switch } from 'react-router-dom'
import { includes } from 'lodash'
import TokenSent from './TokenSent'
import Login from './Login'
import ReactTimeout from 'react-timeout'
import NewPassword from './NewPassword'
import AdminRouter from '../admin/components/AdminRouter'
import Dashboard from './Dashboard'
import Profile from './Profile'
import NewEmail from './NewEmail'
import Courses from './courses/Courses'
import CourseLanding from './courses/CourseLanding'
import RunCourse from './courses/RunCourse'
import AggregatedReport from './reports/AggregatedReport'
import CustomerCertificates from './reports/CustomerCertificates'
import UserReport from './reports/UserReport'
import { Col } from 'react-bootstrap'
import { isLoggedIn, loggedInUser, clearAuthentication } from '../actions/auth'

    
const USER_ACTIVITY_EVENTS = [
    "load",
    "mousemove",
    "mousedown",
    "click",
    "scroll",
    "keypress"
]

const AUTO_LOGOUT_SECONDS = window.LOCAL_SETTINGS.AUTO_LOGOUT_SECONDS || 60*10


class AuthRouter extends Component {

    constructor(props) {
        super(props)
        this.initAutoLogoutTimer()
    }
    
    componentDidMount() {
        this.checkRouting()
    }
    
    componentDidUpdate(prevProps) {
        const { is_logged_in, history, has_usable_password, is_superuser } = this.props
        if (!prevProps.is_logged_in && is_logged_in) {
            if (is_superuser) {
                history.push('/admin/dashboard')
            }
            else if (has_usable_password) {
                history.push('/dashboard')
            } else {
                history.push('/first')
            }
        }
        if (prevProps.is_logged_in && !is_logged_in) {
            history.push('/login')
        }
        this.checkRouting()
    }

    checkRouting() {
        const { history, is_superuser } = this.props
        if ( is_superuser && ! window.location.pathname.startsWith("/admin") ) {
            history.push('/admin/dashboard')
        }
    }

    initAutoLogoutTimer() {
        const { dispatch, clearTimeout, setTimeout, resetTimeout } = this.props
        const that = this
        const setTimer = () => {
            if ( that.auto_logout_timer ) {
                clearTimeout(that.auto_logout_timer)
                that.auto_logout_timer = null
            }
            that.auto_logout_timer = setTimeout(() => dispatch(clearAuthentication()), AUTO_LOGOUT_SECONDS*1000)
        }
        setTimer()
        for ( var i in USER_ACTIVITY_EVENTS ) {
            window.addEventListener(USER_ACTIVITY_EVENTS[i], setTimer)
        }
    }
    
    renderNonProdWarning() {
        return null
        /* const dest = window.location.protocol + "//" + window.location.host.replace("web.", "mail.")
         * return (
         *     <div css={non_prod_warning}>
         *       WARNING: This is not a production site, data may not be preserved
         *       <div className="float-right">
         *         <Col>
         *           <a target="_blank" href={dest}>
         *             Email Inbox
         *           </a>
         *         </Col>
         *       </div>
         *     </div>
         * )*/
    }
    
    render() {
        const { is_logged_in, is_prod } = this.props
        if ( ! is_logged_in ) {
            return (
                <div>
                  <Switch>
                    <Route path="/token" exact={ true } component={ TokenSent } />
                    <Route path="/login" exact={true} component={ Login } />
                    <Route path="/" exact={true} component={ Login } />
                  </Switch>
                </div>
            )
        }
        return (
            <div>
              { ! is_prod && this.renderNonProdWarning() }
              <Switch>
                <Route path="/first" exact={ true } component={ NewPassword } />
                <Route path="/admin" exact={ false } component={ AdminRouter } />
                <Route path="/dashboard" exact={ true } component={ Courses } />
                <Route path="/courses" exact={ true } component={ Courses } />
                <Route path="/course/run/:course_package_id/:override_course_section_id" exact={ true } component={ RunCourse } />
                <Route path="/course/run/:course_package_id" exact={ true } component={ RunCourse } />
                <Route path="/courses/:course_id" exact={ true } component={ Courses } />
                <Route path="/course/:course_package_id" exact={ true } component={ CourseLanding } />
                <Route path="/aggregated_report/" exact={ true } component={ AggregatedReport } />
                <Route path="/certificates/" exact={ true } component={ CustomerCertificates } />
                <Route path="/report" exact={ true } component={ UserReport } />
                <Route path="/profile" exact={ true } component={ Profile } />
                <Route path="/email/:new_email" component={ NewEmail } />
                <Route path="/" component={ Courses } />
              </Switch>
            </div>
        )
    }
}

function mapStateToProps(state) {
    const is_logged_in = isLoggedIn()
    let has_usable_password = true
    let is_superuser = false
    if (is_logged_in) {
        const user = loggedInUser()
        has_usable_password = user && user['has_usable_password']
        is_superuser = user && user['is_superuser']
    }

    const is_prod = includes(window.location.host, "prod")
    return {
        is_logged_in: is_logged_in,
        has_usable_password: has_usable_password,
        is_superuser: is_superuser,
        is_prod
    }
}
export default withRouter(connect(mapStateToProps)(ReactTimeout(AuthRouter)))

const non_prod_warning = css`
height: 30px;
background-color: orange;
width: 100%;
text-align: center;
border-bottom: 1px solid #000000;
border-top: 1px solid #000000;
`
