/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'
import { get } from 'lodash'
import DefaultButton from '../../components/DefaultButton'
import { clearAuthentication, loggedInUser } from '../../actions/auth'
import { clientManager } from '../../actions/client_manager'
import { default_theme as theme } from '../../emotion/theme'
import logo from '../../images/dale_small.png'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Navbar from 'react-bootstrap/Navbar'
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'

class AdminMenuTop extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(clientManager.ensureClientIsLoaded())
    }
    
    onLogout = () => {
        const { dispatch } = this.props
        dispatch(clearAuthentication())
    }
    
    render() {
        const { email, client } = this.props
        return (
            <Navbar expand="lg" css={main}>

              <Navbar.Brand>
                <Link to="/">
                { get(client, "id") && 
                  <img css={logo_style} src={ get(client, ["logo", "original_download_url"], logo) } alt=""  />
                }
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle />
              <Navbar.Collapse>
                <Nav className="mr-auto">
                  <Nav.Item>
                    <Nav.Link>
                      <Link to="/admin/dashboard" css={nav_link}>Home</Link>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link>
                      <Link to="/admin/clients/" css={nav_link}>Clients</Link>
                    </Nav.Link>
                  </Nav.Item>
                  
                  <Nav.Item>
                    <Nav.Link>
                      <Link to="/admin/courses/" css={nav_link}>Course Templates</Link>
                    </Nav.Link>
                  </Nav.Item>

                  <Nav.Item>
                    <Nav.Link>
                      <Link to="/admin/certificate_configs/" css={nav_link}>Certificate Configs</Link>
                    </Nav.Link>
                  </Nav.Item>

                  <NavDropdown title="Reports">
                    <Nav.Item>
                      <NavDropdown.Item>
                        <Link to="/admin/reports/users/" css={nav_link}>
                          Courses by User
                        </Link>
                      </NavDropdown.Item>
                    </Nav.Item>
                  </NavDropdown>

                  <NavDropdown title="Logs" eventKey="logs">
                    <NavDropdown.Item>
                      <Link to="/admin/outbound_emails/">
                        Outbound emails
                      </Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                  
                  <NavDropdown title="Tools">
                    <NavDropdown.Item>
                      <Link to="/admin/editable_emails/">Email Editor</Link>
                    </NavDropdown.Item>
                  </NavDropdown>
                  
                </Nav>
                
                <Nav className="ml-auto">
                  <NavDropdown title={email}>
                    <Nav.Item>
                      <NavDropdown.Item onClick={ this.onLogout }>
                        Logout
                      </NavDropdown.Item>
                    </Nav.Item>
                  </NavDropdown>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
        )
    }

}

function mapStateToProps(state, props) {
    const admin = loggedInUser()
    return {
        email: admin && admin.email,
        client: clientManager.getClient()
    }
}
export default connect(mapStateToProps)(AdminMenuTop)

const main = css`
background-color: ${theme.colors.card_background};
`

const nav_link = css`
  text-decoration: none;
  color: rgba(0,0,0,0.5);

  &:hover {
    text-decoration: none;
    color: rgba(0,0,0,0.9);
  }

`

const logo_style = css`
height: 70px;
`
