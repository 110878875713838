/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { Separator } from '../layout/Separator'
import { default_theme as theme } from '../../emotion/theme'
import { CourseBannerContainer } from './CourseBannerContainer'
import BlueButton from '../layout/BlueButton'
import HollowButton from '../layout/HollowButton'
import continue_learning_img from '../../images/continue-learning.png'
import replay_img from '../../images/replay.png'
import BusyMask from '../BusyMask'

class VideoSummary extends Component {

    componentDidMount() {
        window.scroll(0, 0)        
    }
    
    onContinue = () => {
        const { onContinue, video } = this.props
        onContinue(video)
    }

    onRedo = () => {
        const { onRedo, video } = this.props
        onRedo(video)
    }
    
    render() {
        const { video, course } = this.props

        if (! course.banner_image.original_download_url ) {
            return <BusyMask loading={true} />
        }
        
        return (
            <CourseBannerContainer>
              <div css={ [course_banner_image_container, css`{background-image: url(${course.banner_image.original_download_url})}`] }>
                &nbsp;
              </div>
              <div css={course_banner_overlay_card_style}>
                <div>
                  <BlueButton onClick={this.onContinue} extra_style={button_style}>
                    <img src={continue_learning_img}  css={img_style} />
                    CONTINUE LEARNING
                  </BlueButton>
                </div>
                <Separator variant="h10" />
                <div>
                  <HollowButton onClick={this.onRedo} extra_style={button_style}>
                    <img src={replay_img} css={img_style} />
                    REPLAY
                  </HollowButton>
                </div>
              </div>
            </CourseBannerContainer>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {}
}
    

export default connect(mapStateToProps)(VideoSummary)

const course_banner_image_container = css`
filter: blur(8px);
-webkit-filter: blur(8px);
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
object-fit: cover;
object-position: 0 0;
`

const course_banner_overlay_card_style = css`
position: absolute;
width: 420px;
text-align: center;
padding: 30px;
border-radius: 4px;
`

const img_style = css`
height:30px;
margin-right: 15px;
`

const button_style = css`
width: 250px;
`
