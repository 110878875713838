/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get } from 'lodash'
import { imageList } from '../actions/image'
import { clientManager } from '../actions/client_manager'
import hamburger_icon from "../images/baseline_menu_black_18dp.png"
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../emotion/theme'
import { Container } from 'react-bootstrap'
import Footer from './Footer'
import MenuTop from './MenuTop'
import { StickyContainer, Sticky } from 'react-sticky'
import { Helmet } from 'react-helmet'

class MainLayout extends Component {

    constructor(props) {
        super(props)
        this.state = {is_open: false}
    }

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(clientManager.ensureClientIsLoaded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(clientManager.ensureClientIsLoaded())
    }
    
    onMenuChangeOpenState = (is_open) => {
        this.setState({is_open: is_open})
    }

    render() {
        const { title, children, max_width, title_buttons, client } = this.props
        const { is_open } = this.state
        return (
            <StickyContainer>
              <div css={main} >
                <Helmet>
                  <title>{ get(client, "name", "Jigsaw Compliance") }</title>
                </Helmet>
                <Sticky>
                  {({style}) => (
                      <div css={sticky_extended_style} style={style}>
                        <MenuTop />
                      </div>
                  )}
                </Sticky>
                <div css={inner_container}>
                  { title && 
                    <div css={ header }>
                      <h1 css={ css`margin-top:5px;` }>{ title }</h1>
                    </div>
                  }
                  { children }
                </div>
                <Footer />
              </div>
            </StickyContainer>
        )
    }
}

function mapStateToProps(state, props) {
    const { title, layout, children } = props
    return {
        title,
        children,
        client: clientManager.getClient()
    }
}
export default connect(mapStateToProps)(MainLayout)


const main = css(theme.mq({
    backgroundColor: [theme.colors.page_background, "#fff"],
    fontFamily: "Lato"

}))

const inner_container = css`
height: 100%;
margin-top: 0px;
`

const header = css`
display: flex;
flex-direction: row;
width: 100%;
height: 100px;
justify-content: space-between;
align-items: center;
color: ${theme.colors.secondary};
padding: 10px;
background-color: #efefef;
`

const header_title = css`
margin-top:5px;
text-align: center;
width: 100%;
font-size:16px;
font-weight: bold;
`

const sticky_extended_style = css`
z-index:999;
`
