/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get, map, size } from 'lodash'
import { imageList } from '../../actions/image'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../../emotion/theme'
import { Container, Col, Row } from 'react-bootstrap'
import { coursePackageList } from '../../actions/course_package'
import { customerCourseForPackageList } from '../../actions/customer_course'
import Loading from '../Loading'
import CourseLayout from './CourseLayout'
import { Separator } from '../layout/Separator'
import { clientManager } from '../../actions/client_manager'
import CourseIntro from './CourseIntro'

class CourseLanding extends Component {

    componentDidMount() {
        const { dispatch, course_package_id } = this.props
        dispatch(coursePackageList.ensureObjectLoaded(course_package_id))
        dispatch(customerCourseForPackageList.updateListFilter({course_package: course_package_id}))
        dispatch(customerCourseForPackageList.fetchListIfNeeded())
        
        window.scroll(0, 0)
    }

    componentDidUpdate(prev_props) {
        const { dispatch, course_package_id } = this.props
        if ( course_package_id != prev_props.course_package_id ) {
            dispatch(coursePackageList.ensureObjectLoaded(course_package_id))
            dispatch(customerCourseForPackageList.updateListFilter({course_package: course_package_id}))
            dispatch(customerCourseForPackageList.fetchListIfNeeded())
        }
    }

    onStartCourse = () => {
        const { history, course_package_id } = this.props
        history.push(`/course/run/${course_package_id}`)
    }

    render() {
        const { is_loading, course, customer_course, course_package_id } = this.props
        return (
            <CourseLayout course_package_id={course_package_id} course={course} is_loading={is_loading} space_top={true}>
              { course && course.banner_image &&
                <CourseIntro course={course} customer_course={customer_course} onContinue={this.onStartCourse} />
              }
            </CourseLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const course_package_id = get(props, ["match", "params", "course_package_id"], null)
    const course_package = coursePackageList.getObject(course_package_id)
    const customer_course = course_package && head(customerCourseForPackageList.getVisibleObjects())
    
    return {
        course_package_id,
        course_package,
        customer_course,
        course: get(course_package, "course"),
        is_loading: coursePackageList.isLoading()
    }
}

export default connect(mapStateToProps)(CourseLanding)

