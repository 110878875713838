/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { map, get, size, filter, keys, isEmpty } from 'lodash'
import { adminCourseList } from '../actions/admin_course'
import { adminCertificateConfigList } from '../actions/admin_certificate_config'
import InputField from '../../components/form/InputField'
import DefaultButton from '../../components/DefaultButton'
import { FormikDropdownField } from '../../components/Dropdown'
import Timestamp from '../../components/Timestamp'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import AdminCreationFooter from './AdminCreationFooter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikTextarea } from '../../components/form/TextareaField'
import { Separator } from '../../components/layout/Separator'
import { default_theme as theme } from '../../emotion/theme'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Col, Row, Container, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormFieldWithAction } from '../../components/form/FormFieldWithAction'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'certificate_name': Yup.string().required("Name is required")
})

class AdminCertificateConfigForm extends Component {
    
    componentDidMount() {
        const { dispatch, certificate_config_id } = this.props
        dispatch(adminCertificateConfigList.ensureObjectLoaded(certificate_config_id))
        dispatch(adminCourseList.updatePaginationNumItemsPerPage(1000))
        dispatch(adminCourseList.fetchListIfNeeded())
    }

    onSave = (values, setSubmitting, setFieldError) => {
        const { onSubmit } = this.props
        onSubmit(values).then( (res) => {
            setSubmitting(false)
            if ( res.errors ) {
                map(keys(res.errors), (field_name) => setFieldError(field_name, res.errors[field_name]))
            }
        })
    }

    onAddCourse = (evt, arrayHelpers, index) => {
        evt.preventDefault()
        arrayHelpers.insert(index+1, {pk: null})
    }

    onRemoveCourse = (evt, arrayHelpers, index) => {
        evt.preventDefault()
        arrayHelpers.remove(index)
    }

    render() {
        const { onCancel,
                error,
                course,
                initialValues,
                course_options,
                template_options,
        } = this.props

        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting, setFieldError }) => {this.onSave(values, setSubmitting, setFieldError)}}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        <Row>
                          <Col> 
                            <Card title="Certificate Config">
                              <Row>
                                <Col>
                                  <FormLabelValue>
                                    Name
                                    <FormikInputField
                                        name="certificate_name"
                                        type="text"
                                        placeholder="Enter a certificate name here (appears on the certificate)"
                                    />
                                  </FormLabelValue>
                                </Col>
                              </Row>

                              <Row>
                                <Col>
                                  <FormLabelValue>
                                    Enabled (if not ticked no new certificates are generated for this course configuration)
                                    <FormikCheckboxField name="enabled" />
                                  </FormLabelValue>
                                </Col>
                              </Row>

                              <Row>
                                <Col xs={6}>
                                  <FormLabelValue>
                                    Template
                                    <FormikDropdownField name="certificate_template"
                                                         formik_props={formik_props}
                                                         options={template_options}
                                    />
                                  </FormLabelValue>
                                </Col>

                                <Col xs={6}>
                                  { values.certificate_template === "cpd" &&
                                    <FormLabelValue>
                                      CPD Points
                                      <FormikInputField
                                          name="cpd_points"
                                          type="text"
                                          placeholder="Enter CPD Points as you want it displayed on the certificate"
                                      />
                                    </FormLabelValue>
                                  }
                                </Col>
                              </Row>
                              { values.certificate_template === "cpd" &&
                                <Row>
                                  <Col xs={6}>
                                  </Col>

                                  <Col xs={6}>

                                    <FormLabelValue>
                                      CPD Reference Number
                                      <FormikInputField
                                          name="cpd_ref_no"
                                          type="text"
                                          placeholder="Enter CPD Ref Number as you want it displayed on the certificate"
                                      />
                                    </FormLabelValue>
                                  </Col>
                                </Row>
                              }
                              <Row>
                                <Col>
                                  <FieldArray name="courses"
                                              render={arrayHelpers => (
                                                  <div>
                                                    { map(values.courses, (course, index) => (
                                                        <Container key={index}>
                                                          <Row css={row_separator}>
                                                            <Col xs={6}>
                                                              <FormLabelValue>
                                                                Course
                                                                <FormikDropdownField name={`courses.${index}`}
                                                                                     formik_props={formik_props}
                                                                                     options={course_options}
                                                                />
                                                                
                                                              </FormLabelValue>
                                                            </Col>
                                                            <Col xs={3}>
                                                              <Separator variant="h34" />
                                                              <Button variant="warning" onClick={(evt) => this.onRemoveCourse(evt, arrayHelpers, index)}>
                                                                Delete
                                                              </Button>
                                                            </Col>
                                                          </Row>
                                                          <Separator variant="h30" />
                                                        </Container>
                                                      ))}
                                                    <Row>
                                                      <Col>
                                                        <div css={button_group}>
                                                          <Button variant="outline-primary" onClick={(evt) => this.onAddCourse(evt, arrayHelpers, size(values.courses))}>
                                                            Add course
                                                          </Button>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                              )}
                                  />
                                </Col>
                              </Row>
                            </Card>
                          </Col>
                          
                        </Row>
                        
                        <Row>
                          <Col>
                            <Button type="submit">
                              Save
                            </Button>
                          </Col>
                        </Row>
                      </Form>
              )}
              }
            </Formik>
        )
    } 
}

function mapStateToProps(state, props) {
    const { onSubmit, certificate_config_id, onCancel } = props
    const certificate_config = adminCertificateConfigList.getObject(certificate_config_id) || {}
    const course_options = adminCourseList.getAsOptions()
    let template_options = [{value: 'generic', label: 'Generic'}, {value: 'cpd', label: 'CPD'}]

    if (!isEmpty(certificate_config)) {
        template_options = map(certificate_config.certificate_templates, template => { return {'value':template[0],'label':template[1]} })
    }
    return {
        onSubmit,
        onCancel,
        certificate_config,
        initialValues: certificate_config,
        course_options,
        template_options,
    }
}
export default withRouter(connect(mapStateToProps)(AdminCertificateConfigForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
margin: 10px;
padding: 10px;
@media(max-width: 1300px) {
align-items: center;
}
`

const row_container = css`
width: 50%;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
@media(max-width: 1300px) {
    width: 700px;
}
`

const column_container = css`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
width: 100%;
@media(max-width: 1300px) {
    flex-wrap: nowrap;
    height: auto;
}
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
padding-left: 30px;
padding-right: 30px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: column;
width: 100%;
`

const footer_row = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const row = css`
display: flex; 
justify-content: space-between;
`

const cell = css`
width: 45%;
`

const label_center = css`
color: ${theme.colors.secondary};
font-weight: 600;
text-align: center;
width: 100%;
`

const multi_value_row = css`
display: flex;
justify-content: space-between;
margin: 10px;
padding: 10px;
box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
`

const button_group = css`
width: 100%;
display: flex;
justify-content: end;
`

const multi_value_section = css`
margin-top: 20px;
`

const row_separator = css`
margin-top: 20px;
`

