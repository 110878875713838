/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/core'
import { head, get } from 'lodash'
import { reset } from 'redux-form'
import { adminUserList } from '../actions/admin_user'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminOutboundEmailList } from '../actions/admin_outbound_email'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'

class AdminOutboundEmails extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminOutboundEmailList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminOutboundEmailList.fetchListIfNeeded())
    }

    getCellValue = (header_key, item, index) => {
        const { customer_id } = this.props
        switch( header_key ) {
            case 'created':
                return <Timestamp value={item.created} format="datetime" />
            case 'status':
                return (
                    <div>
                      { item.status === 'sent' && <InlineIcon icon_name="status-success" /> }
                      { item.status === 'queued' && <InlineIcon icon_name="status-in-progress" /> }
                      { item.status === 'error' && <InlineIcon icon_name="status-error" /> }
                    </div>
                )
            default:
                return undefined
        }
    }

    render() {
        const { is_loading, emails, headers } = this.props
        return (
            <AdminMainLayout title='Outbound emails'
                             breadcrumbs={[{name: 'admin_home'},
                                           {name: 'outbound_emails', label: 'Outbound emails', url: '/admin/outbound_emails'}]}>
              <Container fluid >
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`No emails`}
                    headers={ headers }
                    items={ emails }
                    item_list={adminOutboundEmailList}
                    getCellValue={ this.getCellValue }
                />
              </Container>
            </AdminMainLayout>
            
        )
    }
    
}

function mapStateToProps(state, props) {
    const emails = adminOutboundEmailList.getVisibleObjects()
    return {
        is_loading: adminOutboundEmailList.isLoading(),
        emails,
        headers: {
            id: {name: 'ID'},
            subject: {name: 'Subject'},
            to_address: {name: 'To'},
            from_address: {name: 'From'},
            created: {name: 'Created'},
            status: {name: 'Status'},
            last_error_message: {name: 'Error msg'},
        }
    }
}

export default connect(mapStateToProps)(AdminOutboundEmails)
