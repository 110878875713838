import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'
import moment from 'moment'

class CoursePackageActions extends ItemList {
    getEntityKey() {
        return "customer_package/action"
    }

    
}

export const coursePackageActions = new CoursePackageActions("course_package_actions__default")
