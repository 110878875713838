/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { jsx, css } from '@emotion/core'
import BlueButton from '../layout/BlueButton'
import Question from './Question'
import { customerAnswerList } from '../../actions/customer_answer'
import { coursePackageList } from '../../actions/course_package'

class AssessmentMain extends Component {

    constructor(props) {
        super(props)
        this.state = {active_question_index: null}
    }

    componentDidMount() {
        const { active_question_index } = this.props
        if ( active_question_index !== this.state.active_question_index ) {
            this.setState({active_question_index: active_question_index})
        }
        window.scroll(0, 0)        
    }
    
    onContinue = () => {
        const { onContinue, assessment } = this.props
        onContinue(assessment)
    }

    onAnsweredQuestion = (question, option) => {
        const { dispatch, course_package_id, customer_course, onChangeActiveQuestionIndex } = this.props
        // const active_question = course_section.questions[active_question_index]
        let { active_question_index } = this.state
        const that = this
        dispatch(customerAnswerList.onAnsweredQuestion(customer_course.id, question.id, option.id)).then( function() {
            dispatch(coursePackageList.invalidateObject(course_package_id))
            dispatch(coursePackageList.ensureObjectLoaded(course_package_id))

            if ( question.is_last_question ) {
                that.onContinue()
            } else {
                that.setState({active_question_index: active_question_index+1})
                onChangeActiveQuestionIndex(active_question_index+1)
            }
        })
    }
    
    render() {
        const { course_section } = this.props
        let { active_question_index } = this.state
        const that = this
        let active_question = course_section.questions[active_question_index]
        if ( ! active_question ) {
            active_question_index = 0
            this.setState({active_question_index: active_question_index})
            active_question = course_section.questions[active_question_index]
        }

        const label = (get(active_question, "is_last_question") && "Finish Assessment") || "Next Question"
        
        return (
            <div>
              <Question question={active_question}
                        onAnswered={that.onAnsweredQuestion}
                        select_answer_button_label={label}  />
            </div>
        )
        
        return (
            <div>
              Assessment main
              <BlueButton onClick={this.onContinue}>
                Continue
              </BlueButton>
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    const { onChangeActiveQuestionIndex } = props
    return {
        onChangeActiveQuestionIndex
    }
}
    

export default connect(mapStateToProps)(AssessmentMain)
