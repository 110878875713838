/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { reset } from 'redux-form'
import { head } from 'lodash'
import { sendTokenEmail, updateUser } from '../actions/auth'
import { Separator } from './layout/Separator'
import { loggedInUserManager } from '../actions/logged_in_user'
import { userList } from '../actions/user'
import MainLayout from './MainLayout'
import ProfileForm from './ProfileForm'
import BusyMask from './BusyMask'
import { Container, Col, Row } from 'react-bootstrap'
import { showSuccess } from '../actions/Error'

class Profile extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(userList.invalidateList())
        dispatch(userList.fetchListIfNeeded())
    }
    
    onSave = (values) => {
        const { dispatch, history, user } = this.props
        let on_done = function() {}
        if (values.email !== user.email) {
            const new_email = values.email
            values.email = user.email
            on_done = function() {
                dispatch(loggedInUserManager.invalidateUser())
                history.push('/email/' + new_email)
            }
        } else {
            on_done = function() {
                dispatch(loggedInUserManager.invalidateUser())
                dispatch(loggedInUserManager.ensureUserLoaded())
                showSuccess("Profile", "Profile updated")
            }
        }
        return dispatch(updateUser(values, on_done))
    }

    onRequestToken = () => {
        const { dispatch, user } = this.props
        dispatch(sendTokenEmail(user))
    }
    
    onCancelProfile = () => {
        const { dispatch, history } = this.props
        dispatch(reset('PROFILE_FORM'))
        history.push('/dashboard')
    }
    
    render() {
        const { user, is_loading } = this.props
        return (
            <MainLayout>
              <Container>
                <Row>
                  <Col>
                    { is_loading && <BusyMask /> }
                    <Separator variant="h20" />
                    <div css={container}>

                      <h2>My profile</h2>
                      <hr/>
                      <ProfileForm
                        onSave={ this.onSave }
                        onRequestToken ={ this.onRequestToken }
                        onCancelProfile={ this.onCancelProfile }
                        user_id={ user.id }
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </MainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const user = head(userList.getVisibleObjects()) || {}
    return {
        user,
        is_loading: userList.isLoading()
    }
}
export default connect(mapStateToProps)(Profile)


const container = css`
background: #fff;
padding: 20px;
margin-bottom: 200px;
`
