import { ItemList } from '../../orm'

export class UserList extends ItemList {
    getEntityKey() {
        return "admin/user"
    }

}

export const adminUserList = new UserList("admin_user__default")

class UserListGroupManager extends UserList {
    getEntityKey() {
        return "admin/user/manage_group"
    }

    addToGroup(client_id, user_id, group_id, on_done) {
        return this.saveNewObject({action: 'add', client: client_id, user: user_id, group: group_id}, on_done)
    }    

    removeFromGroup(client_id, user_id, group_id, on_done) {
        return this.saveNewObject({action: 'remove', client: client_id, user:user_id, group: group_id}, on_done)
    }
}

export const adminUserInGroupList = new UserList("admin_user__in_group")
export const adminUserNotInGroupList = new UserList("admin_user__not_in_group")

export const adminUserGroupManagerList = new UserListGroupManager("admin_user__group_manager")
