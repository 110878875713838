/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, isEmpty } from 'lodash'
import moment from 'moment'

import { dashboardList } from '../actions/dashboard'

import MainLayout from './MainLayout'
import DashboardItem from './DashboardItem'
import DashboardItemLarge from './DashboardItemLarge'
import BusyMask from './BusyMask'

import { default_theme as theme } from '../emotion/theme'
import public_dark from '../images/public_outline_dark.png'
import article_dark from '../images/article_add_outline_dark.png'
import publish_dark from '../images/publish_outline_dark.png'
import receipt_dark from '../images/receipt_outline_dark.png'
import payment_dark from '../images/payment_outline_dark.png'
import contact_dark from '../images/contact_outline_dark.png'
import sms_dark from '../images/sms_outline_dark.png'
import notification_dark from '../images/notification_outline_dark.png'
import funds_dark from '../images/funds_outline_dark.png'
import error_dark from '../images/error_outline_dark.png'

class Dashboard extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(dashboardList.invalidateList())
        dispatch(dashboardList.fetchListIfNeeded())
    }

    formatDate = (date) => {
        const ts = moment(date)
        return ts.format('DD MMM YYYY')
    }

    dateExpired = (date) => {
        return moment(date).isBefore(new Date())
    }
    
    render() {
        const { data, is_busy } = this.props
        return (
            <MainLayout title='Dashboard'>
              { is_busy && <BusyMask /> }
              <div css={ dashboard }>
              </div>
            </MainLayout>
        )
    }
}
function mapStateToProps(state) {
    const data = head(dashboardList.getVisibleObjects()) || {}
    return {
        data,
        is_busy: dashboardList.isLoading(),
    }
}
export default connect(mapStateToProps)(Dashboard)

const dashboard = css`
display: flex;
flex-wrap: wrap;
`
const column_container = css`
display: flex;
flex-direction: column;
justify-content: flex-start;
align-items: center;
`
