import { ItemList } from '../orm'
import { map, get, head } from 'lodash'

class CustomerReportList extends ItemList {
    getEntityKey() {
        return "customer_report/report_data"
    }

    loadCustomerReportIfNeeded() {
        const that = this
        return async (dispatch, getState) => {
            const filter = this.getFilter()
            dispatch(that.fetchListIfNeeded())
        }
    }

    getCustomerReport() {
        return head(this.getVisibleObjects())
    }
    
}

export const customerReportList = new CustomerReportList("customer_report__default")
