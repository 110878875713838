import { ItemList } from '../orm'
import { map, get, head } from 'lodash'

class AggregatedReportCustomerCourseList extends ItemList {
    getEntityKey() {
        return "aggregated_report_customer_course/report_data"
    }

    setClientGroup(client_group_id) {
        return this.updateListFilter({client_group: client_group_id})
    }
        
    
}

export const aggregatedReportCustomerCourseList = new AggregatedReportCustomerCourseList("aggregated_report_customer_course__default")
