/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import BlueButton from '../layout/BlueButton'
import DocumentPlayer from '../DocumentPlayer'

class DocumentMain extends Component {

    componentDidMount() {
        window.scroll(0, 0)        
    }
    
    onContinue = () => {
        const { onContinue, document } = this.props
        onContinue(document)
    }
    
    render() {
        const { document } = this.props
        return (
            <div>
              <DocumentPlayer ref={document.id}
                              document={document}
                              onDocumentFinished={this.onContinue}
              />
            </div>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {}
}
    

export default connect(mapStateToProps)(DocumentMain)
