/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, map, size } from 'lodash'
import Timestamp from '../../components/Timestamp'
import { adminCourseList } from '../actions/admin_course'
import { adminCourseSectionList } from '../actions/admin_course_section'
import { adminCourseSectionQuestionList } from '../actions/admin_course_section_question'
import { adminCourseSectionQuestionOptionList } from '../actions/admin_course_section_question_option'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminCourseSectionQuestionOptionForm from './AdminCourseSectionQuestionOptionForm'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {NotificationManager} from 'react-notifications'
import Card from '../../components/layout/Card'
import { Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'

class AdminCourseSectionQuestionOption extends Component {

    componentDidMount() {
        this.refresh(null)
    }

    componentDidUpdate(prev_props) {
        this.refresh(prev_props)
    }

    refresh(prev_props) {
        const { dispatch,
                course_section_question_option_id,
                course_section_question_id,
                course_section_id,
                course_id } = this.props
        
        if ( course_section_question_option_id && (!prev_props || course_section_question_option_id !== prev_props.course_section_question_option_id) ) {
            dispatch(adminCourseSectionQuestionOptionList.ensureObjectLoaded(course_section_question_option_id))
        }
        if ( course_section_question_id && (!prev_props || course_section_question_id !== prev_props.course_section_question_id) ) {
            dispatch(adminCourseSectionQuestionList.ensureObjectLoaded(course_section_question_id))
        }
        if ( course_section_id && (!prev_props || course_section_id !== prev_props.course_section_id) ) {
            dispatch(adminCourseSectionList.ensureObjectLoaded(course_section_id))
        }
        if ( course_id && (!prev_props || course_id !== prev_props.course_id) ) {
            dispatch(adminCourseList.ensureObjectLoaded(course_id))
        }
    }

    onSubmit = (values) => {
        const { dispatch, course_section_question_option_id, history } = this.props
        const that = this
        values.active = values.status ? values.status.value : true
        const on_done = function(json) {
            NotificationManager.success("Saved", "Option saved")
            if ( ! course_section_question_option_id ) {
                history.push(`/admin/course_section_question_option/${json.id}`)
            }
        }
        if ( course_section_question_option_id ) {
            values.id = course_section_question_option_id
            return dispatch(adminCourseSectionQuestionOptionList.saveObject(values, on_done))
        } else {
            return dispatch(adminCourseSectionQuestionOptionList.saveNewObject(values, on_done))
        }
    }

    render() {
        const { course_section_question_option_id, course_section_question,
                course_section,
                is_busy, is_loading, page, breadcrumbs } = this.props
        const title = course_section_question_option_id ? 'Edit Question' : 'Add Question'

        return (
            <AdminMainLayout title={title} breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              <Row>
                <Col>
                  <AdminCourseSectionQuestionOptionForm
                      onSubmit={ this.onSubmit }
                      course_section_question_option_id={ course_section_question_option_id }
                  />
                </Col>
              </Row>
              { course_section_question && 
                <div>Last modified at <Timestamp value={course_section_question.modified_at} /></div>
              }
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const course_section_question_option_id = get(props, ["match", "params", "course_section_question_option_id"], null)
    const course_section_question_option = adminCourseSectionQuestionOptionList.getObject(course_section_question_option_id)
    const course_section_question_id = get(course_section_question_option, "course_section_question")
    const course_section_question = course_section_question_id && adminCourseSectionQuestionList.getObject(course_section_question_id)
    const course_section_id = get(course_section_question, "course_section")
    const course_section = course_section_id && adminCourseSectionList.getObject(course_section_id)
    const course_id = get(course_section, "course")
    const course = course_id && adminCourseList.getObject(course_id)
    const breadcrumbs = [ {label: course && course.name,
                           url: course &&`/admin/course/${course_id}`},
                          {label: course_section && course_section.name,
                           url: `/admin/course_section/${course_section_id}`},
                          {label: course_section_question && course_section_question.text,
                           url: `/admin/course_section_question/${course_section_question_id}`},
                          {label: course_section_question_option && course_section_question_option.text,
                           url: `/admin/course_section_question_option/${course_section_question_option_id}`}]
    return {
        course_section_question_option_id,
        course_section_question_option,
        course_section_question_id,
        course_section_id,
        course_id,
        breadcrumbs,
        is_loading: adminCourseSectionList.isLoading(),
        is_busy: adminCourseSectionList.getIsSavingObject()
    }
}
export default connect(mapStateToProps)(AdminCourseSectionQuestionOption)

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`
