/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { map, get, size, filter, keys } from 'lodash'
import { adminCourseSectionQuestionList } from '../actions/admin_course_section_question'
import InputField from '../../components/form/InputField'
import DefaultButton from '../../components/DefaultButton'
import { FormikDropdownField } from '../../components/Dropdown'
import Timestamp from '../../components/Timestamp'
import AdminCreationFooter from './AdminCreationFooter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikTextarea } from '../../components/form/TextareaField'
import { default_theme as theme } from '../../emotion/theme'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Col, Row, Container, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormFieldWithAction } from '../../components/form/FormFieldWithAction'
import { FormikFileUploadField } from '../../components/form/FileUploader'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'text': Yup.string().required("Text is required"),
})

class AdminCourseSectionQuestionForm extends Component {
    
    componentDidMount() {
        const { dispatch, course_section_question_id } = this.props
        dispatch(adminCourseSectionQuestionList.ensureObjectLoaded(course_section_question_id))
    }

    onSave = (values, setSubmitting, setFieldError) => {
        const { onSubmit } = this.props
        onSubmit(values).then( (res) => {
            setSubmitting(false)
            if ( res.errors ) {
                map(keys(res.errors), (field_name) => setFieldError(field_name, res.errors[field_name]))
            }
        })
    }

    render() {
        const { onCancel,
                error,
                course,
                initialValues
        } = this.props
 
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting, setFieldError }) => {this.onSave(values, setSubmitting, setFieldError)}}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
              {formik_props => {
                   const { values } = formik_props
                   return (
                       <Form>
                         <Row>
                           <Col>
                             <Card title="Question Details">
                               <Row>
                                 <Col>
                                   <FormLabelValue>
                                     Text
                                     <FormikInputField
                                         name="text"
                                         type="text"
                                         placeholder="Enter question text"
                                     />
                                   </FormLabelValue>

                                   <FormLabelValue>
                                     Sequence
                                     <FormikInputField name="sequence"
                                                       type="text"
                                                       placeholder="Sequence"
                                     />
                                   </FormLabelValue>

                                   { false && 
                                     <FormLabelValue>
                                       Score
                                       <FormikInputField name="score"
                                                         type="text"
                                                         placeholder="Score"
                                       />
                                     </FormLabelValue>
                                   }
                                   
                                 </Col>
                               </Row>
                             </Card>
                           </Col>
                         </Row>
                         <Row>
                           <Col>
                             <Button type="submit">
                               Save
                             </Button>
                           </Col>
                         </Row>
                       </Form>
                   )}
              }
            </Formik>
        )
    } 
}

function mapStateToProps(state, props) {
    const { onSubmit, course_section_question_id, onCancel } = props
    let course_section_question = adminCourseSectionQuestionList.getObject(course_section_question_id) || {}
    return {
        onSubmit,
        onCancel,
        course_section_question,
        initialValues: course_section_question
    }
}
export default withRouter(connect(mapStateToProps)(AdminCourseSectionQuestionForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
margin: 10px;
padding: 10px;
@media(max-width: 1300px) {
align-items: center;
}
`

const row_container = css`
width: 50%;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
@media(max-width: 1300px) {
    width: 700px;
}
`

const column_container = css`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
width: 100%;
@media(max-width: 1300px) {
    flex-wrap: nowrap;
    height: auto;
}
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
padding-left: 30px;
padding-right: 30px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: column;
width: 100%;
`

const footer_row = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const row = css`
display: flex; 
justify-content: space-between;
`

const cell = css`
width: 45%;
`

const label_center = css`
color: ${theme.colors.secondary};
font-weight: 600;
text-align: center;
width: 100%;
`

const multi_value_row = css`
display: flex;
justify-content: space-between;
margin: 10px;
padding: 10px;
box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
`

const button_group = css`
width: 100%;
display: flex;
justify-content: end;
`

const multi_value_section = css`
margin-top: 20px;
`

const row_separator = css`
margin-top: 20px;
`

