import { ItemList } from '../../orm'

class AdminClientGroupList extends ItemList {
    getEntityKey() {
        return 'admin/client_group'
    }

}

export const adminClientGroupList = new AdminClientGroupList('admin_client_group__default')
