/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { FormattedNumber } from '../FormattedNumber'
import { get, size } from 'lodash'
import { Link } from 'react-router-dom'
import MainLayout from '../MainLayout'
import { Container, Col, Row, Popover, OverlayTrigger } from 'react-bootstrap'
import { Separator } from '../layout/Separator'
import { RadialBarChart, RadialBar, Legend, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { customerReportList } from '../../actions/customer_report'
import CommonTable from '../CommonTable'
import WrappingBusyMask from '../WrappingBusyMask'
import { loggedInUserManager } from '../../actions/logged_in_user'
import BlueButton from '../layout/BlueButton'
import Timestamp from '../Timestamp'

class UserReport extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(loggedInUserManager.ensureUserLoaded())
        dispatch(customerReportList.loadCustomerReportIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(customerReportList.loadCustomerReportIfNeeded())
    }

    renderTotals() {
        const { report } = this.props
        return (
            <Row>
              <Col md="4">

                <div css={card_style}>
                  <div css={card_header_style}>
                    Assigned Courses
                  </div>
                  <div css={[result_style, result_blue_style]}>
                    {report.num_assigned_courses }
                  </div>
                </div>
                
              </Col>
              <Col md="4">
                <div css={card_style}>
                  <div css={card_header_style}>
                    Completed Courses
                  </div>
                  <div css={[result_style, result_green_style]}>
                    {report.num_complete_courses }
                  </div>
                </div>
              </Col>
              <Col md="4">
                <div css={card_style}>
                  <div css={card_header_style}>
                    Incomplete Courses
                  </div>
                  <div css={[result_style, result_red_style]}>
                    {report.num_incomplete_courses }
                  </div>
                </div>

              </Col>
            </Row>

        )
    }

    getCourseCellValue = (header_key, item, index, row_index) => {
        switch( header_key ) {
            case 'start':
                return <Timestamp value={item.started_at} format='date' />
            case 'end':
                return <Timestamp value={item.completed_at} format='date' />
            case 'score':
            return <div css={item.score_percentage === 100 ? result_green_style : result_red_style} ><FormattedNumber value={item.score_percentage} num_digits={2} />%</div>
            case 'progress':
                return <div css={item.progress_percentage === 100 ? result_green_style : result_red_style} ><FormattedNumber value={item.progress_percentage} num_digits={2} />%</div>
            default:
                return undefined
        }
    }

    renderCourses() {
        const { report, flattened_courses, headers, is_loading } = this.props
        return (
            <div css={card_style}>
              <CommonTable
                is_loading={ is_loading }
                empty_message="There are no course results."
                headers={ headers }
                items={ flattened_courses }
                getCellValue={ this.getCourseCellValue }
              />
            </div>
        )
    }
    
    render() {
        const { is_loading, customer } = this.props
        return (
            <MainLayout>

              <WrappingBusyMask is_loading={is_loading}>

                { ! is_loading &&
                  <Container fluid  css={container_style}>
                    <div css={header_style}>
                      User Report for {get(customer, "display_name")}
                    </div>

                    { this.renderTotals() }
                    
                    <Separator variant="h50" />

                    { this.renderCourses() }

                    <Separator variant="h100" />
                    
                  </Container>
                }
              </WrappingBusyMask>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const customer = loggedInUserManager.getUser()
    const report = customerReportList.getCustomerReport()
    return {
        customer,
        report,
        is_loading: !report || customerReportList.isLoading(),
        headers: {
            package_name: {name: 'Course name'},
            progress: {name: 'Progress'},
            start: {name: 'Start'},
            end: {name: 'End'},
            score: {name: 'Score'}
        },
        flattened_courses: report && report.flattened_courses
    }
}
export default connect(mapStateToProps)(UserReport)

const container_style = css`
background-color: #e6e8e3;
padding-left: 50px;
padding-right: 50px;
`

const header_style = css`
background-color: #e6e8e3;
font-size: 30px;
text-align: center;
color: #575853;
padding-top: 40px;
padding-bottom: 30px;
display: flex;
justify-content: center;
`

const card_header_style = css`
font-size: 30px;
text-align: center;
color: #575853;
margin-top: 40px;
margin-bottom: 30px;
`

const card_style = css`
background: white;
border-radius: 4px;
text-align: center;
height: 350px;
padding-bottom: 20px;
padding-top: 20px;
padding-left: 20px;
padding-right: 20px;
`

const result_style = css`
font-weight: bold;
font-size: 80px;
text-align: center;
flex-grow: 1;
`

const result_green_style = css`
color: #3aab47;
`

const result_blue_style = css`
color: #63b3d7;
`

const result_red_style = css`
color: #c24e4b;
`
