/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { size, map } from 'lodash'
import { default_theme as theme } from '../emotion/theme'

class FormError extends Component {
    render() {

        const { meta } = this.props

        if ( !meta || !meta.error || !meta.touched ) {
            return null
        }
        
        return (
            <div css={error_class}>{meta.error}</div>
        )
        
    }
}
export default FormError

const error_class = css`
  color: ${theme.colors.error}
}
`
