/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx } from '@emotion/core'
import {NotificationManager} from 'react-notifications'
import { head, get } from 'lodash'
import { reset } from 'redux-form'
import { adminUserList } from '../actions/admin_user'
import { adminClientList } from '../actions/admin_client'
import { adminCourseList } from '../actions/admin_course'
import DefaultButton from '../../components/DefaultButton'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'

class AdminUser extends Component {

    componentDidMount() {
        const { dispatch, user_id, client_id } = this.props
        if ( user_id ) {
            dispatch(adminUserList.ensureObjectLoaded(user_id))
        }
        dispatch(adminClientList.ensureObjectLoaded(client_id))
    }
    
    handleSubmit = (values, setSubmitting, setFieldError) => {
        const { dispatch, history, client_id } = this.props

        if (!values.id) {
            values.isNewInstance = true
        }
        values.client = client_id
        values.notification_method = values.notification_method ? 'push_notification' : 'text_message'
        const on_done = function() {
            dispatch(adminClientList.invalidateObject(client_id))
            dispatch(adminUserList.invalidateList())
            NotificationManager.success("Saved", "User saved")
            history.push(`/admin/client/${client_id}/users`)
        }
        return dispatch(adminUserList.saveObject(values, on_done))
    }

    onCancelUser = () => {
        const { dispatch, client_id, history } = this.props
        dispatch(reset('USER_FORM'))
        history.push(`/admin/client/${client_id}/users`)
    }

    onResetCoursesForUser = () => {
        const { dispatch, user_id, history } = this.props
        if ( ! window.confirm("WARNING: This is a testing function and cannot be undone! \n\nAre you sure you want to delete all course history for this user?") ) {
            return false
        }
        const on_done = function() {
            NotificationManager.success("Reset", "Courses reset")
        }
        return dispatch(adminCourseList.resetUserCourseAnswers(user_id, on_done))
    }
    
    render() {
        const { user_id, client, is_busy, breadcrumbs } = this.props
        let title = `Add User to ${get(client, "name")}`
        if (user_id) {
            title = `Edit User for ${get(client, "name")}`
        }
        return (
            <AdminMainLayout title={ title } layout="single" breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              <AdminUserForm
                  onSubmit={ this.handleSubmit }
                  onCancelUser={ this.onCancelUser }
                  user_id={ user_id }
              />
              <div>
                <DefaultButton
                  label="Reset courses"
                  type="button"
                  onClickButton={ this.onResetCoursesForUser }
                />
              </div>
              
            </AdminMainLayout>
        )
    }
}
function mapStateToProps(state, props) {
    const client_id = get(props, ["match", "params", "client_id"], null)
    const user_id = get(props, ["match", "params", "user_id"], null)
    const user = user_id && adminUserList.getObject(user_id) || {}
    const client = client_id && adminClientList.getObject(client_id)

    const breadcrumbs = [ {label: "Clients",
                           url: client &&`/admin/clients`},
                          {label: client && client.name,
                           url: client &&`/admin/client/${client_id}`},
                          {label: "Users",
                           url: client &&`/admin/client/${client_id}/users`},
                          {label: (user_id && get(user, "display_name")) || "New user",
                           url: client &&`/admin/client/${client_id}/user/${user_id}`},
                        ]
    return {
        user_id,
        client_id,
        client,
        user,
        is_busy: adminUserList.isLoading() || adminUserList.getIsSavingObject(),
        breadcrumbs
    }
}
export default connect(mapStateToProps)(AdminUser)

