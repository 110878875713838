/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { default_theme as theme } from '../../emotion/theme'
import { size } from 'lodash'
import { jsx, css } from '@emotion/core'
import { CourseCardOverlay } from './CourseCardOverlay'
import { CourseBannerContainer } from './CourseBannerContainer'
import Pluralize from 'react-pluralize'
import BlueButton from '../layout/BlueButton'
import { faPlayCircle } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Separator } from '../layout/Separator'
import rocket_img from '../../images/start_assessment.png'
import BusyMask from '../BusyMask'

class DocumentIntro extends Component {

    componentDidMount() {
        window.scroll(0, 0)        
    }
    
    onContinue = () => {
        const { onContinue, document } = this.props
        onContinue(document)
    }
    
    render() {
        const { course_section, document, course } = this.props

        if (! course.banner_image.original_download_url ) {
            return <BusyMask loading={true} />
        }
        
        return (
            <CourseBannerContainer>
              <div css={ [course_banner_image_container, css`{background-image: url(${course.banner_image.original_download_url})}`] }>
                &nbsp;
              </div>
              <CourseCardOverlay>
                <div css={rocket_icon_style}>
                  <img css={rocket_icon_img_style} src={rocket_img} />
                </div>
                <div css={banner_header_style}>
                  START
                </div>

                <Separator variant="h30" />
                
                <div css={banner_blurb_style}>
                  Please follow this document presentation carefully, it will form the basis for assessments to follow.
                  Select START to continue.
                </div>

                <Separator variant="h30" />
                
                <div>
                  <BlueButton onClick={this.onContinue} css={start_learning_button} >
                    START
                  </BlueButton>
                </div>
              </CourseCardOverlay>
            </CourseBannerContainer>
        )
    }
    
}

function mapStateToProps(state, props) {
    return {}
}
    

export default connect(mapStateToProps)(DocumentIntro)


const course_banner_image_container = css`
filter: blur(8px);
-webkit-filter: blur(8px);
position: absolute;
top: 0px;
left: 0px;
width: 100%;
height: 100%;
object-fit: cover;
object-position: 0 0;
`

const rocket_icon_style = css`
font-size: 15px;
`

const rocket_icon_img_style = css`
height: 70px;
`

const banner_header_style = css`
font-size: 28px;
`

const banner_blurb_style = css`
text-align: center;
`

const banner_info_style = css`
display: flex;
justify-content: center;
`

const icon_style = css`
width: 15px;
margin-right: 5px;
display: flex;
justify-content: center;
`

const info_icon = css`
margin-right: 5px;
`

const info_detail_item_style = css`
display: flex;
align-items: center;
`

const start_learning_button = css`
font-size: 20px;
padding-left: 24px;
padding-right: 24px;
height: 50px;
&:hover {
}
`
