/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, has } from 'lodash'

import { adminDashboardList } from '../actions/admin_dashboard'

import AdminMainLayout from './AdminMainLayout'
import DashboardItem from '../../components/DashboardItem'
import BusyMask from '../../components/BusyMask'
import TableHeader from '../../components/TableHeader'
import TableFooter from '../../components/TableFooter'
import Pagination from '../../components/Pagination'
import CommonTable from '../../components/CommonTable'
import Timestamp from '../../components/Timestamp'
import TableFilter from '../../components/TableFilter'

import { default_theme as theme } from '../../emotion/theme'
import content_dark from '../../images/content_dark.png'
import active_dark from '../../images/active_dark.png'
import news_dark from '../../images/news_dark.png'
import inactive_dark from '../../images/inactive_dark.png'
import send_dark from '../../images/send_dark.png'
import sms_dark from '../../images/sms_outline_dark.png'
import notification_dark from '../../images/notification_outline_dark.png'
import { Container, Row, Col } from 'react-bootstrap'

class AdminDashboard extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(adminDashboardList.invalidateList())
        dispatch(adminDashboardList.fetchListIfNeeded())
    }

    render() {
        const {
            data,
            is_busy,
        } = this.props

        return (
            <AdminMainLayout title='Admin Dashboard'>
              { is_busy && <BusyMask /> }
              <Container>
                <Row>
                  <Col>
                    <DashboardItem background_colour={ theme.colors.logo_3 }
                                   onClickUrl="/admin/clients"
                                   icon={ content_dark }
                                   stat={ data.clients }
                                   text={ "Clients" }
                    />
                  </Col>
                  <Col>
                    <DashboardItem background_colour={ theme.colors.logo_2 }
                                   onClickUrl="/admin/courses"
                                   icon={ news_dark }
                                   stat={ data.courses }
                                   text={ "Course Templates" }
                    />
                  </Col>
                </Row>
              </Container>
            </AdminMainLayout>
        )
    }
}
function mapStateToProps(state) {
    const data = head(adminDashboardList.getVisibleObjects()) || {}
    return {
        data,
        is_busy: adminDashboardList.isLoading(),
    }
}
export default connect(mapStateToProps)(AdminDashboard)

const dashboard = css`
display: flex;
flex-wrap: wrap;
justify-content: center;
`

const column_container = css`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: center;
flex-wrap: wrap;
`

const row_container = css`
display: flex;
flex-direction: row;
justify-content: flex-start;
align-items: flex-start;
`

const pagination_container = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const container = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: flex-start;
margin-bottom: 10px;
min-width: 680px;
max-width: 750px;
padding: 20px;
`

const label = css`
padding-top: 10px;
color: white;
font-weight: 600;
font-size: 18px;
`

