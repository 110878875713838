import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router } from 'react-router-dom';
import { Provider } from 'react-redux'

import MainRouter from './components/MainRouter'
import configureStore from './store/configureStore'
import { syncORMWithStore } from './orm'
import 'bootstrap/dist/css/bootstrap.min.css'

export const store = configureStore({})
syncORMWithStore(store)

class App extends Component {
    render() {
        return (
            <Provider store={store}>
              <Router>
                <MainRouter />
              </Router>
            </Provider>
        );
    }
}
ReactDOM.render(<App />, document.getElementById('root'));
