/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Col, Row, Container, Button } from 'react-bootstrap'
import { map, keys } from 'lodash'
import { NotificationManager } from 'react-notifications'
import * as Yup from 'yup'

import ReactPortal from '../../components/ReactPortal'
import { FormikFileUploadField } from '../../components/form/FileUploader'
import Card from '../../components/layout/Card'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { fileImportList } from '../actions/admin_file_import'
import { adminUserList } from '../actions/admin_user'
import { adminClientGroupList } from '../actions/admin_client_group'
import { adminClientList } from '../actions/admin_client'
import DefaultButton from '../../components/DefaultButton'


const validationSchema = Yup.object().shape({
    'file_import': Yup.string().required("File is required")
})
class AdminUsersFileImport extends Component {

    onSave = (values, setSubmitting, setFieldError) => {
        const { onCloseModal, dispatch } = this.props
        this.onSubmit(values).then( (res) => {
            setSubmitting(false)
            if ( res.errors ) {
                map(keys(res.errors), (field_name) => setFieldError(field_name, res.errors[field_name]))
            } else if (res.status === 'success') {
                NotificationManager.success("Success", "Users imported")
                dispatch(adminUserList.invalidateList())
                dispatch(adminClientGroupList.invalidateList())
                dispatch(adminClientList.invalidateList())
                onCloseModal()
            }
        })
    }

    onSubmit = (values) => {
        const { client_id, dispatch } = this.props
        values.client_id = client_id
        return dispatch(fileImportList.process(values))
    }
    
    render() {
        const {
            client_id,
            onCloseModal,
        } = this.props

        return (
            <ReactPortal>
              <div css={ [main] }>
                <div css={ [container] }>
                  <Formik
                      onSubmit={(values, { setSubmitting, setFieldError }) => {this.onSave(values, setSubmitting, setFieldError)}}
                      enableReinitialize={true}
                      initialValues={{}}
                      validationSchema={validationSchema}
                  >
                    {formik_props => {
                        const { values } = formik_props
                        return (
                            <Form>
                              <Row>
                                <Col>
                                  <Card title="Import Users">
                                    <Row>
                                      <Col>
                                        <FormLabelValue>
                                          Users import sheet
                                          <FormikFileUploadField name="file_import"
                                                                 upload_relative_url='file_import/'
                                                                 file_types=".csv, .xls, .xlsx"
                                                                 formik_props={formik_props}
                                          />
                                        </FormLabelValue>
                                      </Col>
                                    </Row>
                                  </Card>
                                </Col>
                              </Row>
                              
                              <Row>
                                <Col css={ css`display:flex;justify-content:space-between` }>
                                  <Button type="submit">
                                    Save
                                  </Button>
                                  <DefaultButton
                                      label="Cancel"
                                      type="button"
                                      light={ true }
                                      onClickButton={ onCloseModal }
                                  />
                                </Col>
                              </Row>
                            </Form>
                        )
                    }}
                  </Formik>
                </div>
              </div>
            </ReactPortal>
        )
    }
}

function mapStateToProps(state, props) {
    const { client_id, onCloseModal, onActionModal } = props

    return {
        client_id,
        onCloseModal,
    }
}
export default withRouter(connect(mapStateToProps)(AdminUsersFileImport))

const main = css`
    position: fixed;
    z-index: 1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.4);
`

const container = css`
    position: relative;
    display: flex;
    flex: 1;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    margin: 5% auto;
    width: 500px;
    border: 1px solid #888;
    border-radius: 5px;
    background-color: #FFFFFF;
    padding: 2rem;
`
