import { ItemList } from '../../orm'
import { map, size } from 'lodash'
import { http } from '../../lib'
import { UserList } from './admin_user'

class AdminCoursePackageUser extends ItemList {
    getEntityKey() {
        return "admin/course_package/manage_course_package_user"
    }

    addGroupToCoursePackage(client_id, group_id, course_package_id, on_done) {
        return this.saveNewObject({action: 'add_group', client: client_id, group: group_id, course_package: course_package_id}, on_done)
    }
    
    addUserToCoursePackage(client_id, user_id, course_package_id, on_done) {
        return this.saveNewObject({action: 'add', client: client_id, user: user_id, course_package: course_package_id}, on_done)
    }    

    removeUserFromCoursePackage(client_id, user_id, course_package_id, on_done) {
        return this.saveNewObject({action: 'remove', client: client_id, user: user_id, course_package: course_package_id}, on_done)
    }
}

export const adminUserInCoursePackageList = new UserList("admin_user__in_course_package")
export const adminUserNotInCoursePackageList = new UserList("admin_user__not_in_course_package")
export const adminUserCoursePackageManagerList = new AdminCoursePackageUser("admin_user__course_package_manager")
