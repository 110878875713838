/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { useField } from 'formik'
import { default_theme as theme } from '../../emotion/theme'
import FormError from './FormError'
import FormWarning from './FormWarning'
import Form from 'react-bootstrap/Form'

export const FormikInputField = ({ label, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props)
    const className = (meta && meta.touched && meta.error && error_class) || null
    return (
        <div>
          <Form.Control {...field} {...props} css={className}>
          </Form.Control>
          <FormError meta={meta} />
          <FormWarning meta={meta} />
        </div>
          
    )
}

const error_class = css`
  border: 1px solid ${theme.colors.error};
`


// ////////////////////////// OLD FIELD (redux-form) ///////////////////

class InputField extends Component {
    
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            type,
            tabIndex,
            maxLength,
            meta,
            ...extraProps
        } = this.props
        
        return (
            <div css={container}>
              
              <input {...input}
                     {...extraProps}
                     placeholder={placeholder}
                     css={[extra_class]}
                     type={type}
                     maxLength={maxLength}
                     className="form-control"
              />
              { meta.error && meta.touched ?
                <FormError meta={ meta } />
                :
                <FormWarning meta={ meta } />
              }
            </div>
        )
    }
}

export default InputField

const container = css`
width: 100%;
`

const textarea_style = css`
width: 100%;
`
