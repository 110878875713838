/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { useField } from 'formik'
import { default_theme as theme } from '../../emotion/theme'
import FormError from './FormError'
import FormWarning from './FormWarning'
import Form from 'react-bootstrap/Form'

export const FormikCheckboxField = ({ label, ...props }) => {
    const [field, meta] = useField(props)

    return (
        <div css={container}>
          <label css={switch_class}>
            <input css={switch_input_class}
                   checked={field.value}
                   type="checkbox"
                   {...field}
                   {...props}
                   label={label} />
            <span css={slider_class} css={[slider_class, field.value === true ? slider_class__checked : slider_class__unchecked ]} />
            <span css={label_style}>{label}</span>
          </label>
          <FormError meta={meta} />
          <FormWarning meta={meta} />
        </div>
          
    )
}

const container = css`
  width: 100%;
`

const switch_class = css`
  position: relative;
  display: inline-block;
  width: 30px;
  height: 20px;
`

const switch_input_class = css`
  opacity: 0;
  width: 0;
  height: 0;
`

const slider_class = css`
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 3px;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 15px;

  :before {
    position: absolute;
    content: "";
    height: 13px;
    width: 13px;
    left: 2px;
    bottom: 2px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

`

const slider_class__checked = css`
  background-color: green;

  :before {
  -webkit-transform: translateX(13px);
  -ms-transform: translateX(13px);
  transform: translateX(13px);
  }
`

const slider_class__unchecked = css`
  box-shadow: 0 0 1px #2196F3;

`

const label_style = css`
margin-left: 10px;
position: absolute;
left: 30px;
bottom: 0px;
cursor: pointer;
`


// /////////////////////////////

export default class CheckboxField extends Component {

    onChange = (e) => {
        const { input: { onChange } } = this.props
        onChange(e.target.checked)
    }

    render() {
        const { value, label, disabled } = this.props
        return (
            <div>
              <div>
                <label css={[old_label_style, disabled && checkbox_disabled]}>{label}
                  <input
                    onChange={ this.onChange }
                    type="checkbox"
                    css={main}
                    {...this.props}
                  />
                </label>
              </div>
            </div>
        )
    }
}

const main = css`

margin-left: 10px;
:disabled {
    cursor: not-allowed;
    
    label {
      color: ${theme.colors.gray1};
    }

}
`

const old_label_style = css`
cursor: pointer;
`

const checkbox_disabled = css`
    cursor: not-allowed;
    color: ${theme.colors.gray4};
`
