import { ItemList } from '../../orm'
import { has, union, map } from 'lodash'
import { http } from '../../lib'

class AdminCourseSectionList extends ItemList {
    getEntityKey() {
        return 'admin/course_section'
    }

}

export const adminCourseSectionList = new AdminCourseSectionList('admin_course_section__default')
