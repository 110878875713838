import { ItemList } from '../../orm'
import { has, union, map } from 'lodash'
import { http } from '../../lib'

class AdminCourseList extends ItemList {
    getEntityKey() {
        return 'admin/course'
    }

    resetUserCourseAnswers(user_id, on_done) {
        return this.saveNewObject({name: 'irrelevant', user_id: user_id, action: 'reset_course_answers_for_user'}, on_done)
    }

}

class AdminCourseCopier extends ItemList {
    getEntityKey() {
        return 'admin/course/copy'
    }

    copy({course_id, on_done}) {
        return this.saveNewObject({clone_of_course: course_id}, on_done)
    }
}

export const adminCourseList = new AdminCourseList('admin_course__default')
export const adminCourseCopier = new AdminCourseCopier('admin_course_copier__default')
