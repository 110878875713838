/** @jsx jsx */
import { Component } from 'react'
import { Separator } from '../layout/Separator'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../../emotion/theme'

export default class SummaryAssessmentScore extends Component {
    
    render() {
        const { score_percentage, extra_css } = this.props
        return (
            <div>
              <div css={[section_summary__score, extra_css]}>
                {score_percentage ? Math.floor(score_percentage) : 0 }%
              </div>
              <Separator variant="h30" />
            </div>
        )
    }
}

const section_summary__score = css(theme.mq({
    color: "#fff",
    width: "100%",
    fontSize: ["90px", "70px"],
    lineHeight: ["90px", "70px"],
    height: ["130px", "100px"],
    textAlign: "center",
    fontWeight: "700",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
}))

