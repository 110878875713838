/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'

import { default_theme as theme } from '../emotion/theme'

class TableHeader extends Component {

    render() {
        const { children, custom_height, custom_background } = this.props
        return (
            <div css={ [header,
                        custom_height ? css`height:${custom_height};` : undefined,
                        custom_background ? css`background:${custom_background};` : undefined,
            ] } >
              { children }
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { children } = props
    return {
        children
    }
}
export default withRouter(connect(mapStateToProps)(TableHeader))

const header = css`
display: flex;
flex-direction: row;
justify-content: space-between;
align-items: center;
color: #0;
height: 70px;
width: 100%;
border-top-left-radius: 10px;
border-top-right-radius: 10px;
padding-right: 15px;
padding-left: 15px;
`
