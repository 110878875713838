/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { map } from 'lodash'
import { jsx, css } from '@emotion/core'
import { Button } from 'react-bootstrap'
import { default_theme as theme } from '../../emotion/theme'
import { clientManager } from '../../actions/client_manager'

const OPTION_NUMBERS = { 0: "A", 1: "B", 2: "C", 3: "D", 4: "E" }

class Question extends Component {

    constructor(props) {
        super(props)
        this.state = {selected_option: null}
    }

    componentDidUpdate(prev_props) {
        if ( prev_props.question != this.props.question ) {
            this.setState({selected_option: null})
        }
    }
    
    onSelectOption = (option) => {
        this.setState({selected_option: option})
    }

    onAnswered = () => {
        const { question, onAnswered } = this.props
        const { selected_option } = this.state
        onAnswered(question, selected_option)
    }

    renderNextButton() {
        const { select_answer_button_label } = this.props
        const client_next_question_button_style = css`
border: 1px solid ${clientManager.getPrimaryColour()};
background-color: ${clientManager.getPrimaryColour()};
  &:hover {
    background-color: ${clientManager.getPrimaryColour()};
  }
`
        return (
            <Button css={[next_question_button_style, client_next_question_button_style]} onClick={this.onAnswered}>
              {select_answer_button_label}
            </Button>
        )
    }
    
    renderOption(option, index) {
        const { selected_option } = this.state
        const is_selected = selected_option && selected_option.id === option.id

        const client_selected_option_style = css`
  background-color: ${clientManager.getPrimaryColour()};
`
        
        return (
            <div css={[option_style,
                       (is_selected && selected_option_style) || null,
                       (is_selected && client_selected_option_style) || null,
                      ]}
                 key={index}
                 onClick={() => this.onSelectOption(option)}>
              <div css={option_number}>{OPTION_NUMBERS[index]}</div>
              <div css={option_text}>
                {option.text}
              </div>
            </div>
        )
    }
    
    render() {
        const { question } = this.props
        const { selected_option } = this.state
        return (
            <div css={container}>
              <div css={question_and_answer_container}>
                <div css={question_style}>
                  {question.text}
                </div>

                <div css={answer_container}>
                  { map(question.options, (option, index) => this.renderOption(option, index)) }
                </div>
              </div>
              
              { selected_option && this.renderNextButton() }
              
            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { question } = props
    return {
        question
    }
}

export default connect(mapStateToProps)(Question)

const container = css`
padding: 20px 20px;
`

const question_and_answer_container = css`

`

const question_style = css`
font-size: 27px;
margin-bottom: 10px;
`

const answer_container = css(theme.mq({
    width: ["50%;", "100%"]
}))

const option_style = css`
  box-shadow: 0px 0px 1px 0px rgba(0,0,0,0.75);
  font-size: 15px;
  font-weight: 500;
  padding: 15px;
  display: flex;
  margin-bottom: 5px;
  background: #fff;
  cursor: pointer;
`

const selected_option_style = css`
  color: #fff;
`

const option_number = css`
  font-size: 16px;
  margin-right: 5px;
  font-weight: 600;
`

const option_text = css`
`

const next_question_button_style = css`
  border-radius: 0px;
  color: #fff;
  padding-bottom: 10px;
  padding-left: 24px;
  padding-top: 10px;
  padding-right: 24px;
  font-size: 18px;

`
