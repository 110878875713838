/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { reset } from 'redux-form'
import LoginLayout from './LoginLayout'
import { get } from 'lodash'
import TokenLoginForm from './TokenLoginForm'
import { login } from '../actions/auth'
import { default_theme as theme } from '../emotion/theme'
import dale_logo from '../images/dale.png'

class TokenSent extends Component {
    
    handleSubmit = (values) => {
        const { dispatch, email } = this.props
        return dispatch(login(email, values.password))
    }

    onCancelToken = () => {
        const { dispatch, history } = this.props
        dispatch(reset('LOGIN_FORM'))
        history.push(`/login`)
    }
    
    render() {
        const { email } = this.props

        const msg = (
            <div>
              A login token has been sent to your email account: <span css={ mail }>{ email }</span>
            </div>
        )

        const sub_msg = (
            <div>
              Please check your email inbox and enter the token below
            </div>
        )
        
        return (
            <LoginLayout msg={msg} sub_msg={sub_msg}>
              { ({client}) => 
                  <TokenLoginForm
                    client={client}
                    onSubmit={ this.handleSubmit }
                    onCancelToken={ this.onCancelToken }
                  />
              }
            </LoginLayout>
        )
    }
}
function mapStateToProps(state, props) {
    return {
        email: props && props.location && props.location.state && props.location.state.email
    }
}
export default withRouter(connect(mapStateToProps)(TokenSent))

const mail = css`
color: ${theme.colors.primary};
`
