/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get } from 'lodash'
import { reset } from 'redux-form'
import { Link } from 'react-router-dom'
import AdminMainLayout from './AdminMainLayout'
import AdminUserForm from './AdminUserForm'
import BusyMask from '../../components/BusyMask'
import Timestamp from '../../components/Timestamp'
import { adminEditableEmailList } from '../actions/admin_editable_email'
import Card from '../../components/layout/Card'
import CommonTable from '../../components/CommonTable'
import { Button, Container } from 'react-bootstrap'
import { InlineIcon } from '../../components/layout/InlineIcon'
import AdminTableHeader from './AdminTableHeader'
import AdminTableFilter from './AdminTableFilter'

class AdminEditableEmails extends Component {

    componentDidMount() {
        const { dispatch, customer_id } = this.props
        dispatch(adminEditableEmailList.fetchListIfNeeded())
    }

    componentDidUpdate(prev_props) {
        const { dispatch } = this.props
        dispatch(adminEditableEmailList.fetchListIfNeeded())
    }

    renderCell = (header_key, item, column_size, row_index, index, current_state, editable) => {
        switch( header_key ) {
            case 'description':
                return (
                    <div css={description_style}>
                      {item.description}
                    </div>
                )
            default:
                return undefined;
        }
    }

    getCellValue = (header_key, item, index) => {
        switch( header_key ) {
            case 'name':
                return (
                    <div>
                      {item.name}
                    </div>
                )
            case 'modified_at':
                return (
                    <Timestamp value={item.modified_at} format='datetime' />
                )
            case 'published':
                return (
                    <div>
                      { item.published === true && <InlineIcon icon_name='status-success' /> }
                      { item.published !== true && <InlineIcon icon_name='status-error' /> }
                    </div>
                )
            case 'action':
                return (
                    <Link to={`/admin/editable_email/${item.id}`}>
                      <InlineIcon icon_name="edit" />
                    </Link>
                )
            default:
                return undefined
        }
    }

    filterEditableEmails = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminEditableEmailList.updateListFilter(filter_values))
    }

    render() {
        const { is_loading, headers, editable_emails } = this.props
        return (
            <AdminMainLayout breadcrumbs={[{name: 'admin_home'},
                                           {name: 'editable_emails', label: 'Editable Emails', url: '/admin/editable_emails'}]}>
              <Container fluid>
                <AdminTableHeader title="Editable Emails">
                  <div css={ css`width:100%;`}>
                    <AdminTableFilter
                        updateOnChange={ this.filterEditableEmails }
                        form="ADMIN_EDITABLE_EMAILS_FILTER_FORM"
                    />
                  </div>
                </AdminTableHeader>
                <CommonTable
                    is_loading={ is_loading }
                    empty_message={`There are no editable emails.`}
                    headers={ headers }
                    items={ editable_emails }
                    item_list={adminEditableEmailList}
                    renderCell={ this.renderCell }
                    getCellValue={ this.getCellValue }
                    sortOnHeader={ this.sortOnHeader }
                />
              </Container>
            </AdminMainLayout>
        )
    }
    
}

function mapStateToProps(state, props) {
    const editable_emails = adminEditableEmailList.getVisibleObjects()
    return {
        editable_emails,
        is_loading: adminEditableEmailList.isLoading(),
        headers: {
            name: { name: 'Name', column_size: 1 },
            description: { name: 'Description', column_size: 1 },
            modified_at: { name: 'Modified', column_size: 1 },
            published: { name: 'Ready', column_size: 1 },
            action: { name: '', column_size: 1 }
        }
    }
}

export default connect(mapStateToProps)(AdminEditableEmails)

const description_style = css`
font-size: 14px;
overflow: hidden;
max-width: 500px;
text-overflow: ellipsis;
`

const legacy_row = css`
font-size: 12px;
overflow: hidden;
max-width: 500px;
text-overflow: ellipsis;
`
