/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import loading_icon from '../images/loading.gif'

class Loading extends Component {

    render() {

        const { local } = this.props

        return (
            <div css={local ? local_loading_css : loading_css}>
              <img src={loading_icon} css={local ? local_loading_img : loading_img} />
            </div>
        )
    }
}

export default Loading

const loading_css = css`
position: fixed;
top: 20px;
left: 50px; 
`

const loading_img = css`
`

const local_loading_css = css`
`

const local_loading_img = css`
width: 25px;
`
