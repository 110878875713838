/** @jsx jsx */
import { Component } from 'react'
import { isEmpty } from 'lodash'
import Pluralize from 'react-pluralize'
import { jsx, css } from '@emotion/core'

class ReplacementText extends Component {

    render() {

        const { placeholder, text, value, showCount=true } = this.props

        return (
            <Pluralize singular={ isEmpty(text) ? placeholder : text} count={value} showCount={ showCount } />
        )
    }
}

export default ReplacementText
