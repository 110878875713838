import { ItemList } from '../../orm'
import { map, size } from 'lodash'
import { http } from '../../lib'

class AdminCoursePackageList extends ItemList {
    getEntityKey() {
        return 'admin/course_package'
    }

}

export const adminCoursePackageList = new AdminCoursePackageList('admin_course_package__default')

