/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import Timestamp from '../../components/Timestamp'

class AdminCreationFooter extends Component {
    
    render() {
        const { item } = this.props
        return (
            <div css={ field_container }>
              <div css={css`display:flex`}>
                Created at &nbsp; <Timestamp value={item.created_at} format='datetime' />
              </div>
              <div css={css`display:flex`}>
                Modified at &nbsp; <Timestamp value={item.modified_at} format='datetime' />
              </div>
            </div>
        )
    }
}
export default AdminCreationFooter

const field_container = css`
font-size: 10px;
width: 100%;
display: flex;
flex-direction: column;
padding-left: 30px;
padding-right: 30px;
`
