/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { head, get } from 'lodash'
import { Button } from 'react-bootstrap'
import Pluralize from 'react-pluralize'
import { loggedInUserManager } from '../../actions/logged_in_user'
import { default_theme as theme } from '../../emotion/theme'
import { customerCourseForPackageList } from '../../actions/customer_course'
import { CourseCompletionProgressBar } from './CourseCompletionProgressBar'
import { Container } from 'react-bootstrap'
import { courseList } from '../../actions/course'
import { Separator } from '../layout/Separator'
import clock_course_img from '../../images/clock_course.png'
import video_course_img from '../../images/video_course.png'
import completed_img from '../../images/Completed.png'
import in_progress_img from '../../images/InProgress.png'
import failed_img from '../../images/InProgress.png'
import ReplacementText from '../ReplacementText'

class CourseCard extends Component {

    componentDidMount() {
        const { dispatch, course_id, course_package_id } = this.props
        dispatch(courseList.ensureObjectLoaded(course_id))

        // Limitation: Assumes all customer_courses are loaded for matching courses in the course list
    }

    onClickCourse = () => {
        const { onSelectedCourse, course_package_id } = this.props
        if ( onSelectedCourse ) {
            onSelectedCourse(course_package_id)
        }
    }
    
    render() {
        const { course_package, course, customer_course } = this.props

        const completed = get(customer_course, "progress_percentage") === 100
        const in_progress = !completed && get(customer_course, "progress_percentage") > 0
        const failed = completed && get(customer_course, "passed") === false
        const passed = completed && get(customer_course, "passed") === true
        
        return (
            <div css={container} onClick={this.onClickCourse}>
              <div css={card_img_style}>
                <img css={img} src={ (course.card_image && course.card_image.original_download_url) }/>

                <div css={status_circle_style}>
                  { passed && <img css={status_img_style} src={completed_img} /> }
                  { failed && <img css={status_img_style} src={failed_img} /> }
                  { in_progress && <img css={status_img_style} src={in_progress_img} /> }
                </div>

              </div>
              <div css={info_style}>
                <div css={header}>
                  {course_package.name}
                </div>
                <div css={separator_above_progress_bar_style}>&nbsp; </div>
                <div css={progress_bar_style}>
                  <CourseCompletionProgressBar customer_course={customer_course} />
                </div>
                <div css={separator_below_progress_bar_style}>&nbsp; </div>
                <div css={course_details_style}>
                  <div css={icon_container_style}>
                    <img css={icon_style} src={video_course_img} />
                  </div>
                  <div>
                    <ReplacementText
                        placeholder="video module"
                        text={ course_package && course_package.video_replacement_text }
                        value={ course.num_videos }
                    />
                  </div>
                  <div css={vertical_separator}>
            &nbsp;
                  </div>
                  <div css={icon_container_style}>
                    <img css={icon_style} src={clock_course_img} />
                  </div>
                  <div>
                    {course.duration_minutes} min
                  </div>
                  
                </div>
              </div>

            </div>
        )
    }
}

function mapStateToProps(state, props) {
    const { course_id, course_package_id, course_package } = props
    const course = courseList.getObject(course_id)
    const customer_id = get(loggedInUserManager.getUser(), "id")
    const customer_course = customer_id && customerCourseForPackageList.getCustomerCourse({course_id:course_id, customer_id: customer_id})
    return {
        course_id,
        course,
        customer_course,
        course_package,
        course_package_id
    }
}

export default connect(mapStateToProps)(CourseCard)

const container = css(theme.mq({
    display: "flex",
    flexDirection: "column",
    height: ["400px", "250px"],
    textAlign: "center",
    marginBottom: "30px",
    cursor: "pointer",
    position: "relative",
    borderRadius: "4px",
    "-webkit-box-shadow": "3px 4px 5px 0px #EDEDED",
    "-moz-box-shadow": "3px 4px 5px 0px #EDEDED",
    boxShadow: "3px 4px 5px 0px #EDEDED"
}))

const separator_above_progress_bar_style = css(theme.mq({
    minHeight: ["50px", "10px"],
    height: ["50px", "10px"]
}))

const separator_below_progress_bar_style = css(theme.mq({
    minHeight: ["20px", "10px"],
    height: ["20px", "10px"]
}))

const card_img_style = css(theme.mq({
    width: "auto",
    minWidth: "auto",
    position: "relative",
    height: ["50%", "50%"]
}))

const img = css(theme.mq({
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "0 0",
    paddingLeft: ["0px", "15px"],
    paddingRight: ["0px", "15px"]
}))

const header = css(theme.mq({
    textAlign: "left",
    marginTop: "5px",
    fontSize: ["24px", "18px"],
    marginBottom: "10px",
    fontWeight: "500",
    lineHeight: "1.1",
    fontColor: "#624848"
}))

const info_style = css`
padding-left: 15px;
`

const progress_bar_style = css(theme.mq({
    width: ["66%", "100%"]
}))

const course_details_style = css`
display: flex;
`

const vertical_separator = css`
width: 2px;
background-color: #BBBBBB;
margin-left: 7px;
margin-right: 7px;
height: 15px;
margin-top: 7px;
`

const icon_container_style = css`
margin-right: 5px;
`


const icon_style = css`
height: 15px;
`

const status_circle_style = css(theme.mq({
    position: "absolute",
    right: "15px",
    bottom: "15px"
}))

const status_img_style = css(theme.mq({
    height: ["55px", "30px"]
}))
