import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'
import moment from 'moment'

class CustomerVideoList extends ItemList {
    getEntityKey() {
        return "customer_video"
    }

    onWatchedVideo(customer_course_id, video_id) {
        return this.saveNewObject({customer_course: customer_course_id, video: video_id})
    }
    
}

export const customerVideoList = new CustomerVideoList("customer_video_list__default")
