/** @jsx jsx */
import { Component } from 'react'
import { default_theme as theme } from '../../emotion/theme'
import { connect } from 'react-redux'
import { head, get, map, size, filter } from 'lodash'
import { jsx, css } from '@emotion/core'
import BlueButton from '../layout/BlueButton'
import { Separator } from '../layout/Separator'

export default class AssessmentScoreProgressBar extends Component {

    render() {
        const { num_questions_passed, num_questions, score_percentage, passed, score_label } = this.props
        return (
            <div>
              <div css={section_summary_results_title}>
                Results
                <Separator variant="h30" />
              </div>
              <div css={progress_style}>
                <div css={progress_legend_style}>
                  <div>
                    { score_label || "Assessment score" }
                  </div>
                  <div>
                    {num_questions_passed}/{num_questions}
                  </div>
                </div>
                <div css={progress_background_style}>
                  <div css={[progress_amount_style,
                             passed === true ? progress_amount_style__passed : progress_amount_style__failed,
                             css`width: ${score_percentage}%;`]}>
                    &nbsp;
                  </div>
                </div>
              </div>
            </div>
        )
    }
    
}

const progress_style = css`
width: 100%;
`

const progress_legend_style = css`
display: flex;
justify-content: space-between;
`

const progress_background_style = css`
background-color: #EAEAEA;
height: 7px;
margin-top: 10px;
`

const progress_amount_style = css`
height: 7px;
`

const progress_amount_style__passed = css`
background-color: #48B02C;
`

const progress_amount_style__failed = css`
background: #ED2629;
`

const section_summary_results_title = css(theme.mq({
    fontSize: "18px",
    fontWeight: "bold",
    textAlign: ["auto", "center"]
}))
