/** @jsx jsx */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../emotion/theme'
import Select from 'react-select'
import { get, map, union, head, filter } from 'lodash'
import { useField } from 'formik'
import FormError from './FormError'
import { Dropdown as BootstrapDropdown } from 'react-bootstrap'
import FormWarning from './FormWarning'
import { InlineIcon } from './layout/InlineIcon'

export const FormikDropdownField = ({ label, formik_props, variant, options, placeholder, on_change, empty_selection_label, onAddItem, ...props }) => {
  // useField() returns [formik.getFieldProps(), formik.getFieldMeta()]
  // which we can spread on <input> and also replace ErrorMessage entirely.
    const [field, meta] = useField(props)

    variant = variant || "bootstrap"

    let default_value
    if ( variant === 'bootstrap' ) {
        default_value = get(head(filter(options, (option) => option.value === field.value)),
                            "label", <div css={bootstrap_placeholder_style}>{placeholder || "Select an option"}</div>)
    }
    if ( variant === 'semantic' ) {
        default_value = field.value
    }

    if ( empty_selection_label ) {
        options = union([{ value: null, label: empty_selection_label }], options)
    }
    
    const onChange = (value) => {
        formik_props.setFieldValue(field.name, value)
        if ( on_change ) {
            on_change(value)
        }
    }

    const handleAddition = (e, {value} ) => {
        onAddItem(value)
    }

    const allow_additions = onAddItem !== undefined
                                          
    return (
        <div>
          { variant === 'bootstrap' && <BootstrapDropdownStandalone options={options} default_value={default_value} on_change={onChange} placeholder={placeholder} /> }
          { meta.error && meta.touched ?
            <FormError meta={ meta } />
            :
            <FormWarning meta={ meta } />
          }
        </div>
    )

}

export const BootstrapDropdownStandalone = ({options, default_value, on_change}) => {

    const onChange = (option) => {
        on_change(option.value)
    }

    const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
        <div
          css={bootstrap_dropdown_toggle_style}
          ref={ref}
          onClick={(e) => {
              e.preventDefault();
              onClick(e);
          }}
        >
          <div css={bootstrap_dropdown_toggle_content_style}>
            <div>
              {children}
            </div>
            <div>
              <InlineIcon icon_name="caret-down" variant="black" />
            </div>
          </div>
        </div>
    ))
    
    return (
        <div css={bootstrap_dropdown_standalone_style}>
          <BootstrapDropdown css={bootstrap_dropdown_inner_style}>
            <BootstrapDropdown.Toggle variant="success" as={CustomToggle}>
              {default_value}
            </BootstrapDropdown.Toggle>

            <BootstrapDropdown.Menu css={bootstrap_dropdown_standalone_style}>
              {map(options, (option, index) => 
                  <BootstrapDropdown.Item key={index} onClick={() => onChange(option)}>
                    {option.label}
                  </BootstrapDropdown.Item>
              )}
            </BootstrapDropdown.Menu>
          </BootstrapDropdown>
        </div>
    )
}

class Dropdown extends Component {
    render() {
        const {
            extra_class,
            placeholder,
            input,
            label,
            tabIndex,
            meta,
            options,
            ...extraProps
        } = this.props
        
        return (
            <div>
              <Select
                  {...extraProps}
                  value={input.value}
                  options={options}
                  onChange={(value) => input.onChange(value)}
                  css={ extra_class }
              />
              { meta.error && meta.touched ?
                <FormError meta={ meta } />
                :
                <FormWarning meta={ meta } />
              }
            </div>
        )
    }
}
export default Dropdown

const select_class = css`
width: 100%;
`

const bootstrap_dropdown_toggle_style = css`
cursor: pointer;
width: 100%;
`

const bootstrap_dropdown_toggle_content_style = css`
display: flex;
align-items: center;
justify-content: space-between;
`

const bootstrap_dropdown_standalone_style = css`
width: 100%;
border: 1px solid ${theme.colors.light_middle_grey};
padding: 8px;
`

const bootstrap_dropdown_inner_style = css`
width: 100%;
`

const bootstrap_placeholder_style = css`
`

