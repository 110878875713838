/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { useField } from 'formik'

export const FormikTextarea = ({ rows, placeholder, ...props }) => {
    const [field, meta] = useField(props)

    return (
        <div>
          <textarea {...field} rows={rows || 5} css={[default_style]} placeholder={placeholder} />
        </div>
    )
}

const default_style = css`
width: 100%;
padding: 5px;
`

const error_class = css`
`
