import { ItemList } from '../orm'
import { get, head } from 'lodash'
import { default_theme as theme } from '../emotion/theme'

const ADMIN_CLIENT = {'id': 'admin'}

class ClientLoginList extends ItemList {
    getEntityKey() {
        return 'client_login'
    }

    ensureClientIsLoaded() {
        const that = this
        return async (dispatch, getState) => {
            const client = that.getClient()
            const client_slug = this.getClientSlug()
            if ( client_slug && client && client_slug != client.url_slug ) {
                dispatch(that.invalidateObject(client.id))
                dispatch(that.ensureObjectLoaded(client.id))
            }
            dispatch(that.fetchListIfNeeded())
        }
    }

    getClient() {
        const client = head(this.getVisibleObjects())
        const client_slug = this.getClientSlug()
        if ( client_slug === 'admin' ) {
            return ADMIN_CLIENT
        }
        if ( client_slug !== null && client && client.url_slug != client_slug ) {
            console.log("No match", client_slug, client)
            return null
        }
        return client
    }

    getPrimaryColour(client) {
        client = client || this.getClient()
        return get(client, "primary_colour", theme.colors.common_blue)
    }

    getClientSlug(props) {
        let client_slug = get(props, ["match", "params", "client_slug"], null)
        if ( client_slug === null ) {
            client_slug = get(window.location.host.split('.'), 0, null)
        }
        return client_slug
    }

}

export const clientManager = new ClientLoginList('client_manager__default')
