/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { withRouter } from 'react-router'
import { reduxForm, Field } from 'redux-form'
import ReactTimeout from 'react-timeout'
import { Separator } from '../../components/layout/Separator'
import InputField from '../../components/InputField'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { default_theme as theme } from '../../emotion/theme'
import { Button } from 'react-bootstrap'

const SEARCH_DELAY_MILLISECONDS = 1000

class AdminTableFilter extends Component {

    onFilterChanged = (e) => {
        const { updateOnChange } = this.props
        const filter_values = {'any_field': e.target.value}

        if ( this.filter_timeout_id ) {
            clearTimeout(this.filter_timeout_id)
            this.filter_timeout_id = null
        }
        if ( updateOnChange ) {
            this.filter_timeout_id = setTimeout(function () {
                updateOnChange(filter_values)
            }, SEARCH_DELAY_MILLISECONDS)
        }
    }
    
    render() {
        const { handleSubmit, label, updateOnChange } = this.props

        return (
            <div css={ form_container }>
              <form onSubmit={ handleSubmit }>
                <Row>
                  <div css={filter_row}>
                    Filter
                    <Separator variant="w10" />
                    <Field
                        autoComplete="off"
                        type="text"
                        name="any_field"
                        component={ InputField } 
                        onChange={ this.onFilterChanged }
                    />
                    { !updateOnChange &&
                      <Col>
                        <Button type="submit">Apply</Button>
                      </Col>
                    }
                  </div>
                </Row>
              </form>
            </div>
        )
    }
}
AdminTableFilter = reduxForm({
    form: 'ADMIN_TABLE_FILTER_FORM'
})(AdminTableFilter);

function mapStateToProps(state, props) {
    const { onSubmit, label, updateOnChange } = props
    return {
        onSubmit,
        label,
        updateOnChange,
    }
}
export default withRouter(connect(mapStateToProps)(ReactTimeout(AdminTableFilter)))

const form_container = css`
`

const field_container = css`
`

const filter_row = css`
display: flex;
align-items: center;
`
