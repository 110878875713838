/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { isEmpty } from 'lodash'
import { adminUserList } from '../actions/admin_user'
import { Formik, Form, FieldArray, Field } from 'formik'
import { FormikInputField } from '../../components/form/InputField'
import InputField from '../../components/form/InputField'
import MultiDropdown from '../../components/MultiDropdown'
import DefaultButton from '../../components/DefaultButton'
import FormToggleButton from '../../components/FormToggleButton'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import BusyMask from '../../components/BusyMask'
import { Button, Col, Row } from 'react-bootstrap'
import { default_theme as theme } from '../../emotion/theme'
import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    'first_name': Yup.string().required("Required"),
    'last_name': Yup.string().required("Required"),
    'email': Yup.string().required("Required")
})

class AdminUserForm extends Component {
   
    componentDidMount() {
        const { dispatch, user } = this.props
        if (user && user.id) {
            dispatch(adminUserList.ensureObjectLoaded(user.id))
        }
    }

    render() {
        const { onSubmit, onCancelUser, error, is_busy, user, initialValues } = this.props

        return (

            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting, setFieldError }) => {onSubmit(values, setSubmitting, setFieldError)}}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
              {formik_props => {
                  const { values } = formik_props
                  return (
                      <Form>
                        { is_busy && <BusyMask /> }


                        <Row>
                          <Col>
                            <FormLabelValue>
                              First name
                              <FormikInputField
                                  name="first_name"
                                  type="text"
                              />
                            </FormLabelValue>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormLabelValue>
                              Last name
                              <FormikInputField
                                  name="last_name"
                                  type="text"
                              />
                            </FormLabelValue>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormLabelValue>
                              Phone number
                              <FormikInputField
                                  name="phone_number"
                                  type="text"
                              />
                            </FormLabelValue>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormLabelValue>
                              Email Address
                              <FormikInputField
                                  name="email"
                                  type="text"
                              />
                            </FormLabelValue>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormLabelValue>
                              Status
                              <FormikCheckboxField
                                  name="enabled"
                                  label="Enabled/Disabled"
                              />
                            </FormLabelValue>
                          </Col>
                        </Row>
                        { false && 
                          <Row>
                            <Col>
                              <FormLabelValue>
                                Can complete courses
                                <FormikCheckboxField
                                    name="can_complete_courses"
                                    label=""
                                />
                              </FormLabelValue>
                            </Col>
                          </Row>
                        }

                        { false && 
                          <Row>
                            <Col>
                              <FormLabelValue>
                                Can view aggregated reports
                                <FormikCheckboxField
                                    name="can_view_aggregated_reports"
                                    label=""
                                />
                              </FormLabelValue>
                            </Col>
                          </Row>
                        }

                        <div css={ footer }>
                          <div>
                            <DefaultButton
                                label="Cancel"
                                type="button"
                                onClickButton={ onCancelUser }
                            />
                          </div>
                          <div css={ error_container }>
                            { error && <p>{ error }</p>}
                          </div>
                          <div>
                            <Button type="submit">
                              Save
                            </Button>
                          </div>
                        </div>
                      </Form>
              )}}
            </Formik>
        )
    }
}

function mapStateToProps(state, props) {
    const { onSubmit, user_id, onCancelUser } = props
    const user = adminUserList.getObject(user_id) || {}
    let initialValues = Object.assign({}, user)

    if (isEmpty(initialValues)) {
        initialValues.enabled = true
    }
    return {
        onSubmit,
        onCancelUser,
        user,
        initialValues,
        is_busy: adminUserList.isLoading()
    }
}
export default withRouter(connect(mapStateToProps)(AdminUserForm))

const form_layout = css`
width: 90%;
display: flex;
flex-direction: column;
align-items: center;
padding-top: 20px;
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
height: 100px;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;

`
