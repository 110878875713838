/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'

import { default_theme as theme } from '../emotion/theme'
import tooltip from '../images/tooltip_dark.png'

class ToolTip extends Component {

    constructor(props) {
        super(props)
        this.state = {
            display_tip: false,
        }
    }

    showTip = () => {
        this.setState({
            display_tip: true,
        })
    }

    hideTip = () => {
        this.setState({
            display_tip: false,
        })
    }

    renderTip = () => {
        const { children } = this.props
        return (
            <div css={ [
                container,
                css`background:#007bff;:after{border-right-color:#007bff;}`
            ] }>
              { children }
            </div>
        )
    }
    
    render() {
        const { display_tip } = this.state
        
        return (
            <div css={ main }>
              <img src={ tooltip } css={ icon } alt="" onMouseEnter={ this.showTip } onMouseLeave={ this.hideTip } />
              { display_tip && this.renderTip() }
            </div>
        )
    }
}
export default ToolTip

const main = css`
display: flex; 
flex-direction: row; 
margin-left:5px; 
height: 22px; 
align-items: center;
`

const icon = css`
height: 20px; 
width: auto;
cursor: pointer;
`

const container = css`
text-align: justify;
font-size: 0.8rem;
max-width: 290px;
position: absolute;
margin-left: 25px;
border-radius: 5px; 
color: white; 
padding-left: 14px;
padding-right: 14px;
padding-top: 10px;
padding-bottom: 10px;
:after {
content: '';
position: absolute;
left:0;
top: 50%;
width: 0;
height: 0;
border: 5px solid transparent;
border-left: 0;
margin-top: -5px;
margin-left: -5px;
}
`
