/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { Link } from 'react-router-dom'
import { map, get, size, filter, keys, isEmpty } from 'lodash'
import { adminCoursePackageList } from '../actions/admin_course_package'
import { adminCourseList } from '../actions/admin_course'
import InputField from '../../components/form/InputField'
import DefaultButton from '../../components/DefaultButton'
import CommonTable from '../../components/CommonTable'
import ButtonBar from '../../components/ButtonBar'
import { FormikDropdownField } from '../../components/Dropdown'
import Timestamp from '../../components/Timestamp'
import TableHeader from '../../components/TableHeader'
import TableFilter from '../../components/TableFilter'
import AdminCreationFooter from './AdminCreationFooter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikTextarea } from '../../components/form/TextareaField'
import { default_theme as theme } from '../../emotion/theme'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Col, Row, Container, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import { FormikDatePicker } from '../../components/form/DatetimePicker'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { FormFieldWithAction } from '../../components/form/FormFieldWithAction'
import { FormikFileUploadField } from '../../components/form/FileUploader'
import { FormikCheckboxField } from '../../components/form/CheckboxField'
import ToolTip from '../../components/ToolTip'
import * as Yup from 'yup'
import moment from 'moment'

const validationSchema = Yup.object().shape({
  'name': Yup.string().required("Name is required"),
  'course': Yup.string().required("Please select a course")
})

class AdminCoursePackageForm extends Component {

    constructor(props) {
        super(props)
        this.state = {newly_selected_course: null}
    }
    
    componentDidMount() {
        const { dispatch, course_package_id, course_package } = this.props
        dispatch(adminCoursePackageList.ensureObjectLoaded(course_package_id))
        dispatch(adminCourseList.fetchListIfNeeded())
        if ( course_package.course ) {
            adminCourseList.ensureObjectLoaded(course_package.course)
        }
    }

    componentDidUpdate(prev_props) {
        const { dispatch, course_package } = this.props
        dispatch(adminCourseList.fetchListIfNeeded())
        if ( course_package.course ) {
            adminCourseList.ensureObjectLoaded(course_package.course)
        }
    }

    onSave = (values, setSubmitting, setFieldError) => {
        const { onSubmit } = this.props
        onSubmit(values).then( (res) => {
            setSubmitting(false)
            if ( res.errors ) {
                map(keys(res.errors), (field_name) => setFieldError(field_name, res.errors[field_name]))
            }
        })
    }

    onSelectCourse = (item, formik_props) => {
        const course_id = item.id
        formik_props.setFieldValue("course", course_id)
        this.setState({newly_selected_course: item})
    }

    filterCourse = (filter_values) => {
        const { dispatch } = this.props
        dispatch(adminCourseList.updateListFilter(filter_values))
    }

    getCourseCellValue = (header_key, item, index, formik_props) => {
        switch( header_key ) {
        case 'action':
            return (
                <ButtonBar>
                  <Button onClick={(evt) => this.onSelectCourse(item, formik_props)}>
                    Select
                  </Button>
                </ButtonBar>
            )
        default:
            return undefined
        }
    }

    renderCourseSelector(formik_props) {
        const { course_headers, courses, is_loading, selected_course } = this.props
        const { newly_selected_course } = this.state

        const course_to_show = newly_selected_course || selected_course
        
        return (
            <div>
              <Card title="Template for this Package">

                <h4>
                  { course_to_show !== undefined && course_to_show.name }
                  { course_to_show === undefined && "No template selected" }
                  <br/>
                </h4>
                
                <TableHeader>
                  <div css={ css`width:100%;`}>
                    <TableFilter
                      updateOnChange={ this.filterCourse }
                      form="ADMIN_COURSE_PACKAGE_COURSE_TABLE_FILTER_FORM"
                    />
                  </div>
                </TableHeader>
                { formik_props.errors.course &&
                  <div css={error_class}>{formik_props.errors.course}</div>
                }
                <CommonTable
                  is_loading={ is_loading }
                  empty_message="There are no course templates."
                  headers={ course_headers }
                  items={ courses }
                  getCellValue={(header_key, item, index) => this.getCourseCellValue(header_key, item, index, formik_props) }
                  item_list={adminCourseList}
                />
              </Card>
            </div>
        )
    }

    render() {
        const { onCancel, error, course_package,
                initialValues, client_id, course_package_id
        } = this.props

        return (
          <Formik
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, setFieldError }) => {this.onSave(values, setSubmitting, setFieldError)}}
            enableReinitialize={true}
            validationSchema={validationSchema}
          >
            {formik_props => {
              const { values } = formik_props
              return (
                <Form>
                  <Row>
                    <Col md="6">
                      <Card title="Course Details">
                        <Row>
                          <Col>
                            <FormLabelValue>
                              Name
                              <FormikInputField
                                name="name"
                                type="text"
                                placeholder="Enter a name here"
                              />
                            </FormLabelValue>

                            <FormLabelValue>
                              Description
                              <FormikTextarea name="description"
                                              type="text"
                                              placeholder="Enter a description here"
                              />
                            </FormLabelValue>

                            <FormLabelValue>
                              "Video Module" replacement text
                              <FormikInputField
                                name="video_replacement_text"
                                type="text"
                                placeholder='Enter replacement text for "video module" here'
                              />
                            </FormLabelValue>

                            <FormLabelValue>
                              "Assessment" replacement text
                              <FormikInputField
                                name="assessment_replacement_text"
                                type="text"
                                placeholder='Enter replacement text for "assessment" here'
                              />
                            </FormLabelValue>

                            <FormLabelValue>
                              Start date
                              <FormikDatePicker name='date_start' formik_props={formik_props} />
                            </FormLabelValue>

                            <FormLabelValue>
                              <div css={css`display:flex;`}>
                                Official end date <ToolTip>
                                Used for communication with customers
                                </ToolTip>
                              </div>
                              <FormikDatePicker name='date_end_official' formik_props={formik_props} />
                            </FormLabelValue>

                            <FormLabelValue>
                              End date
                              <FormikDatePicker name='date_end' formik_props={formik_props} />
                            </FormLabelValue>
                            <FormLabelValue>
                              Status
                              <FormikCheckboxField
                                name="enabled"
                                label="Enabled/Disabled"
                              />
                            </FormLabelValue>
                          </Col>
                        </Row>
                      </Card>

                      
                      { course_package.id &&
                        <Row>
                          <Col>
                            <Link to={`/admin/client/${client_id}/course_package/${course_package_id}/users`}>Manage users for this course (currently {course_package.num_users})</Link>
                          </Col>
                        </Row>
                      }
                      <Row>
                        <Col>
                          <Button type="submit">
                            Save
                          </Button>
                        </Col>
                      </Row>

                    </Col>

                    <Col md="6">
                      { this.renderCourseSelector(formik_props) }
                    </Col>
                    
                  </Row>
                  
                </Form>
            )}
            }
          </Formik>
        )
    } 
}

function mapStateToProps(state, props) {
    const { onSubmit, course_package_id, client_id, onCancel } = props
    let course_package = adminCoursePackageList.getObject(course_package_id) || {}
    const courses = adminCourseList.getVisibleObjects()
    const selected_course = course_package.course && adminCourseList.getObject(course_package.course)
    const course_headers = {
        name: { name: 'Name', column_size: 1 },
        action: { name: 'Action', column_size: 1 }
    }
    let initialValues = course_package
    if (isEmpty(initialValues)) {
        initialValues.date_end = moment()
        initialValues.date_start = moment()
        initialValues.date_end_official = moment()
    }
    return {
        onSubmit,
        onCancel,
        selected_course,
        courses,
        course_package,
        course_headers,
        initialValues: initialValues
    }
}
export default withRouter(connect(mapStateToProps)(AdminCoursePackageForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
margin: 10px;
padding: 10px;
@media(max-width: 1300px) {
align-items: center;
}
`

const row_container = css`
width: 50%;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
@media(max-width: 1300px) {
    width: 700px;
}
`

const column_container = css`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
width: 100%;
@media(max-width: 1300px) {
    flex-wrap: nowrap;
    height: auto;
}
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
padding-left: 30px;
padding-right: 30px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: column;
width: 100%;
`

const footer_row = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const row = css`
display: flex; 
justify-content: space-between;
`

const cell = css`
width: 45%;
`

const label_center = css`
color: ${theme.colors.secondary};
font-weight: 600;
text-align: center;
width: 100%;
`

const multi_value_row = css`
display: flex;
justify-content: space-between;
margin: 10px;
padding: 10px;
box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
`

const button_group = css`
width: 100%;
display: flex;
justify-content: end;
`

const multi_value_section = css`
margin-top: 20px;
`

const row_separator = css`
margin-top: 20px;
`

const header = css`
display: flex;
justify-content: flex-end;
width: 100%;
`

const error_class = css`
  color: ${theme.colors.error}
}
`
