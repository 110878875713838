/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { get } from 'lodash'
import { Helmet } from 'react-helmet'
import { jsx, css } from '@emotion/core'
import Breadcrumbs from '../../components/Breadcrumbs'
import { clientManager } from '../../actions/client_manager'
import AdminMenuTop from './AdminMenuTop'
import { Separator } from '../../components/layout/Separator'
import { default_theme as theme } from '../../emotion/theme'
import dale_logo from '../../images/dale_small.png'
import { Col, Row, Container } from 'react-bootstrap'

class AdminMainLayout extends Component {
    
    render() {
        const { title, children, breadcrumbs, client } = this.props

        return (
            <div css={main}>
              <Helmet>
                <title>Admin - { get(client, "name", "Jigsaw Compliance") }</title>
              </Helmet>
              <AdminMenuTop/>
              <Breadcrumbs crumbs={breadcrumbs}/>
              <hr/>
              { title &&
                <Container fluid>
                  <Row>
                    <Col>
                      <div css={ header }>
                        <div css={title_container}>{ title }</div>
                      </div>
                    </Col>
                  </Row>
                  <Separator variant="h30" />
                </Container>
              }
              <Container>
                { children }
                <Separator variant="h100" />
              </Container>
            </div>
        )
    }
}
function mapStateToProps(state, props) {
    const { title, layout, children } = props
    return {
        title,
        children,
        client: clientManager.getClient()
    }
}
export default connect(mapStateToProps)(AdminMainLayout)

const main = css`
background-color: ${theme.colors.page_background};

`

const header = css`
display: flex;
flex-direction: row;
width: 100%;
height: 50px;
justify-content: space-between;
align-items: center;
color: ${theme.colors.secondary};
padding: 10px;
background-color: #ededed;
margin-bottom: 10px;
`

const title_container = css`
margin-top:5px;
font-size: 30px;
font-weight: bold;
`
