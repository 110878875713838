/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { FormattedNumber } from '../FormattedNumber'
import { get, size } from 'lodash'
import { Link } from 'react-router-dom'
import MainLayout from '../MainLayout'
import { Container, Col, Row, Popover, OverlayTrigger } from 'react-bootstrap'
import { Separator } from '../layout/Separator'
import { RadialBarChart, RadialBar, Legend, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from 'recharts'
import { customerReportList } from '../../actions/customer_report'
import CommonTable from '../CommonTable'
import WrappingBusyMask from '../WrappingBusyMask'
import { loggedInUserManager } from '../../actions/logged_in_user'
import BlueButton from '../layout/BlueButton'
import Timestamp from '../Timestamp'
import { customerCertificateList } from '../../actions/customer_certificate'

class CustomerCertificates extends Component {

    componentDidMount() {
        const { dispatch } = this.props
        dispatch(loggedInUserManager.ensureUserLoaded())
        dispatch(customerCertificateList.fetchListIfNeeded())
    }

    componentDidUpdate() {
        const { dispatch } = this.props
        dispatch(customerCertificateList.fetchListIfNeeded())
    }

    onDownload = (id) => {
        customerCertificateList.download(id)
    }

    getCellValue = (header_key, item, index, row_index) => {
        const that = this
        switch( header_key ) {
            case 'created_at':
                return <Timestamp value={item.created_at} format='date' />
            case 'download':
                return (
                    <BlueButton onClick={() => this.onDownload(item.id)} auto_disable={false}>
                      Download
                    </BlueButton>
                )
            default:
                return undefined
        }
    }

    renderCertificates() {
        const { report, certificates, headers, is_loading } = this.props
        return (
            <div css={card_style}>
              <CommonTable
                is_loading={ is_loading }
                empty_message="You have no certificates yet."
                headers={ headers }
                items={ certificates }
                getCellValue={ this.getCellValue }
                item_list={customerCertificateList}
              />
            </div>
        )
    }
    
    render() {
        const { is_loading, customer } = this.props
        return (
            <MainLayout>

              <WrappingBusyMask is_loading={is_loading}>

                { ! is_loading &&
                  <Container fluid  css={container_style}>
                    <div css={header_style}>
                      Your certificates
                    </div>

                    <Separator variant="h50" />

                    { this.renderCertificates() }

                    <Separator variant="h100" />
                    
                  </Container>
                }
              </WrappingBusyMask>
            </MainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const customer = loggedInUserManager.getUser()
    return {
        customer,
        is_loading: customerCertificateList.isLoading(),
        headers: {
            created_at: {name: 'Created at'},
            name: {name: 'Name'},
            download: {name: ''}
        },
        certificates: customerCertificateList.getVisibleObjects()
    }
}
export default connect(mapStateToProps)(CustomerCertificates)

const container_style = css`
background-color: #e6e8e3;
padding-left: 50px;
padding-right: 50px;
`
const header_style = css`
background-color: #e6e8e3;
font-size: 30px;
text-align: center;
color: #575853;
padding-top: 40px;
padding-bottom: 30px;
display: flex;
justify-content: center;
`

const card_style = css`
background: white;
border-radius: 4px;
text-align: center;
height: 350px;
padding-bottom: 20px;
padding-top: 20px;
padding-left: 20px;
padding-right: 20px;
`
