/** @jsx jsx */
import { Component } from 'react'
import { connect } from 'react-redux'
import { jsx, css } from '@emotion/core'
import { get, map, size } from 'lodash'
import Timestamp from '../../components/Timestamp'
import { adminCourseSectionList } from '../actions/admin_course_section'
import { adminCourseList } from '../actions/admin_course'
import { adminCourseSectionQuestionList } from '../actions/admin_course_section_question'
import Loading from '../../components/Loading'
import AdminMainLayout from './AdminMainLayout'
import AdminCourseSectionForm from './AdminCourseSectionForm'
import BusyMask from '../../components/BusyMask'
import Modal from '../../components/Modal'
import {NotificationManager} from 'react-notifications'
import Card from '../../components/layout/Card'
import { Button, Row, Col } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPencilAlt, faTrashAlt, faPlus } from '@fortawesome/free-solid-svg-icons'

class AdminCourseSection extends Component {

    componentDidMount() {
        const { dispatch, course_section_id, course_section } = this.props
        dispatch(adminCourseSectionList.ensureObjectLoaded(course_section_id))
        dispatch(adminCourseSectionQuestionList.updateListFilter({course_section:course_section_id}))
        dispatch(adminCourseSectionQuestionList.fetchListIfNeeded())

        if ( course_section && course_section.course ) {
            dispatch(adminCourseList.ensureObjectLoaded(course_section.course))
        }
    }

    componentDidUpdate(prev_props) {
        const { dispatch, course_section_id, course_section } = this.props
        if ( course_section_id != prev_props.course_section_id ) {
            dispatch(adminCourseSectionQuestionList.updateListFilter({course_section:course_section_id}))
        }
        if ( course_section_id ) {
            dispatch(adminCourseSectionQuestionList.fetchListIfNeeded())
            dispatch(adminCourseSectionList.ensureObjectLoaded(course_section_id))
        }
        if ( course_section && course_section.course ) {
            dispatch(adminCourseList.ensureObjectLoaded(course_section.course))
        }
    }

    onAddQuestion = () => {
        const { dispatch, course_section_id, course, course_section } = this.props
        dispatch(adminCourseSectionQuestionList.saveNewObject({course_section: course_section_id, text: `New Question ${size(course_section.questions)+1}`})).then( () => {
            dispatch(adminCourseSectionQuestionList.invalidateList())
            dispatch(adminCourseSectionList.invalidateObject(course_section_id))
            dispatch(adminCourseSectionList.ensureObjectLoaded(course_section_id))
        })
    }

    onEditQuestion = (question) => {
        const { history } = this.props
        history.push(`/admin/course_section_question/${question.id}`)
    }

    onDeleteQuestion = (question) => {
        const { history, dispatch, course_section_id } = this.props
        if ( ! window.confirm("Are you sure?") ) {
            return null
        }
        return dispatch(adminCourseSectionQuestionList.deleteObject(question.id)).then( () => {
            NotificationManager.success("Deleted", "Course question deleted")
            dispatch(adminCourseSectionQuestionList.invalidateList())
            dispatch(adminCourseSectionList.invalidateObject(course_section_id))
        })
    }
    
    onSubmit = (values) => {
        const { dispatch, course_section_id, course, history } = this.props
        const that = this
        values.active = values.status ? values.status.value : true
        const on_done = function(json) {
            dispatch(adminCourseSectionQuestionList.invalidateList())
            NotificationManager.success("Saved", "Course section saved2")
            if ( course_section_id ) {
                dispatch(adminCourseSectionList.invalidateObject(course_section_id))
                dispatch(adminCourseSectionList.ensureObjectLoaded(course_section_id))
                dispatch(adminCourseList.invalidateObject(course.id))
                dispatch(adminCourseList.ensureObjectLoaded(course.id))
            } else {
                history.push(`/admin/course_section/${json.id}`)
            }
        }
        if ( course_section_id ) {
            values.id = course_section_id
            return dispatch(adminCourseSectionList.saveObject(values, on_done))
        } else {
            return dispatch(adminCourseSectionList.saveNewObject(values, on_done))
        }
    }

    renderQuestions = () => {
        const { course_section } = this.props
        const that = this
        return (
            <Card title="Questions">
              {map(course_section.questions, function(question, index) {
                  return (
                      <Row key={index}>
                        <Col md="6">
                          {question.text}
                        </Col>
                        <Col md="1">
                          <Button onClick={() => that.onEditQuestion(question)} variant="outline-secondary">
                            <FontAwesomeIcon icon={faPencilAlt} />
                          </Button>
                        </Col>
                        <Col md="1">
                          <Button onClick={() => that.onDeleteQuestion(question)} variant="outline-secondary">
                            <FontAwesomeIcon icon={faTrashAlt} />
                          </Button>
                        </Col>
                      </Row>
                  )
               }
              )}
              <Button onClick={that.onAddQuestion} variant="outline-secondary">
                <FontAwesomeIcon icon={faPlus} />
                &nbsp; Add question
              </Button>
            </Card>
        )
    }

    render() {
        const { course_section_id, course_section, is_busy, is_loading, page, breadcrumbs } = this.props
        const title = course_section_id ? 'Edit Section' : 'Add Section'

        return (
            <AdminMainLayout title={title} breadcrumbs={breadcrumbs}>
              { is_busy && <BusyMask /> }
              { is_loading && <Loading /> }
              <Row>
                <Col>
                  <AdminCourseSectionForm
                      onSubmit={ this.onSubmit }
                      course_section_id={ course_section_id }
                  />
                </Col>
                <Col>
                  { course_section && course_section.section_type === 'assessment' &&
                    course_section.id && this.renderQuestions() }
                </Col>
              </Row>
              { course_section && 
                <div>Last modified at <Timestamp value={course_section.modified_at} /></div>
              }
            </AdminMainLayout>
        )
    }
}

function mapStateToProps(state, props) {
    const course_section_id = get(props, ["match", "params", "course_section_id"], null)
    const course_section = adminCourseSectionList.getObject(course_section_id)
    const course = course_section && adminCourseList.getObject(course_section.course)
    const breadcrumbs = [ {label: course && course.name,
                           url: course &&`/admin/course/${course_section.course}`},
                          {label: course_section && course_section.name,
                           url: `/admin/course_section/${course_section_id}`}]
    return {
        course_section_id,
        course_section,
        course,
        breadcrumbs,
        is_loading: adminCourseSectionList.isLoading(),
        is_busy: adminCourseSectionList.getIsSavingObject()
    }
}
export default connect(mapStateToProps)(AdminCourseSection)

const forms_container = css`
display: flex; 
flex-direction: column;
align-items: center; 
justify-content: flex-start;
width: 100%;
`

const content_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`

const admin_container = css`
display: flex;
flex-direction: column;
align-items: center;
width: 100%;
}
`
