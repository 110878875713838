/** @jsx jsx */
import { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../../emotion/theme'

export class CourseBannerContainer extends Component {
    render() {
        return (
            <div css={course_banner_container_card_style}>
              {this.props.children}
            </div>
        )
        
    }
}

const course_banner_container_card_style = css(theme.mq({
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100%",
    minHeight: ["640px", "500px"]
}))

