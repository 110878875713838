/** @jsx jsx */
import { Component } from 'react';
import { jsx, css } from '@emotion/core'
import { map, size } from 'lodash'
import { default_theme as theme } from '../emotion/theme'

class ButtonBar extends Component {

    renderButton = (btn, index) => {
        return <div key={index} css={button}>{btn}</div>
    }
    
    render() {
        return (
            <div css={button_bar}>
              { this.props.children.length>1 && map(this.props.children, (child, index) => this.renderButton(child, index)) }
              { !this.props.children.length && this.renderButton(this.props.children, 0) }
            </div>
        )
    }
}
export default ButtonBar

export const button_bar = css`
display: flex;
margin-bottom: 5px;
margin-top: 5px;
`

export const button = css`
padding-left: 5px;
padding-right: 5px;
`
