import { ItemList } from '../orm'
import { map, get, head } from 'lodash'

class AggregatedReportCustomerCourseForExportList extends ItemList {
    getEntityKey() {
        return "aggregated_report_customer_course/report_data_for_export"
    }

    setClientGroup(client_group_id) {
        return this.updateListFilter({client_group: client_group_id})
    }
        
    
}

export const aggregatedReportCustomerCourseForExportList = new AggregatedReportCustomerCourseForExportList("aggregated_report_customer_course_for_export__default")
