import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'

class CourseList extends ItemList {
    getEntityKey() {
        return "course"
    }
    
    getCourseSection(course, id) {
        return head(filter(get(course, "sections", []), (section) => section.id === id))
    }
}

export const courseList = new CourseList("course__default")
