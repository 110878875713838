/** @jsx jsx */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { default_theme as theme } from '../../emotion/theme'
import CardInfoText from './CardInfoText'
import { Separator } from './Separator'

export default class Card extends Component {

    render() {

        const { title, mini_title } = this.props
        return (
            <div css={container}>
              { title && 
                <h2>{title}</h2>
              }
              { mini_title &&
                <div>
                  <CardInfoText>{mini_title}</CardInfoText>
                  <Separator variant="h15"/>
                </div>
              
              }
              <div css={card}>
                {this.props.children}
              </div>
            </div>
        )
    }
}

const container = css`
width: 100%;
`

const card = css`
padding: 10px;
border: 1px solid ${theme.colors.card_border};
border-radius: 4px;
background-color: ${theme.colors.card_background};
margin-bottom: 20px;
`
