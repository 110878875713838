/** @jsx jsx */
import React, { Component } from 'react'
import { jsx, css } from '@emotion/core'
import { get } from 'lodash'
import { default_theme as theme } from '../../emotion/theme'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'
import { clientManager } from '../../actions/client_manager'

class HollowButton extends Component {

    render() {
        const { onClick, children, client, extra_style } = this.props
        const client_style = css`
background-color: transparent;
border: 1px solid #fff;

&:focus, &:active, &:hover {
background-color: transparent;
border-color: ${clientManager.getPrimaryColour()};
}
`
        return (
            <Button variant="primary" css={[style, client_style, extra_style]} onClick={onClick} {...this.props}>
              {children}
            </Button>
        )
    }

}

function mapStateToProps(state, props) {
    return {
    }
}

export default connect(mapStateToProps)(HollowButton)

const style = css`
font-weight: 500;
border-radius: 0px;
border-radius: 2px;
`
