import facepaint from 'facepaint'

const breakpoint_mobile = '768px'
const breakpoint_desktop = '768px'

// To use mq :
//     ...
//     css(theme.mq({
//        width: [x, y],
//     }))
//     ...
//     means x is default (which in our case is desktop), and mobile will be y.
//

export const default_theme = {
    mq: facepaint([
        `@media(max-width: ${breakpoint_mobile})`
    ]),

    breakpoint_mobile: breakpoint_mobile,
    breakpoint_desktop: breakpoint_mobile,
    
    colors: {
        white: '#FFFFFF',
        black: '#000000',
        primary: '#599DC2',
        primary_hover: '#15aed3',
        gray1: '#3c4858',
        gray2: '#e6e6e6',
        gray3: '#eeeeee',
        gray4: '#bfbfbf',
        gray5: '#7b7979',
        error: '#FF0000',
        green: '#36801e',
        dark_green: '#2c6b17',
        red: '#b32626',
        dark_red: '#9a1919',
        secondary: '#282828',
        //secondary: '#3c4858',
        secondary_hover: '#bfbfbf',
        light: '#f8f9fa',
        logo_1: '#e56325',
        logo_2: '#9bcb3c',
        logo_3: '#feba18',
        logo_4: '#15aed3',

        dashboard_1: '#e56325',
        dashboard_2: '#9bcb3c',
        dashboard_3: '#feba18',
        dashboard_4: '#15aed3',

        menu_background: '#fff',
        page_background: '#eeeeee4a',
        content_background: '#eeeeee4a',

        card_border: '#d1d1d6',
        card_background: '#ffffff',

        common_blue: "#04A4E5",

        primary_orange: "#ff9500",
        primary_green: "#23b73d",
        primary_red: "#d3222a",
        very_light_grey: "#efeff4",
        
        
    }
}
