/** @jsx jsx */
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { jsx, css } from '@emotion/core'
import { map, get, size, filter, keys } from 'lodash'
import { adminCourseSectionList } from '../actions/admin_course_section'
import InputField from '../../components/form/InputField'
import DefaultButton from '../../components/DefaultButton'
import { FormikDropdownField } from '../../components/Dropdown'
import Timestamp from '../../components/Timestamp'
import AdminCreationFooter from './AdminCreationFooter'
import { FormikInputField } from '../../components/form/InputField'
import { FormikTextarea } from '../../components/form/TextareaField'
import { FormikFileUploadField } from '../../components/form/FileUploader'
import { default_theme as theme } from '../../emotion/theme'
import { Formik, Form, FieldArray, Field } from 'formik'
import { Col, Row, Container, Button } from 'react-bootstrap'
import Card from '../../components/layout/Card'
import CardInfoText from '../../components/layout/CardInfoText'
import { FormLabelValue } from '../../components/form/FormLabelValue'
import { Separator } from '../../components/layout/Separator'
import { FormFieldWithAction } from '../../components/form/FormFieldWithAction'
import * as Yup from 'yup'

const SECTION_TYPE_OPTIONS = [ {value: 'assessment', label: 'Assessment'},
                               {value: 'video', label: 'Video'},
                               {value: 'document', label: 'Document'} ]

const DOCUMENT_TYPE_OPTIONS = [ {value: 'pdf', label: 'Pdf'},
                                {value: 'pdf_slideshow', label: 'Pdf Slideshow'} ]


const validationSchema = Yup.object().shape({
})

const MAX_NUM_VIDEOS = 1

class AdminCourseSectionForm extends Component {

    componentDidMount() {
        const { dispatch, course_section_id } = this.props
        dispatch(adminCourseSectionList.ensureObjectLoaded(course_section_id))
    }

    onSave = (values, setSubmitting, setFieldError) => {
        const { onSubmit } = this.props
        onSubmit(values).then( (res) => {
            setSubmitting(false)
            if ( res.errors ) {
                map(keys(res.errors), (field_name) => setFieldError(field_name, res.errors[field_name]))
            }
        })
    }

    onAddVideo = (evt, arrayHelpers, index) => {
        evt.preventDefault()
        arrayHelpers.insert(index+1, {name: null})
    }

    onAddDocument = (evt, arrayHelpers, index) => {
        evt.preventDefault()
        arrayHelpers.insert(index+1, {name: null})
    }

    renderVideosSection(formik_props, values) {
        return (
            <FieldArray
                name="videos"
                render={arrayHelpers => (
                    <div>
                      { map(values.videos, (video, index) => (
                          <Container key={index}>

                            <Row css={row_separator}>
                              <Col>
                                <FormLabelValue>
                                  Url

                                  <FormikTextarea name={`videos.${index}.video_url`}
                                                  formik_props={formik_props}
                                                  placeholder="Enter the video url (eg http://player.vimeo.com/video/xxxx)"
                                  />
                                </FormLabelValue>
                              </Col>
                            </Row>
                            
                            <Row>
                              <Col>
                                <FormLabelValue>
                                  <span>Duration (minutes:seconds)</span>
                                  <FormikInputField name={`videos.${index}.duration_string`}
                                                         type="text"
                                                         placeholder="Duration (MM:SS)"
                                  />
                                </FormLabelValue>
                              </Col>
                            </Row>

                            <hr/>
                            <Separator variant="h30" />
                          </Container>
                      ))}

                      { (MAX_NUM_VIDEOS > 1 || size(values.videos) === 0) && (
                            <Row>
                              <Col>
                                <div css={button_group}>
                                  <Button variant="outline-primary" onClick={(evt) => this.onAddVideo(evt, arrayHelpers, size(values.videos))}>
                                    Add video
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                      )}

                    </div>
                )}
            />
        )
    }

    renderDocumentsSection(formik_props, values) {
        return (
            <FieldArray
                name="documents"
                render={arrayHelpers => (
                    <div>
                      { map(values.documents, (document, index) => (
                          <Container key={index}>

                            <Row css={row_separator}>
                              <Col>
                                <FormLabelValue>
                                  Document
                                  <FormikFileUploadField name={`documents.${index}.document`}
                                                         upload_relative_url='file_info/'
                                                         file_types=".pdf"
                                                         formik_props={formik_props}
                                  />
                                </FormLabelValue>
                              </Col>
                            </Row>
                            
                            <Row>
                              <Col>
                                <FormLabelValue>
                                  Type
                                  <FormikDropdownField name={`documents.${index}.document_type`}
                                                       formik_props={formik_props}
                                                       options={DOCUMENT_TYPE_OPTIONS}
                                  />
                                </FormLabelValue>
                              </Col>
                            </Row>

                            <hr/>
                            <Separator variant="h30" />
                          </Container>
                      ))}

                      { size(values.documents) === 0 && (
                            <Row>
                              <Col>
                                <div css={button_group}>
                                  <Button variant="outline-primary" onClick={(evt) => this.onAddDocument(evt, arrayHelpers, size(values.documents))}>
                                    Add document
                                  </Button>
                                </div>
                              </Col>
                            </Row>
                      )}

                    </div>
                )}
            />
        )
    }
    
    render() {
        const { onCancel,
                error,
                course_section,
                course,
                initialValues
        } = this.props
 
        return (
            <Formik
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting, setFieldError }) => {this.onSave(values, setSubmitting, setFieldError)}}
                enableReinitialize={true}
                validationSchema={validationSchema}
            >
              {formik_props => {
                   const { values } = formik_props
                   return (
                       <Form>
                         <Row>
                           <Col>
                             <Card title="Section Details" >
                               <Row>
                                 <Col>
                                 </Col>
                               </Row>
                               <Row>
                                 <Col>
                                   <FormLabelValue>
                                     Name
                                     <FormikInputField
                                         name="name"
                                         type="text"
                                         placeholder="Enter course section name here"
                                     />
                                   </FormLabelValue>

                                   <FormLabelValue>
                                     Minimum passmark (percentage)
                                     <FormikInputField
                                         name="pass_percentage"
                                         type="text"
                                         placeholder="Pass percentage"
                                     />
                                   </FormLabelValue>

                                   <FormLabelValue>
                                     Sequence
                                     <FormikInputField name="sequence"
                                                       type="text"
                                                       placeholder="Sequence"
                                     />
                                   </FormLabelValue>

                                   <FormLabelValue>
                                     Type
                                     <FormikDropdownField name="section_type"
                                                          formik_props={formik_props}
                                                          options={SECTION_TYPE_OPTIONS}
                                     />
                                   </FormLabelValue>

                                   
                                 </Col>
                               </Row>
                               <Row>
                                 <Col>
                                   <Button type="submit">
                                     Save
                                   </Button>
                                 </Col>
                               </Row>

                             </Card>
                           </Col>
                         </Row>


                         
                         { course_section && course_section.section_type === 'video' && 
                           <Row>
                             <Col>
                               <Card title="Video">
                                 <Row>
                                   <Col>
                                     { this.renderVideosSection(formik_props, values) }
                                   </Col>
                                 </Row>
                               </Card>
                             </Col>
                           </Row>
                         }

                         { course_section && course_section.section_type === 'document' && 
                           <Row>
                             <Col>
                               <Card title="Document">
                                 <Row>
                                   <Col>
                                     { this.renderDocumentsSection(formik_props, values) }
                                   </Col>
                                 </Row>
                               </Card>
                             </Col>
                           </Row>
                         }

                         <Row>
                           <Col>
                             <Button type="submit">
                               Save
                             </Button>
                           </Col>
                         </Row>

                         
                       </Form>
                   )}
              }
            </Formik>
        )
    } 
}

function mapStateToProps(state, props) {
    const { onSubmit, course_section_id, onCancel } = props
    let course_section = adminCourseSectionList.getObject(course_section_id) || {}
    return {
        onSubmit,
        onCancel,
        course_section,
        initialValues: course_section
    }
}
export default withRouter(connect(mapStateToProps)(AdminCourseSectionForm))

const form_layout = css`
width: 100%;
display: flex;
flex-direction: column;
align-items: flex-start;
justify-content: flex-start;
margin: 10px;
padding: 10px;
@media(max-width: 1300px) {
align-items: center;
}
`

const row_container = css`
width: 50%;
display: flex;
flex-direction: row;
align-items: flex-start;
justify-content: flex-start;
@media(max-width: 1300px) {
    width: 700px;
}
`

const column_container = css`
display: flex;
flex-direction: column;
flex-wrap: wrap;
justify-content: flex-start;
width: 100%;
@media(max-width: 1300px) {
    flex-wrap: nowrap;
    height: auto;
}
`

const field_container = css`
width: 100%;
display: flex;
flex-direction: column;
padding-left: 30px;
padding-right: 30px;
`

const error_container = css`
display: flex;
align-items: center;
min-height: 30px;
flex-direction: column;
padding-top: 20px;

p {
margin: 0;
padding: 0;
color: ${theme.colors.error};
}
`

const footer = css`
display: flex;
flex-direction: column;
width: 100%;
`

const footer_row = css`
display: flex;
flex-direction: row;
text-align: center;
justify-content: space-between;
width: 100%;
padding-top:20px;
`

const label = css`
color: ${theme.colors.secondary};
font-weight: 600;
`

const row = css`
display: flex; 
justify-content: space-between;
`

const cell = css`
width: 45%;
`

const label_center = css`
color: ${theme.colors.secondary};
font-weight: 600;
text-align: center;
width: 100%;
`

const multi_value_row = css`
display: flex;
justify-content: space-between;
margin: 10px;
padding: 10px;
box-shadow: 0 0 1.5px 0 rgba(0, 0, 0, 0.12), 0 1.5px 1.5px 0 rgba(0, 0, 0, 0.24);
`

const button_group = css`
width: 100%;
display: flex;
justify-content: end;
`

const multi_value_section = css`
margin-top: 20px;
`

const row_separator = css`
margin-top: 20px;
`

const video_heading = css`
font-size: 16px;
font-weight: bold;
`
