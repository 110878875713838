import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'
import moment from 'moment'

class CustomerDocumentList extends ItemList {
    getEntityKey() {
        return "customer_document"
    }

    onWatchedDocument(customer_course_id, document_id) {
        return this.saveNewObject({customer_course: customer_course_id, document: document_id})
    }
    
}

export const customerDocumentList = new CustomerDocumentList("customer_document_list__default")
