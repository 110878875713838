import { ItemList } from '../orm'
import { map, get, union, filter, head } from 'lodash'
import { download } from '../actions/lib'

class CustomerCertificateList extends ItemList {
    getEntityKey() {
        return "customer_certificate"
    }

    download(id) {
        const url = `customer_certificate/${id}/download/` 
        const params = null 
        const post_params = filter
        return download(url, params, post_params)
    }

}

export const customerCertificateList = new CustomerCertificateList("customer_certificate__default")
