/** @jsx jsx */
import { Component } from 'react';
import { jsx } from '@emotion/core'
import { Col, Row, Container } from 'react-bootstrap'

export class FormFieldWithAction extends Component {
    render() {

        const big_child = this.props.children[0]
        const small_child = this.props.children[1]
        
        return (
            <Row>
              <Col md={9}>
                { big_child }
              </Col>
              <Col md={3}>
                { small_child }
              </Col>
            </Row>
        )
    }
}
